<template>
    <div v-if="!!app_context" class="card">
        <div class="card-header">
            <div class="card-title h5">{{ $root.translate("Google") }}</div>
        </div>
        <div v-if="record.google_settings" class="card-body">
            <div v-if="record.google_settings.key_file">
                <form-select
                    id="ga_account_id"
                    name="ga_account_id"
                    :value="record.google_settings.ga_account_id"
                    :data-source="record.google.accounts"
                    :empty-label="$root.translate('None')"
                    empty-value=""
                    :show-all="true"
                    :required="false"
                    @input="save"
                >
                    <template v-slot:label>{{ $root.translate("Default Account") }}</template>
                </form-select>
            </div>
            <div v-else>
                <upload-input
                    name="google_key"
                    :url="record.google_settings.key_file || ''"
                    :accepts="upload_accepts('google_key')"
                    :uploading="!!uploading.google_key"
                    :uploaded="uploaded.google_key||0"
                    :deletable="false"
                    @upload="upload_file_list('google_key', $event)"
                >
                    <template v-slot:dragging-title>
                        {{ $root.translate("Drop the key to upload it") }}
                    </template>
                    <template v-slot:empty-title>
                        {{ $root.translate("Drag JSON key here, or use the button below") }}
                    </template>
                    <template v-slot:empty-button-title>
                        {{ $root.translate("Select JSON key...") }}
                    </template>
                    <template v-slot:display>
                        {{ record.google_settings.key_file }}
                    </template>
                </upload-input>
            </div>

            <h6 class="mt-8">{{ translate("Google Documents") }}</h6>
            <div v-if="record.ga_oauth_token && !!ga_picker">
                <label class="form-label">
                    {{ translate("Knowledge Center Directory") }}
                </label>
                <default-button
                    :block="true"
                    @click="ga_pick_folder"
                >
                    <span v-if="record.knowledge_center_folder_id">{{ record._knowledge_center_folder.name || record.knowledge_center_folder_id }}</span>
                    <span v-else>{{ translate("Select Knowledge Center Directory...") }}</span>
                    <open-icon glyph="pen" class="ml-2" />
                </default-button>
            </div>
            <default-button
                v-else-if="!record.ga_oauth_token"
                color="error"
                :block="true"
                @click="ga_token_client.requestAccessToken({ prompt: 'consent' })"
            >
                {{ translate("Authorize Access to Google Drive/Docs...") }}
            </default-button>
        </div>
    </div>
</template>

<script type="text/javascript">
import { misc_utilities, handles_uploads } from "@/nibnut/mixins"

import is_external_script_holder from "./IsExternalScriptHolder"

import {
    UploadInput,
    FormSelect,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"

export default {
    name: "GoogleConnector",
    mixins: [is_external_script_holder, misc_utilities, handles_uploads],
    components: {
        UploadInput,
        FormSelect,
        DefaultButton,
        OpenIcon
    },
    created () {
        this.inject_script(
            "google-api",
            "https://apis.google.com/js/api.js",
            this.gg_load_apis
        )
        this.inject_script(
            "google-gis",
            "https://accounts.google.com/gsi/client",
            this.gg_load_gis
        )
    },
    methods: {
        gg_load_apis () {
            window.gapi.load("client:picker", this.gg_bootstrap_picker)
        },
        gg_load_gis () {
            this.ga_token_client = window.google.accounts.oauth2.initTokenClient({
                client_id: this.constants("GOOGLE_CLIENT_ID"),
                scope: "https://www.googleapis.com/auth/drive.metadata.readonly",
                callback: this.gg_oauth_callback
            })
        },
        gg_oauth_callback (response) {
            if(response.error !== undefined) this.$error(response)
            this.$emit("input", response.access_token, "ga_oauth_token")
        },
        gg_bootstrap_picker () {
            window.gapi.client.load("https://www.googleapis.com/discovery/v1/apis/drive/v3/rest").then(() => {
                const view = new window.google.picker.DocsView(window.google.picker.ViewId.FOLDERS)
                view.setMimeTypes("application/vnd.google-apps.folder")
                view.setSelectFolderEnabled(true)
                this.ga_picker = new window.google.picker.PickerBuilder()
                    .setDeveloperKey(this.constants("GOOGLE_API_KEY"))
                    .setOAuthToken(this.record.ga_oauth_token)
                    .setAppId("wp-expert-tools")
                    .setOrigin(window.location.protocol + "//" + window.location.host)
                    .setRelayUrl(window.location.protocol + "//" + window.location.host)
                    .setSelectableMimeTypes("application/vnd.google-apps.folder")
                    .addView(view)
                    .setCallback(this.gg_picker_callback)
                    .build()
            })
        },
        ga_pick_folder () {
            this.ga_picker.setVisible(true)
            /*
            this.ga_token_client.requestAccessToken({ prompt: "" }).then(() => {
                this.ga_picker.setVisible(true)
            })
            */
        },
        gg_picker_callback (data) {
            if(
                (data.action === "picked") &&
                !!data.docs &&
                !!data.docs.length
            ) this.$emit("input", data.docs[0].id, "knowledge_center_folder_id")
        },
        upload_accepts (name, filename = null) {
            if(filename) return !!filename.match(/\.(json)$/i)
            return "application/json,.json"
        },
        upload_data () {
            return {
                entity: "setting",
                id: 1
            }
        },
        upload_attached (name, record) {
            if(this.record.google.email) {
                this.$copyText(this.record.google.email).then(() => {
                    this.$success(`${this.$root.translate("Don't forget to create the service account's user in GA (copied to your clipboard)")}: ${this.record.google.email}`)
                }, () => {})
            }
        },
        upload_attach_error (name, error) {
            this.$error(error.message)
        },
        save (value, field) {
            this.record.google_settings[field] = value
            this.$emit("input", this.record.google_settings, "google_settings")
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            ga_picker: null
        }
    }
}
</script>
