<template>
    <div v-if="!!app_context" class="card">
        <div class="card-header">
            <div class="card-title h5">{{ $root.translate("Contacts+") }}</div>
        </div>
        <div class="card-body">
            <div class="form-group text-center">
                <label v-if="!!app_context.settings.contacts_plus_accessible" class="form-label text-success">{{ $root.translate("Connected") }}</label>
                <base-link
                    v-else
                    :href="app_context.settings.contacts_plus_auth_url"
                    :title="$root.translate('Connect to your Contacts+ Account')"
                    class="btn"
                >
                    Connect
                </base-link>
            </div>

            <h6>{{ $root.translate("Type Mappings") }}</h6>

            <table class="table">
                <thead>
                    <tr>
                        <td class="text-small text-bold">{{ $root.translate("Contacts+ Tag") }}</td>
                        <td class="text-small text-bold">{{ $root.translate("Client Tag") }}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="contacts_plus_tag in contacts_plus_tags"
                        :key="contacts_plus_tag.id"
                    >
                        <td>{{ contacts_plus_tag.name }}</td>
                        <td>
                            <form-select
                                :id="`client-type-${contacts_plus_tag.id}`"
                                name="client_type"
                                :value="contacts_plus_tag.local_type"
                                :data-source="client_types"
                                :empty-label="$root.translate('Unused')"
                                empty-value=""
                                :show-all="true"
                                :required="false"
                                @input="save(contacts_plus_tag.id, $event)"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script type="text/javascript">
import { misc_utilities } from "@/nibnut/mixins"

import { FormSelect, BaseLink } from "@/nibnut/components"

export default {
    name: "ContactsPlusConnector",
    mixins: [misc_utilities],
    components: {
        FormSelect,
        BaseLink
    },
    methods: {
        save (tag, local_type) {
            if(!this.tags || !tag) return
            const tags = { ...this.tags }
            tags[tag].local_type = local_type
            this.$emit("input", tags, "contacts_plus_tags")
        }
    },
    computed: {
        client_types () {
            return Object.values(this.constants("client_types"))
        },
        contacts_plus_tags () {
            if(!this.tags) return []
            return Object.values(this.tags)
        }
    },
    props: {
        tags: {
            type: Object,
            default () {
                return {}
            }
        }
    }
}
</script>
