<template>
    <div v-if="!!stats" class="wpe-dashboard">
        <open-icon
            v-if="auto_refreshing"
            glyph="sync"
            :spin="true"
            class="auto-refresh"
        />
        <div v-if="!stats.downtime || !Object.keys(stats.downtime).length" class="empty">
            <div class="empty-icon">
                <open-icon glyph="chart-bar" size="5x" />
            </div>
            <p class="empty-title h5">{{ $root.translate("First report coming soon...") }}</p>
            <p class="empty-subtitle">{{ $root.translate("You will be notified by email") }}</p>
        </div>
        <div v-else class="card">
            <div v-if="waiting" class="card-body">
                <loader size="sm" />
            </div>
            <div v-else class="card-body">
                <div class="columns mb-2">
                    <div class="column">
                        <h4 v-if="!!title" class="mb-0">
                            {{ title }}
                            <stat-button
                                :count="issues_by_type('error').length"
                                :label="translate('Errors')"
                                color="error"
                                glyph="bomb"
                                class="ml-2"
                                @click.prevent="show_issues_of_type('error')"
                            />
                            <stat-button
                                :count="issues_by_type('warning').length"
                                :label="translate('Warnings')"
                                color="warning"
                                glyph="exclamation-triangle"
                                class="ml-2"
                                @click.prevent="show_issues_of_type('warning')"
                            />
                            <stat-button
                                v-if="!!count_broken_data(broken_urls_test_data('links'))"
                                :count="count_broken_data(broken_urls_test_data('links'))"
                                :label="translate('Broken Links')"
                                color="secondary"
                                glyph="unlink"
                                class="ml-2"
                                @click.prevent="show_issues_of_type('links')"
                            />
                            <stat-button
                                v-if="!!count_broken_data(broken_urls_test_data('images'))"
                                :count="count_broken_data(broken_urls_test_data('images'))"
                                :label="translate('Missing Images')"
                                color="secondary"
                                glyph="image"
                                class="ml-2"
                                @click.prevent="show_issues_of_type('images')"
                            />
                        </h4>
                    </div>
                    <div class="column text-right">
                        <div class="btn-group">
                            <default-button
                                :active="state.scope === 1"
                                size="xs"
                                @click.prevent="set_scope(1)"
                            >
                                {{ $root.translate('1 day') }}
                            </default-button>
                            <default-button
                                :active="state.scope === 7"
                                size="xs"
                                @click.prevent="set_scope(7)"
                            >
                                {{ $root.translate('7 days') }}
                            </default-button>
                            <default-button
                                :active="state.scope === 15"
                                size="xs"
                                @click.prevent="set_scope(15)"
                            >
                                {{ $root.translate('15 days') }}
                            </default-button>
                            <default-button
                                :active="state.scope === 30"
                                size="xs"
                                @click.prevent="set_scope(30)"
                            >
                                {{ $root.translate('30 days') }}
                            </default-button>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div
                        v-if="!siteId"
                        class="column col-2 col-md-6 col-sm-12"
                    >
                        <apexchart
                            :height="160"
                            type="radialBar"
                            :options="chart_options('monitors')"
                            :series="chart_data('monitors')"
                        />
                    </div>
                    <div
                        :class="{ 'col-12': !!siteId, 'col-10': !siteId, 'col-md-6': !siteId }"
                        class="column col-sm-12"
                    >
                        <div class="columns">
                            <div
                                class="column col-4 col-sm-12"
                            >
                                <apexchart
                                    :height="160"
                                    type="area"
                                    :options="chart_options('downtime')"
                                    :series="chart_data('downtime')"
                                />
                            </div>
                            <div
                                class="column col-4 col-sm-12"
                            >
                                <apexchart
                                    :height="160"
                                    type="area"
                                    :options="chart_options('errors')"
                                    :series="chart_data('errors')"
                                />
                            </div>
                            <div
                                class="column col-4 col-sm-12"
                            >
                                <apexchart
                                    :height="160"
                                    type="area"
                                    :options="chart_options('load')"
                                    :series="chart_data('load')"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal-dialog
            id="issues-viewer"
            :show.sync="viewing"
            class="modal-lg"
        >
            <template v-slot:title>
                <span v-if="viewing_type === 'error'" class="h5 text-error">
                    <open-icon glyph="bomb" class="mr-1" />
                    {{ $root.translate("Errors") }}
                </span>
                <span v-else-if="viewing_type === 'warning'" class="h5 text-warning">
                    <open-icon glyph="exclamation-triangle" class="mr-1" />
                    {{ $root.translate("Warnings") }}
                </span>
                <span v-else-if="viewing_type === 'links'" class="h5">
                    <open-icon glyph="unlink" class="mr-1" />
                    {{ $root.translate("Broken Links") }}
                </span>
                <span v-else-if="viewing_type === 'images'" class="h5">
                    <open-icon glyph="image" class="mr-1" />
                    {{ $root.translate("Missing Images") }}
                </span>
            </template>

            <div v-if="(viewing_type === 'links') || (viewing_type === 'images')">
                <table
                    class="table table-striped"
                >
                    <tr
                        v-for="(urls, page_url) in broken_urls_data(viewing_type)"
                        :key="page_url"
                    >
                        <td>
                            <base-link
                                :href="`${page_url}?_wpe-links-report=${broken_urls(viewing_type).slug}`"
                                target="_blank"
                            >
                                {{ page_url | nibnut.smart_truncate(80) }} ({{ urls.length }})
                            </base-link>
                            <ul>
                                <li
                                    v-for="url in urls"
                                    :key="url.to"
                                >
                                    {{ url.to | nibnut.smart_truncate(80) }}
                                </li>
                            </ul>
                        </td>
                    </tr>
                </table>
                <default-button
                    v-if="!show_all[viewing_type] && has_more_data(viewing_type)"
                    flavor="link"
                    :block="true"
                    @click.prevent="show_all[viewing_type] = true"
                >
                    {{ $root.translate("Show All") }}
                </default-button>
            </div>
            <data-table
                v-else
                id="issues-list"
                :columns="visible_columns"
                :rows="filtered_rows"
                search=""
                :searchable="false"
                :total="rows.length"
                :found="rows.length"
                :current-page="state.page"
                :total-pages="nb_pages"
                :clickable="false"
                :show-totals="false"
                :empty-title="$root.translate('Yay! No issues or warnings! Pat on your back...')"
                class="non-sticky"
                @sort="sort_by"
                @click="navigate"
            >
                <template
                    v-slot:summary="{ row }"
                >
                    <div
                        :class="{ [`text-${row.type}`]: true }"
                    >
                        <open-icon v-if="row.monitor_type === 'ssl'" glyph="lock" class="mr-1" />
                        <open-icon v-else-if="row.monitor_type === 'domain'" glyph="passport" class="mr-1" />
                        <open-icon v-else-if="row.monitor_type === 'email'" glyph="at" class="mr-1" />
                        <open-icon v-else glyph="link" class="mr-1" />
                        <span class="text-uppercase">[{{ row.monitor_type }}]</span>
                        {{ row.title }}
                        <a
                            v-if="!siteId"
                            :href="row.site_url"
                            target="_blank"
                            class="mt-2"
                            @click.stop
                        >
                            {{ row.site_name }}
                        </a>
                        <div
                            class="text-gray text-small mt-2"
                        >
                            {{ row.date | nibnut.date }}<span v-if="!row.id.match(/^ssl\./)"> @ {{ row.date | nibnut.date("HH:mm:ss") }}</span>
                        </div>
                    </div>
                </template>
                <template
                    v-slot:tbody="{ row, field }"
                >
                    <div
                        v-if="field === 'title'"
                        :class="{ [`text-${row.type}`]: true }"
                    >
                        <open-icon v-if="row.monitor_type === 'ssl'" glyph="lock" class="mr-1" />
                        <open-icon v-else-if="row.monitor_type === 'domain'" glyph="passport" class="mr-1" />
                        <open-icon v-else-if="row.monitor_type === 'email'" glyph="at" class="mr-1" />
                        <open-icon v-else glyph="link" class="mr-1" />
                        <span class="text-uppercase">[{{ row.monitor_type }}]</span>
                        {{ row.title }}
                        <a
                            v-if="!siteId"
                            :href="row.site_url"
                            target="_blank"
                            class="text-gray ml-4"
                            @click.stop
                        >
                            {{ row.site_name }}
                        </a>
                        <default-button
                            :title="$root.translate('Re-run')"
                            flavor="link"
                            size="sm"
                            @click.prevent.stop="regenerate(row)"
                        >
                            <open-icon glyph="sync" :spin="regenerating.indexOf(row.id)>=0" />
                        </default-button>
                    </div>
                    <span v-else-if="field === 'date'">
                        {{ row.date | nibnut.date }}<span v-if="!row.id.match(/^ssl\./)"> @ {{ row.date | nibnut.date("HH:mm:ss") }}</span>
                    </span>
                    <span v-else>{{ row[field] }}</span>
                </template>
            </data-table>
        </modal-dialog>
    </div>
</template>

<script>
import { mapState } from "vuex"

import { is_data_table_source } from "@/nibnut/mixins"

import { ModalDialog, DefaultButton, BaseLink, OpenIcon } from "@/nibnut/components"
import StatButton from "@/custom/components/StatButton"
import Loader from "@/custom/components/Loader"
import VueApexCharts from "vue-apexcharts"

const average = function (stats) {
    const values = Object.values(stats)
    const count = values.reduce((value, stat) => value + stat.count, 0)
    const total = values.reduce((value, stat) => value + stat.total, 0)
    return total ? (count / total) : 0
}
const uptime = function (scope, stat) {
    let total_time = (60 * 60)
    if(scope > 1) total_time *= 24

    const total_sites_time = stat.sites * total_time
    return total_sites_time ? Math.round(((total_sites_time - stat.total_down_time) / total_sites_time) * 100) : 0
}

let refresher

export default {
    name: "BaseDashboard",
    mixins: [is_data_table_source],
    components: {
        ModalDialog,
        DefaultButton,
        BaseLink,
        OpenIcon,
        StatButton,
        Loader,
        Apexchart: VueApexCharts
    },
    mounted () {
        this.load()
        refresher = setInterval(this.auto_refresh, 300000)
    },
    beforeDestroy () {
        if(refresher) clearInterval(refresher)
    },
    watch: {
        profile_id: "load",
        siteId: "load",
        showErrors: "show_errors",
        showWarnings: "show_warnings",
        showLinks: "show_links",
        showImages: "show_images"
    },
    methods: {
        toggle_waiting (waiting, auto_refreshing = false) {
            if(!auto_refreshing) {
                this.$emit("loading", waiting, this.issues)
                this.waiting = waiting
            } else this.auto_refreshing = waiting
        },
        load_dashboard_data (auto_refreshing = false) {
            this.toggle_waiting(true, auto_refreshing)
            this.$store.dispatch(
                "RECORD_ACTION",
                {
                    entity: "site",
                    id: this.siteId,
                    action: "dashboard",
                    data: { scope: this.state.scope },
                    passthru: true
                }
            ).then(response => {
                this.stats = response.stats || null
                this.issues = response.issues || []
                this.$emit("loaded", {
                    errors: this.issues_by_type("error").length,
                    warnings: this.issues_by_type("warning").length,
                    links: this.count_broken_data(this.broken_urls_test_data("links")),
                    images: this.count_broken_data(this.broken_urls_test_data("images"))
                })
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.toggle_waiting(false, auto_refreshing)
            })
        },
        load () {
            if(this.profile_id) this.load_dashboard_data()
        },
        auto_refresh () {
            this.load_dashboard_data(true)
        },
        chart_options (chart_id) {
            const stats = this.stats[chart_id]
            if(chart_id === "monitors") {
                return {
                    chart: {
                        id: chart_id,
                        type: "radialBar"
                    },
                    plotOptions: {
                        radialBar: {
                            dataLabels: {
                                name: {
                                    fontSize: "22px"
                                },
                                value: {
                                    fontSize: "16px",
                                    offsetY: 0
                                },
                                total: {
                                    show: true,
                                    fontSize: "14px",
                                    label: this.$root.translate("Monitors"),
                                    formatter: function (w) {
                                        return stats.up + stats.down
                                    }
                                }
                            }
                        }
                    },
                    labels: [this.$root.translate("Up"), this.$root.translate("Down")],
                    colors: ["#00bcb4", "#e85600"]
                }
            }

            let color = "#00bcb4"
            let title
            let subtitle
            if(chart_id === "errors") {
                title = `${this.$numeral(average(stats) * 100).format("0,0")}%`
                subtitle = this.$root.translate("Average errors")
                color = "#e85600"
            } else if(chart_id === "load") {
                title = `${this.$numeral(average(stats)).format("0,0")} ms`
                subtitle = this.$root.translate("Average load time")
            } else {
                const values = Object.values(stats)
                const count = values.length
                let total = 0
                values.forEach(stat => {
                    total += uptime(this.state.scope, stat)
                })

                title = `${this.$numeral(count ? (total / count) : 0).format("0,0")}%`
                subtitle = this.$root.translate("Average Uptime")
            }
            const categories = []
            Object.keys(stats).forEach(date_time_string => {
                const date = this.$moment(date_time_string)
                if(this.state.scope <= 1) categories.push(date.format("HH:mm"))
                else categories.push(date.format("MM/DD"))
            })

            return {
                chart: {
                    id: chart_id,
                    sparkline: {
                        enabled: true
                    }
                },
                fill: {
                    opacity: 0.3
                },
                colors: [color],
                title: {
                    text: title,
                    offsetX: 0,
                    style: {
                        fontSize: "24px"
                    }
                },
                subtitle: {
                    text: subtitle,
                    offsetX: 0,
                    style: {
                        fontSize: "14px"
                    }
                },
                xaxis: {
                    categories
                },
                tooltip: {
                    y: {
                        formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
                            if(chart_id === "load") return `${this.$numeral(value).format("0,0")} ms`
                            return `${this.$numeral(value).format("0,0")}%`
                        }
                    }
                }
            }
        },
        chart_data (chart_id) {
            const stats = this.stats[chart_id]
            if(chart_id === "monitors") {
                const total = stats.up + stats.down
                return [
                    total ? this.$numeral((stats.up / total) * 100).format("0,0") : 0,
                    total ? this.$numeral((stats.down / total) * 100).format("0,0") : 0
                ]
            }

            let name
            let data
            if(chart_id === "load") {
                name = this.$root.translate("Load Time")
                data = Object.values(stats).map(stat => {
                    return stat.total ? (stat.count / stat.total) : 0
                })
            } else if(chart_id === "downtime") {
                name = this.$root.translate("Uptime")
                data = Object.values(stats).map(stat => {
                    return uptime(this.state.scope, stat)
                })
            } else {
                name = this.$root.translate("Errors")
                data = Object.values(stats).map(stat => {
                    return stat.total ? Math.round((stat.count / stat.total) * 100) : 0
                })
            }
            return [
                {
                    name,
                    data
                }
            ]
        },
        set_scope (days) {
            this.set_state_value("scope", days)
            this.load()
        },
        navigate (row) {
            if(!this.siteId) this.$router.push({ name: "site.edit", params: { id: row.site_id } })
        },
        regenerate (row) {
            this.regenerating.push(row.id)
            this.$store.dispatch(
                "RECORD_ACTION",
                {
                    entity: "monitor",
                    id: row.monitor_id,
                    action: "refresh",
                    method: "put"
                }
            ).then(() => {
                this.$success(this.$root.translate("Monitoring requested: you will be notified by email when the report is ready."))
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                const index = this.regenerating.indexOf(row.id)
                if(index >= 0) this.regenerating.splice(index, 1)
            })
        },
        broken_urls (test_id = "links") {
            if(!this.siteId) return null
            return this.issues.find(issue => {
                return (issue.monitor_type === "links") && (issue.test_id === test_id)
            })
        },
        broken_urls_test_data (test_id) {
            const broken_urls = this.broken_urls(test_id)
            return broken_urls ? broken_urls.test_data : []
        },
        broken_urls_data (test_id) {
            let data = this.broken_urls_test_data(test_id)
            const keys = Object.keys(data)
            if(keys.length && !this.show_all[test_id]) {
                const full_data = data
                data = {}
                keys.slice(0, 1).map(key => {
                    data[key] = full_data[key]
                })
            }
            return data
        },
        count_broken_data (list) {
            return Object.keys(list).reduce((sum, key) => {
                return sum + list[key].length
            }, 0)
        },
        has_more_data (test_id) {
            return this.count_broken_data(this.broken_urls_data(test_id)) !== this.count_broken_data(this.broken_urls_test_data(test_id))
        },
        issues_by_type (type) {
            if(type.match(/^(links|images)$/)) return Object.keys(this.broken_urls_test_data(type))
            return this.issues.filter(issue => {
                return (issue.type !== "report") && (issue.type === type)
            })
        },
        show_issues_of_type (type) {
            this.viewing_type = type
            this.viewing = true
            this.refresh()
        },
        show_errors () {
            this.show_issues_of_type("error")
        },
        show_warnings () {
            this.show_issues_of_type("warning")
        },
        show_links () {
            this.show_issues_of_type("links")
        },
        show_images () {
            this.show_issues_of_type("images")
        }
    },
    computed: {
        ...mapState(["profile_id"]),
        state_identifier () {
            return "Dashboard"
        },
        rows () {
            if(!this.viewing) return []
            return this.issues_by_type(this.viewing_type)
        }
    },
    props: {
        siteId: {
            type: Number,
            validator: prop => prop >= 0,
            required: true
        },
        title: {
            type: String,
            default: "Statistics"
        },
        showErrors: {
            type: Number,
            default: 0
        },
        showWarnings: {
            type: Number,
            default: 0
        },
        showLinks: {
            type: Number,
            default: 0
        },
        showImages: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            stats: null,
            issues: [],
            show_all: { links: false, images: false },

            columns: {
                title: { label: "Error/Warning", sort: null, type: "alpha" },
                date: { label: "Date", sort: "desc", type: "numeric" }
            },
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "date",
                sort_dir: "desc",
                filter_on: null,
                filter: null,
                scope: 7,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            auto_refreshing: false,
            waiting: false,
            viewing: false,
            viewing_type: "",
            regenerating: []
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/sass/variables";

.wpe-dashboard {
    position: relative;
    & > .auto-refresh {
        position: absolute;
        right: 0;
        top: -1rem;
    }
    & > .card {
        background-color: $bg-color;

        .card-body {
            .loader {
                text-align: center;
                margin: 2rem auto;
            }
            .empty {
                padding: $unit-8;
            }
        }
    }
}
</style>
