const handles_dates = {
    methods: {
        standardized_range_object (date_data) {
            if((typeof date_data === "object") && !this.$moment.isMoment(date_data) && (typeof date_data.from !== "undefined")) {
                if(!this.$moment.isMoment(date_data.from)) date_data.from = this.$moment(date_data.from)
                if(!this.$moment.isMoment(date_data.to)) date_data.to = this.$moment(date_data.to)
                return date_data
            }
            const date = this.$moment(date_data)
            return { from: date, to: date }
        },
        standardized_date (date_data) {
            if(!date_data) return null
            if(date_data && this.$moment.isMoment(date_data)) return date_data
            return this.$moment(date_data)
        }
    }
}

export default handles_dates
