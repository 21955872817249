<template>
    <div v-if="!!edited_record" class="columns">
        <div class="column col-3 col-xl-6 col-lg-6 col-md-12 mb-2">
            <quickbooks-connector
                class="mb-2"
            />
            <stripe-connector
                :record="edited_record"
                class="mb-2"
                @input="save"
            />
            <google-connector
                :record="edited_record"
                @input="save"
            />
        </div>
        <div class="column col-3 col-xl-6 col-lg-6 col-md-12 mb-2">
            <contacts-plus-connector
                :tags="edited_record.contacts_plus_tags"
                class="mb-2"
                @input="save"
            />
        </div>
        <div class="column col-6 col-xl-12 col-lg-12 col-md-12 mb-2">
            <freescout-connector
                :record="edited_record"
                @input="save"
            />
        </div>
    </div>
</template>

<script>
import is_settings_panel from "./IsSettingsPanel"

import QuickbooksConnector from "./QuickbooksConnector"
import ContactsPlusConnector from "./ContactsPlusConnector"
import GoogleConnector from "./GoogleConnector"
import FreescoutConnector from "./FreescoutConnector"
import StripeConnector from "./StripeConnector"

export default {
    name: "Integrations",
    mixins: [is_settings_panel],
    components: {
        QuickbooksConnector,
        ContactsPlusConnector,
        GoogleConnector,
        FreescoutConnector,
        StripeConnector
    }
}
</script>
