<template>
    <label
        :class="{ [`form-${type}`]: true }"
    >
        <input
            v-bind="input_props"
            v-on="$listeners"
            :class="{ saving }"
        />
        <i class="form-icon"></i><slot></slot>
    </label>
</template>

<script>
export default {
    name: "BaseToggleInput",
    computed: {
        input_props () {
            const { saving, ...input_props } = this.$props
            return input_props
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        checked: {
            default: false
        },
        type: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(radio|checkbox)$/i),
            default: "radio"
        },
        saving: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>
