const routes = [
    { path: "/settings", name: "settings", component: require("@/views/Settings").default, meta: { admin_route: true } },
    { path: "/profile", name: "profile", component: require("@/views/user/Edit").default, meta: { login_required: true } },
    { path: "/clients", name: "client.list", component: require("@/views/client/List").default, meta: { admin_route: true, scroll_position: true } },
    { path: "/client/:id?", name: "client.edit", component: require("@/views/client/Edit").default, meta: { admin_route: true } },
    { path: "/sites", name: "site.list", component: require("@/views/site/List").default, meta: { admin_route: true, scroll_position: true } },
    { path: "/site/:uuid/credits", name: "site.credits", component: require("@/views/site/Credits").default },
    { path: "/site/:id?", name: "site.edit", component: require("@/views/site/Edit").default, meta: { admin_route: true } },
    { path: "/licenses", name: "license.list", component: require("@/views/license/List").default, meta: { licensing_route: true, scroll_position: true } },
    { path: "/license/:id", name: "license.edit", component: require("@/views/license/Edit").default, meta: { admin_route: true } },
    { path: "/projects", name: "project.list", component: require("@/views/project/List").default, meta: { admin_route: true, scroll_position: true } },
    { path: "/alerts", name: "alert.list", component: require("@/views/alert/List").default, meta: { admin_route: true, scroll_position: true } },
    { path: "/users", name: "user.list", component: require("@/views/user/List").default, meta: { admin_route: true, scroll_position: true } },
    { path: "/user/:id?", name: "user.edit", component: require("@/views/user/Edit").default, meta: { admin_route: true } },
    { path: "/tickets/:folder?", name: "ticket.list", component: require("@/views/ticket/List").default, meta: { admin_route: true, nav_expanded: true } },
    { path: "/tickets/:folder/:id?", name: "ticket.edit", component: require("@/views/ticket/Edit").default, meta: { admin_route: true, nav_expanded: true } },
    { path: "/credits", name: "credit.list", component: require("@/views/credit/List").default, meta: { admin_route: true } },
    { path: "/report/:uuid", name: "report.view", component: require("@/views/report/View").default },
    { path: "/report/:slug/links/:id", name: "report.broken-links", component: require("@/views/report/BrokenLinks").default },
    { path: "/fix/:slug/links/:id", name: "report.fix-broken-links", component: require("@/views/report/FixBrokenLinks").default },
    { path: "/", name: "home", component: require("@/views/Dashboard").default, meta: { login_required: true } }
]

export default routes
