<template>
    <div>
        <data-table
            :id="state_identifier"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :show-head="false"
            :can-add="true"
            @sort="sort_by"
            @search="search"
            @clear-search="clear_search"
            @page="goto_page"
            @click="start_editing"
            @add="setup_new_plan"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6 :class="{ 'text-gray': !row.reportable }">{{ row.name }}</h6>
                <div :class="{ 'text-gray': !row.reportable }">
                    {{ row.standardized_monthly_price | nibnut.currency }} / {{ $root.translate("month") }}
                </div>
                <div :class="{ 'text-gray': !row.reportable }" class="text-small">
                    {{ row.standardized_yearly_price | nibnut.currency }} / {{ $root.translate("year") }}
                </div>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div v-if="field === 'monthly_price'" :class="{ 'text-gray': !row.reportable }">
                    {{ row.standardized_monthly_price | nibnut.currency }} / {{ $root.translate("month") }}
                    <div class="text-small text-gray">
                        {{ row.standardized_yearly_price | nibnut.currency }} / {{ $root.translate("year") }}
                    </div>
                </div>
                <div v-else-if="field === '_buttons'" class="text-right">
                    <default-button
                        flavor="link"
                        color="error"
                        @click.prevent.stop="confirm_record_delete(row)"
                    >
                        <open-icon glyph="trash" />
                    </default-button>
                </div>
                <span v-else :class="{ 'text-gray': !row.reportable }">{{ row[field] }}</span>
            </template>
        </data-table>

        <modal-dialog
            id="plan-editor"
            :show.sync="editing"
        >
            <template v-slot:title>
                <span v-if="!action_record.id" class="h5">{{ $root.translate("New Plan") }}</span>
                <span v-else class="h5">{{ action_record.name }}</span>
            </template>

            <form-select
                id="type"
                name="type"
                v-model="action_record.type"
                :data-source="types"
                :empty-value="null"
                :required="true"
                :show-all="true"
                :saving="saving('type')"
                :error="has_error('type')"
                @input="maybe_save"
            >
                <template v-slot:label>{{ translate("Type") }}</template>
            </form-select>

            <form-input
                id="plan-name"
                name="name"
                v-model="action_record.name"
                :required="true"
                :saving="saving('name')"
                :error="has_error('name')"
                @input="maybe_save"
            >
                <template v-slot:label>{{ $root.translate("Name") }}</template>
            </form-input>

            <div class="columns">
                <div class="column col-4 col-sm-12">
                    <form-input
                        id="plan-monthly-price"
                        name="monthly_price"
                        type="float"
                        v-model="action_record.monthly_price"
                        :required="false"
                        :min="0"
                        glyph="dollar-sign"
                        glyph-position="left"
                        :saving="saving('monthly_price')"
                        :error="has_error('monthly_price')"
                        @input="maybe_save"
                    >
                        <template v-slot:label>{{ $root.translate("Monthly Price") }}</template>
                        <template v-slot:right_addon><span class="input-group-addon">/{{ $root.translate("month") }}</span></template>
                    </form-input>
                </div>
                <div class="column col-4 col-sm-12">
                    <form-input
                        id="plan-yearly-price"
                        name="yearly_price"
                        type="float"
                        v-model="action_record.yearly_price"
                        :required="false"
                        :min="0"
                        glyph="dollar-sign"
                        glyph-position="left"
                        :saving="saving('yearly_price')"
                        :error="has_error('yearly_price')"
                        @input="maybe_save"
                    >
                        <template v-slot:label>{{ $root.translate("Yearly Price") }}</template>
                        <template v-slot:right_addon><span class="input-group-addon">/{{ $root.translate("year") }}</span></template>
                    </form-input>
                </div>
                <div v-if="!!action_record && (action_record.type === this.constants('plan_types', 'TYPE_MAINTENANCE').id)" class="column col-4 col-sm-12">
                    <form-input
                        id="plan-monthly-credits"
                        name="monthly_credits"
                        v-model="action_record.monthly_credits"
                        :required="false"
                        :min="0"
                        :saving="saving('monthly_credits')"
                        :error="has_error('monthly_credits')"
                        @input="maybe_save"
                    >
                        <template v-slot:label>{{ $root.translate("Credits") }}</template>
                        <template v-slot:right_addon><span class="input-group-addon">/{{ $root.translate("month") }}</span></template>
                    </form-input>
                </div>
            </div>

            <form-toggle-input
                id="reportable"
                name="reportable"
                type="checkbox"
                v-model="action_record.reportable"
                @input="maybe_save"
            >
                {{ $root.translate('Reportable in stats') }}
            </form-toggle-input>

            <h6 v-if="!!action_record && (action_record.type === this.constants('plan_types', 'TYPE_MAINTENANCE').id)" class="mt-4">{{ $root.translate('Default Monitors') }}</h6>
            <div v-if="!!action_record && !!action_record.default_monitor_types && (action_record.type === this.constants('plan_types', 'TYPE_MAINTENANCE').id)" class="columns">
                <div class="column col-6 col-sm-12">
                    <form-toggle-input
                        id="website"
                        name="website"
                        type="checkbox"
                        :value="action_record.default_monitor_types.indexOf(constants('monitor_types', 'TYPE_WEBSITE').id) >= 0"
                        @input="toggle_monitor(constants('monitor_types', 'TYPE_WEBSITE').id)"
                    >
                        {{ $root.translate('Website Status') }}
                    </form-toggle-input>
                </div>
                <div class="column col-6 col-sm-12">
                    <form-toggle-input
                        id="domain"
                        name="domain"
                        type="checkbox"
                        :value="action_record.default_monitor_types.indexOf(constants('monitor_types', 'TYPE_DOMAIN').id) >= 0"
                        @input="toggle_monitor(constants('monitor_types', 'TYPE_DOMAIN').id)"
                    >
                        {{ $root.translate('Domain') }}
                    </form-toggle-input>
                </div>
                <div class="column col-6 col-sm-12">
                    <form-toggle-input
                        id="ssl"
                        name="ssl"
                        type="checkbox"
                        :value="action_record.default_monitor_types.indexOf(constants('monitor_types', 'TYPE_SSL').id) >= 0"
                        @input="toggle_monitor(constants('monitor_types', 'TYPE_SSL').id)"
                    >
                        {{ $root.translate('SSL Certificate') }}
                    </form-toggle-input>
                </div>
                <div class="column col-6 col-sm-12">
                    <form-toggle-input
                        id="links"
                        name="links"
                        type="checkbox"
                        :value="action_record.default_monitor_types.indexOf(constants('monitor_types', 'TYPE_LINKS').id) >= 0"
                        @input="toggle_monitor(constants('monitor_types', 'TYPE_LINKS').id)"
                    >
                        {{ $root.translate('Broken Links & Images') }}
                    </form-toggle-input>
                </div>
                <div class="column col-6 col-sm-12">
                    <form-toggle-input
                        id="site_email"
                        name="site_email"
                        type="checkbox"
                        :value="action_record.default_monitor_types.indexOf(constants('monitor_types', 'TYPE_SITE_EMAIL').id) >= 0"
                        @input="toggle_monitor(constants('monitor_types', 'TYPE_SITE_EMAIL').id)"
                    >
                        {{ $root.translate('Site Email') }}
                    </form-toggle-input>
                </div>
            </div>

            <template v-slot:footer>
                <div
                    v-if="!action_record.id"
                    class="text-center"
                >
                    <default-button
                        class="mr-2"
                        @click.prevent="stop_editing"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        color="primary"
                        class="ml-2"
                        @click.prevent="create_plan"
                    >
                        {{ $root.translate("Save") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import { is_remote_data_table_source, crud_utilities, handles_saving, confirms } from "@/nibnut/mixins"

import { ModalDialog, FormInput, FormSelect, FormToggleInput, DefaultButton, OpenIcon } from "@/nibnut/components"

export default {
    mixins: [is_remote_data_table_source, crud_utilities, handles_saving, confirms],
    components: {
        ModalDialog,
        FormInput,
        FormSelect,
        FormToggleInput,
        DefaultButton,
        OpenIcon
    },
    methods: {
        start_editing (plan) {
            this.action_record = plan
            this.editing = true
        },
        stop_editing () {
            this.editing = false
            this.action_record = {}
        },
        setup_new_plan () {
            this.record_shell("plan").then(record => {
                this.start_editing(record)
            }).catch(this.receive_error)
        },
        maybe_save (value, field) {
            if(this.action_record.id) this.save_field_for_record_id(this.entity, this.action_record.id, value, field)
        },
        create_plan () {
            this.$store.dispatch("CREATE_RECORD", {
                entity: "plan",
                data: this.action_record
            }).then(record => {
                this.action_record = {}
                this.stop_editing()
                this.refresh()
            }).catch(this.receive_error)
        },
        confirm_record_delete (plan) {
            this.action_record = plan
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete {name}", { name: this.action_record.name }),
                    message: this.$root.translate("Do you really want to delete this plan? Sites on this plan will remain unaffected."),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-plan"
            )
        },
        confirmed () {
            if(this.confirming === "delete-plan") {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: this.entity,
                        id: this.action_record.id
                    }
                ).then(record => {
                    this.done_confirming()
                    this.action_record = {}
                    this.refresh()
                }).catch(this.receive_error)
            } else this.done_confirming()
        },
        toggle_monitor (monitor_type) {
            if(!!this.action_record && !!this.action_record.default_monitor_types) {
                const default_monitor_types = this.action_record.id ? [...this.action_record.default_monitor_types] : this.action_record.default_monitor_types
                const index = default_monitor_types.indexOf(monitor_type)
                if(index >= 0) default_monitor_types.splice(index, 1)
                else default_monitor_types.push(monitor_type)
                if(this.action_record.id) this.save_field_for_record_id(this.entity, this.action_record.id, default_monitor_types, "default_monitor_types")
            }
        }
    },
    computed: {
        state_identifier () {
            return "plans-list"
        },
        fields () {
            return [
                "name", "type",
                "monthly_price", "yearly_price", "standardized_monthly_price", "standardized_yearly_price",
                "reportable", "default_monitor_types", "monthly_credits"
            ]
        },
        types () {
            return Object.values(this.constants("plan_types"))
        }
    },
    data () {
        return {
            entity: "plan",
            columns: {
                name: { label: "Plan", sort: "asc", type: "alpha" },
                monthly_price: { label: "Price", sort: null, type: "numeric" },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },

            action_record: {},
            editing: false
        }
    }
}
</script>
