import { is_record_editor } from "../../nibnut/mixins"

const is_settings_panel = {
    mixins: [is_record_editor],
    methods: {
        grab_record_id () {
            this.record_id = 1
            return true
        },
        reload () {
            if(this.is_public_page || this.profile_id) {
                if(this.grab_record_id() === true) return

                this.waiting = true
                if(this.record_id) {
                    this.$store.dispatch(
                        "FETCH_RECORD",
                        {
                            entity: this.entity,
                            id: this.record_id,
                            query: { relation_ids: this.relation_ids }
                        }
                    ).then(() => {
                        this.shell_record = null
                        this.post_load()
                    }).catch(error => {
                        this.$error(error.message)
                    }).then(() => {
                        this.waiting = false
                    })
                } else {
                    this.$store.dispatch(
                        "FETCH_RECORD_SHELL",
                        { entity: this.entity }
                    ).then(record => {
                        this.shell_record = record
                        this.post_load()
                    }).catch(error => {
                        this.$error(error.message)
                    }).then(() => {
                        this.waiting = false
                    })
                }
            }
        }
    },
    data () {
        return {
            entity: "setting",
            entityName: "Setting:::Settings"
        }
    }
}

export default is_settings_panel
