<template>
    <div v-if="!!record" class="card">
        <div class="card-header">
            <div class="card-title h5">
                {{ $root.translate("FreeScout") }}
                <span v-if="!!record.freescout_active" class="text-success ml-4">{{ $root.translate("Connected") }}</span>
                <span v-else class="text-error ml-4">{{ $root.translate("Disconnected") }}</span>
            </div>
        </div>
        <div
            v-if="!!record.freescout_active"
            class="card-body"
        >
            <form-select
                id="freescout_mailbox_id"
                name="freescout_mailbox_id"
                :value="record.freescout_mailbox_id"
                :data-source="record.freescout_mailboxes"
                :empty-label="$root.translate('None')"
                :empty-value="0"
                :show-all="true"
                :required="false"
                @input="save"
            />

            <data-table
                :id="state_identifier"
                entity-name="snippet:::snippets"
                :columns="visible_columns"
                :rows="filtered_rows"
                :searchable="false"
                :total="state.total"
                :found="state.found"
                :current-page="state.page"
                :total-pages="nb_pages"
                :can-add="true"
                @sort="sort_by"
                @page="goto_page"
                @click="edit_record"
                @add="create_record"
            >
                <template
                    v-slot:summary="{ row }"
                >
                    <h6>{{ row.title }}</h6>
                    <div class="text-gray" v-html="summary_for_row(row)"></div>
                </template>
                <template
                    v-slot:tbody="{ row, field }"
                >
                    <div v-if="field === 'title'">
                        <h6>{{ row.title }}</h6>
                        <div class="text-gray" v-html="summary_for_row(row)"></div>
                    </div>
                    <div v-else-if="field === '_buttons'" class="text-right">
                        <default-button
                            flavor="link"
                            color="error"
                            @click.prevent.stop="confirm_record_delete(row)"
                        >
                            <open-icon glyph="trash" />
                        </default-button>
                    </div>
                    <span v-else>
                        {{ row[field] }}
                    </span>
                </template>
            </data-table>

            <modal-dialog
                id="freescout-snippet-editor"
                :show.sync="editing"
            >
                <template v-slot:title>
                    <span class="h5">{{ translate("Freescout Snippet") }}</span>
                </template>

                <form-input
                    id="freescout-snippet-title"
                    name="title"
                    :value="edited_record.title"
                    :required="true"
                    :saving="saving('title')"
                    :error="has_error('title')"
                    @input="save_record_field"
                >
                    <template v-slot:label>{{ $root.translate("Title") }}</template>
                </form-input>

                <form-editor
                    id="freescout-snippet-body"
                    name="body"
                    :value="edited_record.body"
                    :required="true"
                    size="lg"
                    :full-height="false"
                    :saving="saving('body')"
                    :error="has_error('body')"
                    @input="save_record_field"
                >
                    <template v-slot:label>{{ $root.translate("Text") }}</template>
                </form-editor>

                <template v-slot:footer>
                    <div
                        v-if="edited_record === shell_record"
                        class="text-center"
                    >
                        <default-button
                            class="mr-2"
                            @click.prevent="editing = false"
                        >
                            {{ $root.translate("Cancel") }}
                        </default-button>
                        <default-button
                            color="primary"
                            class="ml-2"
                            @click.prevent="save_record"
                        >
                            {{ $root.translate("Save") }}
                        </default-button>
                    </div>
                </template>
            </modal-dialog>
            <confirmation
                v-if="!!confirming"
                v-bind="confirmation_props"
                @cancel="done_confirming"
                @confirmed="confirmed"
            />
        </div>
    </div>
</template>

<script type="text/javascript">
import { is_data_table_source, handles_saving, confirms } from "@/nibnut/mixins"

import {
    ModalDialog,
    FormInput,
    FormSelect,
    FormEditor,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"

export default {
    name: "FreescoutConnector",
    mixins: [is_data_table_source, handles_saving, confirms],
    components: {
        ModalDialog,
        FormInput,
        FormSelect,
        FormEditor,
        DefaultButton,
        OpenIcon
    },
    watch: {
        "record.id": "reset"
    },
    methods: {
        save (freescout_mailbox_id) {
            this.$emit("input", freescout_mailbox_id, "freescout_mailbox_id")
        },
        summary_for_row (row) {
            return this.nibnut_filter("nibnut.smart_truncate", [row.body, 80]).replace(/<br[^>]*?>/gi, " ").replace(/[\r\n]+/gi, " ")
        },
        edit_record (row) {
            this.edited_record = row
            this.editing = true
        },
        create_record () {
            this.shell_record = { title: "", body: "" }
            this.edit_record(this.shell_record)
        },
        save_record_field (value, field) {
            if(this.edited_record) {
                this.edited_record[field] = value
                if(this.edited_record !== this.shell_record) this.$emit("input", this.record.freescout_snippets, "freescout_snippets")
            }
        },
        save_record () {
            if(this.edited_record === this.shell_record) {
                this.record.freescout_snippets.push({ ...this.shell_record })
                this.$emit("input", this.record.freescout_snippets, "freescout_snippets")
                this.editing = false
            }
        },
        confirm_record_delete (row) {
            const index = this.rows.indexOf(row)
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete {name}", { name: row.title }),
                    message: this.$root.translate("Do you really want to delete this snippet? It will not affect any existing content, it will simply no longer be accessible to use while replying to tickets."),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete"),
                    delete_data: index
                },
                "delete-snippet"
            )
        },
        confirmed () {
            if(this.confirming === "delete-snippet") {
                this.record.freescout_snippets.splice(this.confirmation_props.delete_data, 1)
                this.$emit("input", this.record.freescout_snippets, "freescout_snippets")
                this.done_confirming()
                this.refresh()
            } else this.done_confirming()
        }
    },
    computed: {
        state_identifier () {
            return "freescout-snippets"
        },
        rows () {
            if(!this.record) return []
            return this.record.freescout_snippets || []
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            columns: {
                title: { label: "Snippets", sort: "asc", type: "alpha" },
                _buttons: { label: " ", sort: false }
            },

            default_state: {
                per_page: 5,
                page: 1,
                sort_by: null,
                sort_dir: null,
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            shell_record: { title: "", body: "" },
            edited_record: {},
            editing: false
        }
    }
}
</script>
