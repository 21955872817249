<template>
    <page
        id="settings"
        :title="$root.translate('Settings')"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <ul class="tab tab-block mt-10 mb-4">
            <li
                :class="{ active: current_tab === 'notifications' }"
                class="tab-item"
            >
                <a
                    href="#"
                    @click.prevent="current_tab = 'notifications'"
                >
                    {{ $root.translate("Notifications") }}
                </a>
            </li>
            <li
                :class="{ active: current_tab === 'contracts' }"
                class="tab-item"
            >
                <a
                    href="#"
                    @click.prevent="current_tab = 'contracts'"
                >
                    {{ $root.translate("Contracts") }}
                </a>
            </li>
            <li
                :class="{ active: current_tab === 'hosts' }"
                class="tab-item"
            >
                <a
                    href="#"
                    @click.prevent="current_tab = 'hosts'"
                >
                    <span class="show-md">{{ $root.translate("Lists") }}</span>
                    <span class="hide-md">{{ $root.translate("Client Types, Hosts & Registrars") }}</span>
                </a>
            </li>
            <li
                :class="{ active: current_tab === 'settings' }"
                class="tab-item"
            >
                <a
                    href="#"
                    @click.prevent="current_tab = 'settings'"
                >
                    {{ $root.translate("Settings") }}
                </a>
            </li>
            <li
                :class="{ active: current_tab === 'maintenance' }"
                class="tab-item"
            >
                <a
                    href="#"
                    @click.prevent="current_tab = 'maintenance'"
                >
                    {{ $root.translate("Maintenance") }}
                </a>
            </li>
            <li
                :class="{ active: current_tab === 'integrations' }"
                class="tab-item"
            >
                <a
                    href="#"
                    @click.prevent="current_tab = 'integrations'"
                >
                    {{ $root.translate("Integrations") }}
                </a>
            </li>
        </ul>
        <notifications
            v-if="current_tab === 'notifications'"
        />
        <contracts
            v-else-if="current_tab === 'contracts'"
        />
        <hosts
            v-else-if="current_tab === 'hosts'"
        />
        <app-settings
            v-else-if="current_tab === 'settings'"
        />
        <maintenance
            v-else-if="current_tab === 'maintenance'"
        />
        <integrations
            v-else-if="current_tab === 'integrations'"
        />
    </page>
</template>

<script type="text/javascript">
import { is_record_editor_page } from "../nibnut/mixins"

import Notifications from "./settings/Notifications"
import Contracts from "./settings/Contracts"
import Hosts from "./settings/Hosts"
import AppSettings from "./settings/AppSettings"
import Maintenance from "./settings/Maintenance"
import Integrations from "./settings/Integrations"

export default {
    name: "Settings",
    mixins: [is_record_editor_page],
    components: {
        Notifications,
        Contracts,
        Hosts,
        AppSettings,
        Maintenance,
        Integrations
    },
    methods: {
        statused (status) {
            this.page_status = status
        },
        grab_record_id () {
            this.record_id = 1
        }
    },
    data () {
        return {
            entity: "setting",
            entityName: "Setting:::Settings",

            current_tab: "notifications"
        }
    }
}
</script>
