<template>
    <div>
        <contact-editor
            v-if="!!shell"
            :contact="shell"
        />
        <data-table
            v-else
            id="client-contacts"
            :columns="visible_columns"
            :rows="filtered_rows"
            :searchable="false"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="true"
            @sort="sort_by"
            @search="search"
            @clear-search="clear_search"
            @page="goto_page"
            @click="edit_record"
            @add="add_record"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6>
                    {{ row.computed_name }}
                    <default-button
                        flavor="link"
                        size="sm"
                        :color="is_main_contact(row) ? 'primary' : 'light'"
                        :title="is_main_contact(row) ? $root.translate('Main Contact') : $root.translate('Make this contact the main contact')"
                        class="ml-2"
                        @click.prevent.stop="$emit('input', row.id)"
                    >
                        <open-icon
                            :glyph="is_main_contact(row) ? 'star' : 'asterisk'"
                        />
                    </default-button>
                    <div
                        v-if="!!row.position"
                        class="text-small text-gray"
                    >
                        {{ row.position }}
                    </div>
                </h6>
                <mailto-link
                    v-if="!!row.email"
                    :email="row.email"
                    :copy="true"
                    class="btn btn-link btn-sm btn-block my-2"
                    @click.stop
                >
                    <template v-slot:default="{ copied }">
                        <open-icon :glyph="copied ? 'check-circle' : 'envelope'" />
                        {{ row.email }}
                    </template>
                </mailto-link>
                <base-link
                    v-if="!!row.phone"
                    :href="row.phone | nibnut.telto"
                    @click.stop
                    class="btn btn-link btn-sm btn-block my-2"
                >
                    <open-icon glyph="phone" />
                    {{ row.phone }}
                </base-link>
                <div class="columns mt-2">
                    <div
                        :class="{ 'col-12': (rows.length <= 1), 'col-4': (rows.length > 1) }"
                        class="column"
                    >
                        <default-button
                            flavor="link"
                            size="sm"
                            :title="!!row.contacts_plus_id ? $root.translate('Disconnect Contacts+') : $root.translate('Connect to Contacts+')"
                            :block="(rows.length <= 1)"
                            class="contacts-plus-button mt-2"
                            @click.prevent.stop="contacts_plus_toggle(row)"
                        >
                            <contacts-plus-logo :active="!!row.contacts_plus_id" />
                        </default-button>
                    </div>
                    <div v-if="rows.length > 1" class="column col-8">
                        <default-button
                            flavor="link"
                            size="sm"
                            color="error"
                            :block="true"
                            @click.prevent.stop="confirm_record_delete(row)"
                        >
                            <open-icon
                                glyph="trash"
                            />
                        </default-button>
                    </div>
                </div>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <default-button
                    v-if="field === 'is_main'"
                    flavor="link"
                    size="sm"
                    :color="is_main_contact(row) ? 'primary' : 'light'"
                    :title="is_main_contact(row) ? $root.translate('Main Contact') : $root.translate('Make this contact the main contact')"
                    @click.prevent.stop="$emit('input', row.id)"
                >
                    <open-icon
                        :glyph="is_main_contact(row) ? 'star' : 'asterisk'"
                    />
                </default-button>
                <div v-else-if="field === 'computed_name'">
                    {{ row.computed_name }}
                    <div
                        v-if="!!row.position"
                        class="text-small text-gray"
                    >
                        {{ row.position }}
                    </div>
                </div>
                <div
                    v-else-if="field === '_buttons'"
                    class="text-right"
                >
                    <default-button
                        v-if="rows.length > 1"
                        flavor="link"
                        size="sm"
                        color="error"
                        class="mr-2"
                        @click.prevent.stop="confirm_record_delete(row)"
                    >
                        <open-icon
                            glyph="trash"
                        />
                    </default-button>
                    <remote-connection-button
                        flavor="link"
                        size="sm"
                        :remote-id="row.freescout_id"
                        remote-service-name="FreeScout"
                        :url="freescout_url(`customers/${row.freescout_id}/edit`)"
                        class="freescout-button mr-2"
                        @click="freescout_toggle(row)"
                    >
                        <freescout-logo :active="!!row.freescout_id" />
                    </remote-connection-button>
                    <!--
                    <default-button
                        flavor="link"
                        size="sm"
                        :title="!!row.freescout_id ? $root.translate('Click to view in FreeScout. Command-click to disconnect') : $root.translate('Connect to Freescout')"
                        @click.prevent.stop="freescout_toggle(row, $event)"
                    >
                        <freescout-logo :active="!!row.freescout_id" />
                    </default-button>
                    //-->
                    <remote-connection-button
                        flavor="link"
                        size="sm"
                        :remote-id="row.contacts_plus_id"
                        remote-service-name="Contacts+"
                        :url="contacts_plus_url(row.contacts_plus_id)"
                        class="contacts-plus-button mr-2"
                        @click="contacts_plus_toggle(row)"
                    >
                        <contacts-plus-logo :active="!!row.contacts_plus_id" />
                    </remote-connection-button>
                    <!--
                    <default-button
                        flavor="link"
                        size="sm"
                        :title="!!row.contacts_plus_id ? $root.translate('Disconnect Contacts+') : $root.translate('Connect to Contacts+')"
                        class="contacts-plus-button"
                        @click.prevent.stop="contacts_plus_toggle(row)"
                    >
                        <contacts-plus-logo :active="!!row.contacts_plus_id" />
                    </default-button>
                    //-->
                    <div class="popover popover-top">
                        <default-button
                            flavor="link"
                            size="sm"
                            @click.prevent.stop="edit_contact_notes(row)"
                        >
                            <open-icon
                                :glyph="row.notes ? 'comment-dots' : 'comment-alt'"
                                :class="{ 'text-primary': !!row.notes, 'text-gray': !row.notes }"
                            />
                        </default-button>
                        <div v-if="!!row.notes" class="popover-container text-left">
                            <div class="card">
                                <div class="card-body">
                                    <div v-html="row.notes"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="field === 'email'">
                    <div
                        v-if="!!row.phone"
                    >
                        <open-icon glyph="phone" />
                        {{ row.phone }}
                    </div>
                    <mailto-link
                        v-if="!!row.email"
                        :email="row.email"
                        :copy="true"
                        class="btn btn-link text-small"
                        @click.stop
                    >
                        <template v-slot:default="{ copied }">
                            <open-icon :glyph="copied ? 'check-circle' : 'envelope'" />
                            {{ row.email }}
                        </template>
                    </mailto-link>
                </div>
                <span v-else>
                    {{ row[field] }}
                </span>
            </template>
        </data-table>

        <connect-dialog
            :show.sync="connecting"
            :record="connecting_contact"
            :service="connection_service"
            :relation-ids="['ticket']"
            @connected="connected"
        />

        <modal-dialog
            v-if="!!edited_record"
            id="contact-editor"
            :show.sync="editing"
        >
            <template v-slot:title>
                <span v-if="!!edited_record.id" class="h5">
                    {{ $root.translate("Contact \"{name}\"", { name: edited_record.computed_name }) }}
                </span>
                <span v-else class="h5">
                    {{ $root.translate("New contact for \"{name}\"", { name: client.company }) }}
                </span>
            </template>

            <contact-editor
                v-if="edited_record"
                :contact="edited_record"
                :creation-errors="creation_errors"
            />

            <template v-slot:footer>
                <div v-if="!edited_record.id" class="text-center">
                    <default-button
                        :disabled="creating"
                        class="mr-2"
                        @click.prevent="editing = false"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        :disabled="creating"
                        :waiting="creating"
                        color="primary"
                        class="ml-2"
                        @click.prevent="create_contact"
                    >
                        {{ $root.translate("Create") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>

        <modal-dialog
            v-if="!!edited_record"
            id="contact-notes-editor"
            :show.sync="editing_note"
        >
            <template v-slot:title>
                <span class="h5">
                    {{ $root.translate("Contact \"{name}\" Notes", { name: edited_record.computed_name }) }}
                </span>
            </template>

            <form-editor
                id="notes"
                name="notes"
                v-model="edited_record.notes"
                size="lg"
                :required="false"
                :full-height="false"
                @input="save"
            />
        </modal-dialog>

        <!--
        <modal-dialog
            v-if="!!edited_record"
            id="contacts-plus-client-picker"
            :show.sync="connecting_to_contacts_plus"
        >
            <template v-slot:title>
                <span class="h5">
                    {{ $root.translate("Link {name} to Contacts+ Customer", { name: edited_record.computed_name }) }}
                </span>
            </template>

            <form-input
                id="contacts_plus_query"
                name="contacts_plus_query"
                v-model="contacts_plus_query"
                :live="true"
                :required="false"
                :saving="querying_contacts_plus"
            >
                <template v-slot:hint>{{ $root.translate("Type at least 3 characters of a contact name or email") }}</template>
                <template v-slot:right_addon>
                    <default-button
                        color="primary"
                        :disabled="contacts_plus_query.length < 3"
                        loader-size="xs"
                        :waiting="querying_contacts_plus"
                        @click.prevent="query_contacts_plus"
                    >
                        <open-icon glyph="search" />
                    </default-button>
                </template>
            </form-input>

            <table
                class="table"
            >
                <tbody>
                    <tr
                        v-for="contacts_plus_result in contacts_plus_results"
                        :key="contacts_plus_result.id"
                        @click.prevent="contacts_plus_match = contacts_plus_result.id"
                        :class="{'active': contacts_plus_match === contacts_plus_result.id}"
                    >
                        <td>
                            <open-icon
                                :glyph="(contacts_plus_match === contacts_plus_result.id) ? 'check-circle' : 'circle'"
                                class="ml-2"
                            />
                            {{ contacts_plus_result.name }}<span v-if="contacts_plus_result.email" class="text-muted ml-2">{{ contacts_plus_result.email }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td
                            @click.prevent="contacts_plus_match = constants('FAKE_CONTACTS_PLUS_ID')"
                            :class="{'active': contacts_plus_match === constants('FAKE_CONTACTS_PLUS_ID')}"
                        >
                            <open-icon
                                :glyph="(contacts_plus_match === constants('FAKE_CONTACTS_PLUS_ID')) ? 'check-circle' : 'circle'"
                                class="ml-2"
                            />
                            {{ $root.translate("Create New Client in Contacts+") }}
                        </td>
                    </tr>
                </tbody>
            </table>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        :disabled="linking_to_contacts_plus"
                        class="mr-2"
                        @click.prevent="connecting_to_contacts_plus = false"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        :disabled="linking_to_contacts_plus"
                        :waiting="linking_to_contacts_plus"
                        color="primary"
                        class="ml-2"
                        @click.prevent="link_contacts_plus"
                    >
                        {{ $root.translate("Link") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>
        //-->

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            :waiting="deleting_record"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex"

import { is_data_table_source, handles_saving, confirms } from "@/nibnut/mixins"

import { ModalDialog, MailtoLink, FormEditor, BaseLink, DefaultButton, OpenIcon } from "@/nibnut/components"
import {
    ContactsPlusLogo,
    FreescoutLogo,
    RemoteConnectionButton
} from "@/custom/components"
import ContactEditor from "./ContactEditor"
import ConnectDialog from "./ConnectDialog"

export default {
    name: "Contacts",
    mixins: [is_data_table_source, handles_saving, confirms],
    components: {
        ContactsPlusLogo,
        FreescoutLogo,
        RemoteConnectionButton,
        ModalDialog,
        MailtoLink,
        FormEditor,
        BaseLink,
        DefaultButton,
        OpenIcon,
        ContactEditor,
        ConnectDialog
    },
    mounted () {
        this.reroot()
    },
    watch: {
        "client.id": "reroot"
    },
    methods: {
        reroot () {
            this.set_state_values({
                total: this.rows.length,
                found: this.filtered_rows.length
            })
        },
        is_main_contact (contact) {
            if(!this.client || !this.client.main_contact_id) return false
            return contact.id === this.client.main_contact_id
        },
        edit_record (contact) {
            this.edited_record = contact
            if(this.client.id) this.editing = true
        },
        add_record () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: "contact" }
            ).then(record => {
                record.client_id = this.client.id
                this.edit_record(record)
            }).catch(error => {
                this.$error(error.message)
            })
        },
        create_contact () {
            if(this.edited_record && !this.edited_record.id) {
                this.creating = true
                this.creation_errors = {}
                this.$store.dispatch("CREATE_RECORD", {
                    entity: this.entity,
                    data: {
                        ...this.edited_record,
                        relation_ids: ["client"]
                    }
                }).then(() => {
                    this.editing = false
                    this.edited_record = null
                    this.refresh()
                }).catch(error => {
                    if(error.topic_id && !!document.getElementById(error.topic_id)) {
                        this.$set(this.creation_errors, error.topic_id, error.message)
                    } else this.topic_less_error(error)
                }).then(() => {
                    this.creating = false
                })
            }
        },
        save (value, field, contact = null) {
            if(!contact) contact = this.edited_record
            contact[field] = value
            if(contact.id) return this.save_field_for_record_id(this.entity, contact.id, value, field)
            return Promise.resolve()
        },
        freescout_toggle (contact) {
            if(contact) {
                this.connecting_contact = contact
                this.connection_service = "freescout"
                if(!contact.freescout_id) {
                    this.connecting = true
                } else {
                    this.save(null, "freescout_id", contact).then(() => {
                        this.connected(0)
                    }).catch(error => {
                        this.$error(error.message)
                    })
                }
            }
        },
        freescout_url (path) {
            if(!this.app_context) return ""
            return `https://${this.app_context.settings.freescout_domain}/${path}`
        },
        contacts_plus_toggle (contact) {
            if(contact) {
                if(contact.contacts_plus_id) {
                    this.save(null, "contacts_plus_id", contact).catch(error => {
                        this.$error(error.message)
                    })
                } else {
                    this.connecting_contact = contact
                    this.connection_service = "contacts-plus"
                    this.connecting = true
                    /*
                    this.edited_record = contact
                    this.contacts_plus_match = this.constants("FAKE_CONTACTS_PLUS_ID")
                    this.contacts_plus_query = ""
                    this.contacts_plus_results = []
                    this.connecting_to_contacts_plus = true
                    */
                }
            }
        },
        contacts_plus_url (contacts_plus_id) {
            if(!this.app_context || !this.app_context.settings.contacts_plus_url_prefix) return ""
            return `https://${this.app_context.settings.contacts_plus_domain}/contacts/${this.app_context.settings.contacts_plus_url_prefix}/${contacts_plus_id}`
        },
        connected (freescout_id) {
            this.$emit("connected", freescout_id, this.connection_service)
        },
        /*
        query_contacts_plus () {
            if(!this.contacts_plus_query || (this.contacts_plus_query.length < 3)) return
            this.contacts_plus_match = this.constants("FAKE_CONTACTS_PLUS_ID")
            this.querying_contacts_plus = true
            this.$store.dispatch(
                "CONTACTS_PLUS_CUSTOMER_QUERY",
                {
                    query: this.contacts_plus_query
                }
            ).then(response => {
                this.contacts_plus_results = response
                if(this.contacts_plus_results.length === 1) this.contacts_plus_match = this.contacts_plus_results[0].id
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.querying_contacts_plus = false
            })
        },
        link_contacts_plus () {
            if(this.edited_record) {
                const contacts_plus_match = this.contacts_plus_match || null
                if(!contacts_plus_match) this.connecting_to_contacts_plus = false
                else if(!this.edited_record.id) {
                    this.edited_record.contacts_plus_id = contacts_plus_match
                    this.connecting_to_contacts_plus = false
                } else {
                    this.linking_to_contacts_plus = true
                    this.save(contacts_plus_match, "contacts_plus_id").then(() => {
                        this.connecting_to_contacts_plus = false
                    }).catch(error => {
                        this.$error(error.message)
                    }).then(() => {
                        this.linking_to_contacts_plus = false
                    })
                }
            }
        },
        */
        confirm_record_delete (contact) {
            this.edited_record = contact
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete Contact"),
                    message: this.$root.translate("Do you really want to delete the contact \"{name}\"? There is no undo...", { name: contact.computed_name }),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-record"
            )
        },
        record_delete () {
            this.deleting_record = true
            this.$store.dispatch(
                "RECORD_DELETE",
                {
                    entity: this.entity,
                    id: this.edited_record.id
                }
            ).then(() => {
                this.done_confirming()
            }).catch(this.receive_error).then(() => {
                this.deleting_record = false
            })
        },
        confirmed () {
            if(this.confirming === "delete-record") this.record_delete()
            else this.done_confirming()
        },
        edit_contact_notes (contact) {
            this.edited_record = contact
            this.editing_note = true
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        raw_contacts () {
            return this.entity_records("contact")
        },
        state_identifier () {
            return "client-contacts"
        },
        rows () {
            if(!this.client || !this.client.id) return []
            return this.raw_contacts.filter(contact => contact.client_id === this.client.id)
        }
    },
    props: {
        client: {
            type: Object,
            required: true
        },
        shell: {
            validator: prop => !prop || (typeof prop === "object")
        }
    },
    data () {
        return {
            entity: "contact",
            columns: {
                is_main: { label: " ", sort: false },
                computed_name: { label: "Name", sort: "asc", type: "alpha" },
                email: { label: "Contact", sort: null, type: "alpha" },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                client_id: 0
            },

            edited_record: null,
            editing: false,
            creation_errors: {},
            creating: false,
            editing_note: false,

            deleting_record: false,

            connecting: false,
            connection_service: "",
            connecting_contact: {}
            /*
            contacts_plus_query: "",
            contacts_plus_results: [],
            connecting_to_contacts_plus: false,
            querying_contacts_plus: false,
            contacts_plus_match: null,
            linking_to_contacts_plus: false
            */
        }
    }
}
</script>
