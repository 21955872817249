<template>
    <th
        @mouseover="hovered=true"
        @mouseout="hovered=false"
        @click="click"
    >
        {{ label }}
        <open-icon
            v-if="sort !== false"
            :glyph="glyph"
            :class="{ 'active': !!sort || hovered, 'text-gray': !sort && !hovered, 'ml-4': !compact }"
        />
    </th>
</template>

<script type="text/javascript">
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "DataTableHeaderCell",
    components: {
        OpenIcon
    },
    methods: {
        click () {
            this.$emit("click", this.field, (this.sort === "desc") ? "asc" : "desc")
        }
    },
    computed: {
        glyph () {
            if(this.hovered) {
                if(this.sort && this.sort.match(/^desc/i)) return `sort-${this.type}-down`
                return `sort-${this.type}-down-alt`
            }
            if(this.sort && this.sort.match(/^desc/i)) return `sort-${this.type}-down-alt`
            return `sort-${this.type}-down`
        }
    },
    props: {
        field: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        sort: {
            validator: prop => !prop || prop.match(/^(asc|desc)$/),
            required: true
        },
        type: {
            type: String,
            validator: prop => !!prop && prop.match(/^(amount|numeric|alpha)$/),
            deafult: "amount"
        },
        compact: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            hovered: false
        }
    }
}
</script>
