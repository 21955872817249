<template>
    <page
        id="clients-list"
        :title="$root.translate('Client:::Clients', {}, 2)"
        :status="page_status"
        :waiting="waiting || loading"
        @statused="statused"
    >
        <template v-slot:title>
            <h1>
                {{ $root.translate('Client:::Clients', {}, 2) }}
                <default-button
                    :title="$root.translate('New Client')"
                    flavor="link"
                    @click.prevent="record_create(entity)"
                >
                    <open-icon glyph="plus" />
                </default-button>
            </h1>
        </template>
        <data-table
            id="clients-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="true"
            :export-url="export_url"
            @sort="sort_by"
            @search="search"
            @clear-search="clear_search"
            @page="goto_page"
            @add="record_create(entity)"
            @click="record_edit(entity, $event.id)"
        >
            <template
                v-slot:controls
            >
                <div>
                    <form-select
                        id="plan"
                        name="plan"
                        :value="state.filter"
                        data-source="client"
                        :empty-value="0"
                        :empty-label="$root.translate('All Contracts')"
                        :show-all="true"
                        :required="false"
                        @input="filter('plan', $event)"
                    />
                </div>
                <div>
                    <form-select
                        id="type"
                        name="type"
                        :value="state.type_filter"
                        :data-source="client_types"
                        :empty-value="0"
                        :empty-label="$root.translate('All Tags')"
                        :show-all="true"
                        :required="false"
                        @input="filter_by_type($event)"
                    />
                </div>
                <div>
                    <form-select
                        id="category"
                        name="category"
                        :value="state.category_filter"
                        data-source="client"
                        :empty-value="0"
                        :empty-label="$root.translate('Any Type')"
                        :show-all="true"
                        :required="false"
                        @input="filter_by_category($event)"
                    />
                </div>
            </template>
            <template
                v-slot:summary="{ row }"
            >
                <h6>
                    {{ row.computed_name }}<span class="chip ml-2">{{ client_type(row) }}</span>
                    <div
                        v-if="!!row.position || !!row.company"
                        class="text-small text-gray"
                    >
                        {{ row.position }}<span v-if="!!row.position && !!row.company"> at </span>{{ row.company }}
                    </div>
                </h6>
                <mailto-link
                    v-if="!!row.computed_email"
                    :email="row.computed_email"
                    :copy="true"
                    class="btn btn-link btn-sm my-2"
                    @click.stop
                >
                    <template v-slot:default="{ copied }">
                        <open-icon :glyph="copied ? 'check-circle' : 'envelope'" />
                        {{ row.computed_email }}
                    </template>
                </mailto-link>
                <br v-if="!!row.computed_email && !!row.computed_phone" />
                <base-link
                    v-if="!!row.computed_phone"
                    :href="row.computed_phone | nibnut.telto"
                    @click.stop
                    class="btn btn-link btn-sm my-2"
                >
                    <open-icon glyph="phone" />
                    {{ row.computed_phone }}
                </base-link>
                <div class="text-small">
                    <span v-if="!!row.next_contact_on_as_string">
                        {{ $root.translate("Next contact on {when}", { when: nibnut_filter("nibnut.date", [row.next_contact_on_as_string, "DD/MM/YYYY"]) }) }}
                    </span>
                    <span v-else>
                        {{ $root.translate("No contact scheduled") }}
                    </span>
                    <div
                        class="text-gray"
                    >
                        <span v-if="!!row.last_contacted_on_as_string">
                            {{ $root.translate("Last contacted on {when}", { when: nibnut_filter("nibnut.date", [row.last_contacted_on_as_string, "DD/MM/YYYY"]) }) }}
                        </span>
                        <span v-else>
                            {{ $root.translate("Never contacted") }}
                        </span>
                    </div>
                </div>
                <div class="text-small">
                    <span v-if="$moment().diff($moment(row.joined_on_as_string), 'hours') < 24">{{ $root.translate("Joined Today") }}</span>
                    <span v-else>{{ $root.translate("Joined {when}", { when: nibnut_filter("nibnut.date_from_now", [row.joined_on_as_string]) }) }}</span>
                    <div
                        class="text-gray"
                    >
                        {{ row.joined_on_as_string | nibnut.date('DD/MM/YYYY') }}
                        <span v-if="!!row.referred_by"> &bull; {{ $root.translate("Referred by {name}", { name: row.referred_by }) }}</span>
                    </div>
                    <div
                        class="text-gray"
                    >
                        {{ $root.translate("LTV") }}: {{ row.computed_lifetime_value | nibnut.currency }}
                    </div>
                </div>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div v-if="field === 'computed_name'">
                    {{ row.computed_name }}<span class="chip ml-2">{{ client_type(row) }}</span>
                    <div
                        v-if="!!row.position || !!row.company"
                        class="text-small text-gray"
                    >
                        {{ row.position }}<span v-if="!!row.position && !!row.company"> at </span>{{ row.company }}
                    </div>
                </div>
                <div v-else-if="field === 'computed_email'">
                    <mailto-link
                        v-if="!!row.computed_email"
                        :email="row.computed_email"
                        :copy="true"
                        class="btn btn-link"
                        @click.stop
                    >
                        <template v-slot:default="{ copied }">
                            <open-icon :glyph="copied ? 'check-circle' : 'envelope'" />
                            {{ row.computed_email }}
                        </template>
                    </mailto-link>
                    <div
                        v-if="!!row.computed_phone"
                        class="text-small"
                    >
                        <open-icon glyph="phone" />
                        {{ row.computed_phone }}
                    </div>
                </div>
                <div v-else-if="field === 'next_contact_on_as_string'">
                    <span
                        v-if="!!row.next_contact_on_as_string"
                        :class="{ 'text-error': $moment(row.next_contact_on_as_string).isBefore() }"
                    >
                        {{ row.next_contact_on_as_string | nibnut.date('DD/MM/YYYY') }}
                    </span>
                    <span v-else class="text-gray">{{ $root.translate("Not scheduled") }}</span>
                    <div
                        class="text-small text-gray"
                    >
                        <span v-if="!!row.last_contacted_on_as_string">{{ row.last_contacted_on_as_string | nibnut.date('DD/MM/YYYY') }}</span>
                        <span v-else>{{ $root.translate("Never contacted") }}</span>
                    </div>
                </div>
                <div v-else-if="field === 'joined_on_as_string'">
                    <span v-if="$moment().diff($moment(row.joined_on_as_string), 'hours') < 24">{{ $root.translate("Today") }}</span>
                    <span v-else>{{ row.joined_on_as_string | nibnut.date_from_now }}</span>
                    <div
                        class="text-small text-gray"
                    >
                        {{ row.joined_on_as_string | nibnut.date('DD/MM/YYYY') }}
                        <span v-if="!!row.referred_by"> &bull; {{ $root.translate("Referred by {name}", { name: row.referred_by }) }}</span>
                    </div>
                </div>
                <div v-else-if="field === 'computed_contracts'">
                    {{ row.computed_contracts }}
                    <div
                        v-if="!!row.computed_revenue"
                        class="text-small text-gray"
                    >
                        {{ row.computed_revenue | nibnut.currency }}&nbsp;/&nbsp;{{ $root.translate("month") }}
                        |
                        {{ (row.computed_revenue * 12) | nibnut.currency }}&nbsp;/&nbsp;{{ $root.translate("year") }}
                    </div>
                </div>
                <span v-else-if="field === 'computed_lifetime_value'">
                    <span v-if="!!row.computed_lifetime_value" class="no-wrap">{{ row.computed_lifetime_value | nibnut.currency }}</span>
                </span>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>
    </page>
</template>

<script type="text/javascript">
import { is_page, is_remote_data_table_source, crud_utilities, misc_utilities, ui_utilities } from "@/nibnut/mixins"

import {
    DefaultButton,
    OpenIcon,
    MailtoLink,
    BaseLink,
    FormSelect
} from "@/nibnut/components"

export default {
    name: "SiteList",
    mixins: [is_page, is_remote_data_table_source, crud_utilities, misc_utilities, ui_utilities],
    components: {
        DefaultButton,
        OpenIcon,
        MailtoLink,
        BaseLink,
        FormSelect
    },
    methods: {
        filter_rows (rows) {
            if(this.state.filter_on && this.state.filter) {
                return rows.filter((row) => {
                    if(this.state.filter_on.match(/_at$/i)) return !!row[this.state.filter_on] === !!parseInt(this.state.filter)
                    if(this.state.filter_on === "plan") return (row.plan_ids.indexOf(this.state.filter) >= 0)
                    return row[this.state.filter_on] === this.state.filter
                })
            }
            return rows
        },
        client_type (client) {
            if(!client || !client.type) return ""
            const type = Object.values(this.constants("client_types")).find(type => type.id === client.type)
            return type ? type.name : ""
        },
        clear_search () {
            this.clearing_search = true
            this.set_state_values({
                page: 1,
                filter_on: "plan",
                filter: 0,
                type_filter: 0,
                archived: false,
                search: ""
            })
            this.refresh()
            setTimeout(() => {
                this.clearing_search = false
            }, 750)
        },
        filter_by_type (type_filter) {
            this.set_state_values({
                page: 1,
                type_filter
            })
            this.refresh()
        },
        filter_by_category (category_filter) {
            this.set_state_values({
                page: 1,
                category_filter
            })
            this.refresh()
        }
    },
    computed: {
        fields () {
            return [
                ...Object.keys(this.columns),
                "type",
                "company", "position",
                "computed_phone", "referred_by",
                "plan_ids",
                "computed_sites",
                "computed_revenue",
                "last_contacted_on_as_string"
            ]
        },
        client_types () {
            return Object.values(this.constants("client_types"))
        }
    },
    data () {
        return {
            entity: "client",
            // relation_ids: ["monitor"],
            columns: {
                computed_name: { label: "Name", sort: null, type: "alpha" },
                computed_email: { label: "Contact", sort: null, type: "alpha" },
                next_contact_on_as_string: { label: "Next Contact", sort: null, type: "numeric" },
                joined_on_as_string: { label: "Since", sort: "desc", type: "numeric" },
                computed_contracts: { label: "Plans", sort: null, type: "alpha" },
                computed_lifetime_value: { label: "LTV", sort: null, type: "numeric" }
            },
            default_state: {
                per_page: 50,
                page: 1,
                sort_by: "joined_on_as_string",
                sort_dir: "desc",
                filter_on: "plan",
                filter: 0,
                type_filter: 0,
                category_filter: "",
                archived: false,
                search: "",
                total: 0,
                found: 0
            },

            sampling: false
        }
    }
}
</script>

<style lang="scss">
#clients-list {
    table.table {
        max-width: 100%;

        .btn.btn-link {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 80vw;
        }
    }
}
</style>
