<template>
    <div v-if="edited_record" class="card">
        <div class="card-header">
            <div class="card-title h5">{{ translate("Default 12-months Project Values") }}</div>
        </div>
        <div class="card-body">
            <div class="columns">
                <form-input
                    id="price_first_period"
                    name="price_first_period"
                    v-model="edited_record.price_first_period"
                    glyph="dollar-sign"
                    glyph-position="left"
                    :required="false"
                    class="column"
                    @input="save"
                >
                    <template v-slot:label>
                        {{ translate("Project Startup") }}
                    </template>
                </form-input>
                <form-input
                    id="price_recurring_period"
                    name="price_recurring_period"
                    v-model="edited_record.price_recurring_period"
                    glyph="dollar-sign"
                    glyph-position="left"
                    :required="false"
                    class="column"
                    @input="save"
                >
                    <template v-slot:label>
                        {{ translate("Project Recurring") }}
                    </template>
                </form-input>
                <form-input
                    id="duration"
                    name="duration"
                    type="number"
                    v-model="edited_record.duration"
                    :required="false"
                    :min="1"
                    class="column"
                    @input="save"
                >
                    <template v-slot:label>
                        {{ translate("Duration") }}
                    </template>
                    <template v-slot:right_addon>
                        <span class="input-group-addon">{{ translate("month:::months", {}, edited_record.duration) }}</span>
                    </template>
                </form-input>
            </div>
        </div>
    </div>
</template>

<script>
import { is_record_editor } from "@/nibnut/mixins"

import { FormInput } from "@/nibnut/components"

export default {
    name: "ProjectTypes",
    mixins: [is_record_editor],
    components: {
        FormInput
    },
    methods: {
        grab_record_id () {
            this.record_id = 1
        }
    },
    data () {
        return {
            entity: "project_type",
            entityName: "12-months project:::12-months projects"
        }
    }
}
</script>
