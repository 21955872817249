import Axios from "axios"

// window.axios = Axios
Axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"
// Axios.defaults.headers.common["X-CSRF-TOKEN"] = document.querySelector("meta[name="csrf-token"]").getAttribute("content")

let _heartbeat_handler = null
Axios.interceptors.response.use(response => {
    // console.log("Axios.interceptors.response", response) // , response.request.responseText)
    if(response.data && response.data.meta && response.data.meta.csrf) Axios.defaults.headers.common["X-CSRF-TOKEN"] = response.data.meta.csrf

    if(response.data && response.data.redirect) window.location = response.data.redirect

    if(_heartbeat_handler && response.data && response.data.heartbeat_data) _heartbeat_handler(response.data.heartbeat_data)

    return Promise.resolve(response.data || response)
}, (error) => {
    return Promise.reject(error.response || error)
})

function fetch (url, data, method = "get") {
    let params = data
    if(method.match(/^(get|delete)$/i)) params = { params }
    return Axios[method](
        url,
        params
    )
}

export default {
    heartbeat_handler (handler) {
        _heartbeat_handler = handler
    },
    heartbeat () {
        return fetch("/api/heartbeat")
    },
    load_profile () {
        return fetch("/api/profile", { fields: ["fieldset::profile"] })
    },
    login (email, password) {
        return fetch("/api/login", { email, password, fields: ["fieldset::profile"] }, "post")
    },
    logout () {
        return fetch("/api/logout", {}, "post")
    },
    reinvite (email, expires_in) {
        return this.records_action("user", "re-invite", { email, expires_in }, "post")
    },
    invitation (token) {
        return fetch(`/api/user/invitation/${token}`)
    },
    signup (data) {
        return fetch("/api/signup", data, "post")
    },
    send_password_reset (email) {
        return fetch("/api/password/email", { email }, "post")
    },
    reset_pasword (token, email, password, password_confirmation) {
        return fetch("/api/password/reset", {
            token,
            email,
            password,
            password_confirmation
        }, "post")
    },
    records_action (entity, action, data, method = "get") {
        return fetch(`/api/${entity}/${action}`, data, method)
    },
    records (entity, query) {
        return this.records_action(entity, "records", query)
    },
    record_action (entity, id, action, data, method = "get") {
        return fetch(`/api/${entity}/${id}/${action}`, data, method)
    },
    record_shell (entity) {
        return this.records_action(entity, "shell")
    },
    record_create (entity, data) {
        return this.records_action(entity, "create", data, "post")
    },
    record (entity, id, query) {
        return this.record_action(entity, id, "", query)
    },
    record_delete (entity, id, data) {
        return this.record_action(entity, id, "", data, "delete")
    },
    record_restore (entity, id, data) {
        return this.record_action(entity, id, "restore", data, "post")
    },
    record_save (entity, id, data) {
        return this.record_action(entity, id, "save", data, "post")
    },
    file_action (entity, id, action, name, file, progress, xtra_data, method = "post") {
        const config = {}
        const data = new FormData()

        if(file) {
            data.append(name, file)
            config.headers = { "content-type": "multipart/form-data" }
        } else data.append(name, true)
        if(xtra_data) Object.keys(xtra_data).forEach(key => data.append(key, xtra_data[key]))

        return this.record_action(entity, id, action || "attach", data, method)
    },
    autosuggest (entity, context, data) {
        return fetch(`/api/${entity}/autosuggest/${context}`, data)
    },
    remote_entity_query (entity, service, query) {
        return fetch(`/api/${entity}/remote-search`, { service, query })
    },
    qbo_print (entity, id) {
        return fetch(`/api/${entity}/${id}/qbo-print`)
    },
    qbo_send_email (entity, id) {
        return fetch(`/api/${entity}/${id}/qbo-send`)
    },
    contacts_plus_customer_query (query) {
        return fetch("/api/client/contacts-plus-search", { query })
    }
}
