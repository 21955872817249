<template>
    <img
        :src="src"
        class="quickbooks-button-image"
        @mouseover="hover=true"
        @mouseout="hover=false"
    />
</template>

<script type="text/javascript">
import qbo_white_hover from "@/assets/img/C2QB_white_btn_sm_hover.png"
import qbo_white_default from "@/assets/img/C2QB_white_btn_sm_default.png"
import qbo_green_hover from "@/assets/img/C2QB_green_btn_sm_hover.png"
import qbo_green_default from "@/assets/img/C2QB_green_btn_sm_default.png"

export default {
    computed: {
        src () {
            if(!this.white && this.hover) return qbo_green_hover
            if(!this.white) return qbo_green_default
            if(this.white && this.hover) return qbo_white_hover
            return qbo_white_default
        }
    },
    props: {
        white: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            hover: false
        }
    }
}
</script>

<style lang="scss">
.quickbooks-button-image {
    max-width: 100%;
    max-height: 100%;
}
</style>
