<template>
    <page
        id="report-view"
        :title="title"
        :status="page_status"
        :waiting="waiting"
        class="public-page-content"
        @statused="statused"
    >
        <template v-slot:title>
            <h3 id="top-of-page">{{ title }}</h3>
            <h1 v-if="!!edited_record && edited_record.content">{{ edited_record.content.overview.url }}</h1>
            <h2 v-if="!!edited_record">
                {{ edited_record.from_date | nibnut.date("MMMM YYYY") }}
                <div
                    v-if="!!edited_record.history && (edited_record.history.length > 1)"
                    class="popover popover-bottom"
                >
                    <default-button flavor="link">
                        <open-icon glyph="calendar"  />
                    </default-button>
                    <div class="popover-container text-small">
                        <ul class="menu">
                            <li
                                v-for="report in edited_record.history"
                                :key="report.id"
                                class="menu-item"
                            >
                                <base-link
                                    :href="`/report/${report.id}`"
                                >
                                    {{ report.name }}
                                </base-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="text-small text-gray">{{ edited_record.from_date | nibnut.date }} - {{ edited_record.to_date | nibnut.date }}</div>
            </h2>
        </template>

        <div v-if="!!edited_record && edited_record.content" class="columns align-items-center mt-8">
            <div class="column flex-grow">
                <ul class="toc">
                    <li
                        v-for="section in sections"
                        :key="section"
                        class="toc-item"
                    >
                        <base-link
                            :href="`#${section}`"
                            @click.prevent.stop="scroll_to_hash(`#${section}`)"
                        >
                            <open-icon :glyph="section_glyph(section)" /> {{ section_title(section) }}
                        </base-link>
                    </li>
                </ul>
            </div>
            <div class="column flex-static hide-sm print-hide">
                <default-button
                    flavor="link"
                    @click.prevent="print"
                >
                    <open-icon glyph="print" />
                </default-button>
            </div>
        </div>

        <div
            v-for="section in sections"
            :key="section"
        >
            <h4
                :id="section"
                class="columns align-items-center"
            >
                <div class="column">
                    <open-icon :glyph="section_glyph(section)" /> {{ section_title(section) }}
                </div>
                <div
                    v-if="(section === 'tickets') && (!!edited_record.used_credits || !!edited_record.available_credits)"
                    class="column col-auto text-small"
                >
                    {{ translate("Credits") }}:&nbsp;{{ translate("1 used:::{n} used", { n: edited_record.used_credits }, edited_record.used_credits) }}&nbsp;/&nbsp;{{ translate("1 available:::{n} available", { n: edited_record.available_credits }, edited_record.available_credits) }}<span v-if="!!edited_record.carry_over_credits">&nbsp;({{ translate("1 carried forward:::{n} carried forward", { n: edited_record.carry_over_credits }, edited_record.carry_over_credits) }})</span>
                </div>
            </h4>
            <table
                v-if="section === 'overview'"
                class="table summary"
            >
                <tbody>
                    <tr class="hide-sm">
                        <th>
                            {{ $root.translate("Website") }}
                            <img v-if="!!edited_record.content.overview.snapshot" :src="edited_record.content.overview.snapshot" />
                        </th>
                        <td>
                            <base-link
                                :href="edited_record.content.overview.url"
                            >
                                {{ edited_record.content.overview.url }}
                            </base-link>
                        </td>
                    </tr>
                    <tr v-if="!!edited_record.content.overview.hosting">
                        <th>{{ $root.translate("Hosting") }}</th>
                        <td>{{ edited_record.content.overview.hosting }}</td>
                    </tr>
                    <tr v-if="!!edited_record.content.overview.dns">
                        <th>{{ $root.translate("DNS") }}</th>
                        <td>{{ edited_record.content.overview.dns }}</td>
                    </tr>
                    <tr v-if="!!edited_record.content.overview.registrar">
                        <th>{{ $root.translate("Registrar") }}</th>
                        <td>{{ edited_record.content.overview.registrar }}</td>
                    </tr>
                    <tr>
                        <th>{{ $root.translate("Domain Expiration Date") }}</th>
                        <td>{{ edited_record.content.overview.domain_expires_on }}</td>
                    </tr>
                    <tr>
                        <th>{{ $root.translate("SSL Expiration Date") }}</th>
                        <td>{{ edited_record.content.overview.ssl_expires_on }}</td>
                    </tr>
                    <tr>
                        <th>{{ $root.translate("Active Theme") }}</th>
                        <td>{{ edited_record.content.overview.active_theme }}</td>
                    </tr>
                    <tr>
                        <th>
                            <span class="hide-sm">{{ $root.translate("Website Uptime or Availability") }}</span>
                            <span class="show-sm">{{ $root.translate("Website Uptime") }}</span>
                        </th>
                        <td>
                            <span v-if="edited_record.content.overview.uptime === 100">100%</span>
                            <span v-else>{{ $root.translate("{percent}%", { percent: nibnut_filter("nibnut.number", [edited_record.content.overview.uptime, "0,0.000"]) }) }}</span>
                        </td>
                    </tr>
                    <tr v-if="edited_record.content.overview.careplan">
                        <th>{{ $root.translate("Care Plan") }}</th>
                        <td>
                            {{ edited_record.content.overview.careplan }}
                            <span v-if="setting('care_plans_url')"> (<base-link :href="setting('care_plans_url')" target="_blank">{{ $root.translate("see details") }}</base-link>)</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-else-if="section === 'analytics'">
                <div v-if="!!edited_record.content.analytics">
                    <apexchart
                        :height="160"
                        type="area"
                        :options="chart_options('analytics')"
                        :series="chart_data('analytics')"
                    />

                    <table class="table summary">
                        <tbody>
                            <tr>
                                <th>{{ $root.translate("Website Visits") }}</th>
                                <td>
                                    {{ edited_record.content.analytics.visits | nibnut.number("0,0") }}
                                </td>
                            </tr>
                            <tr>
                                <th>{{ $root.translate("Page Views") }}</th>
                                <td>
                                    {{ edited_record.content.analytics.pageviews | nibnut.number("0,0") }}
                                </td>
                            </tr>
                            <tr>
                                <th>{{ $root.translate("Average Pages / Visit") }}</th>
                                <td>
                                    {{ edited_record.content.analytics.avg_pages_per_visit | nibnut.number("0,0.0") }}
                                </td>
                            </tr>
                            <tr>
                                <th>{{ $root.translate("Bounce Rate") }}</th>
                                <td>
                                    {{ edited_record.content.analytics.bounce_rate | nibnut.number("0,0.0") }}%
                                </td>
                            </tr>
                            <tr>
                                <th>{{ $root.translate("Average Visit Duration") }}</th>
                                <td>
                                    {{ edited_record.content.analytics.avg_visit_duration * 1000 | nibnut.duration }}
                                </td>
                            </tr>
                            <tr>
                                <th>{{ $root.translate("New Visitors") }}</th>
                                <td>
                                    {{ edited_record.content.analytics.new_visitors | nibnut.number("0,0") }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else-if="!!edited_record && !!edited_record.white_label_report" v-html="setting('no_ga_connection_whitelabel')"></div>
                <div v-else v-html="setting('no_ga_connection')"></div>
            </div>
            <table v-else-if="section === 'maintenance'" class="table summary">
                <tbody>
                    <tr>
                        <th>{{ $root.translate("WordPress Version") }}</th>
                        <td>
                            {{ edited_record.content.maintenance.wordpress_version }}
                            <span class="text-small text-success"><open-icon glyph="check" class="hide-sm mr-2" />Updated</span>
                        </td>
                    </tr>
                    <tr>
                        <th>{{ $root.translate("Plugins Updates") }}</th>
                        <td>
                            <span
                                :class="{ 'text-gray': !edited_record.content.maintenance.plugin_updates }"
                            >
                                {{ edited_record.content.maintenance.plugin_updates | nibnut.number("0,0") }}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th>{{ $root.translate("Themes Updated") }}</th>
                        <td>
                            <span
                                :class="{ 'text-gray': !edited_record.content.maintenance.theme_updates }"
                            >
                                {{ edited_record.content.maintenance.theme_updates | nibnut.number("0,0") }}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th>{{ $root.translate("Database Optimizations Performed") }}</th>
                        <td>{{ edited_record.content.maintenance.optimizations | nibnut.number("0,0") }} times</td>
                    </tr>
                    <tr>
                        <th>{{ $root.translate("Backups") }}</th>
                        <td>{{ edited_record.content.maintenance.backups | nibnut.number("0,0") }}</td>
                    </tr>
                    <tr>
                        <th>
                            {{ $root.translate("Maintenance Tasks Performed") }}
                            <ul>
                                <li>Cleanup all post revisions (except for the last)</li>
                                <li>Delete trash posts</li>
                                <li>Delete spam comments</li>
                                <li>Delete trash comments</li>
                                <li>Optimize database tables</li>
                            </ul>
                        </th>
                        <td>{{ edited_record.content.maintenance.optimizations | nibnut.number("0,0") }} times</td>
                    </tr>
                </tbody>
            </table>
            <div v-else-if="section === 'links'">
                <h5
                    v-if="!broken_link_pages.length"
                    class="text-success"
                >
                    <open-icon glyph="check" class="mr-4" /> {{ $root.translate("Congrats! No broken links!") }}
                </h5>
                <div v-else>
                    <p class="text-gray">{{ $root.translate("The following pages on your site contain links that are broken. (point to an inexistant page) It is strongly suggested you fix or remove these links.") }}</p>
                    <dl
                        v-for="url in broken_link_pages"
                        :key="url"
                    >
                        <dt>
                            {{ $root.translate("On the page") }}
                            <base-link
                                :href="`${url}?_wpe-links-report=${edited_record.slug}`"
                                target="_blank"
                            >
                                {{ url }}
                            </base-link>
                            ...
                        </dt>
                        <dd>
                            {{ $root.translate("...we find broken links that point to") }}:
                            <ul>
                                <li
                                    v-for="link in edited_record.content.links[url]"
                                    :key="link.xpath"
                                >
                                    <base-link
                                        :href="link.to"
                                        target="_blank"
                                    >
                                        {{ link.to }}
                                    </base-link>
                                </li>
                            </ul>
                        </dd>
                    </dl>
                </div>
                <div
                    v-if="fixed_link_pages.length"
                    class="text-gray"
                >
                    <p>{{ $root.translate("The broken links on the following pages were fixed or removed.") }}</p>
                    <table class="table text-small">
                        <thead>
                            <tr>
                                <th>{{ $root.translate("Page") }}</th>
                                <th class="text-right">{{ $root.translate("Nb fixes") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="url in fixed_link_pages"
                                :key="url"
                            >
                                <td>{{ url }}</td>
                                <td class="text-right">{{ edited_record.fixes.links[url].length | nibnut.number("0,0") }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-else-if="section === 'images'">
                <h5
                    v-if="!broken_image_pages.length"
                    class="text-success"
                >
                    <open-icon glyph="check" class="mr-4" /> {{ $root.translate("Congrats! No broken or missing images!") }}
                </h5>
                <div v-else>
                    <p class="text-gray">{{ $root.translate("The following pages on your site contain broken or missing images. It is strongly suggested you fix or remove them.") }}</p>
                    <dl
                        v-for="url in broken_image_pages"
                        :key="url"
                    >
                        <dt>
                            {{ $root.translate("On the page") }}
                            <base-link
                                :href="`${url}?_wpe-links-report=${edited_record.slug}`"
                                target="_blank"
                            >
                                {{ url }}
                            </base-link>
                            ...
                        </dt>
                        <dd>
                            {{ $root.translate("...we find broken links that point to") }}:
                            <ul>
                                <li
                                    v-for="link in edited_record.content.images[url]"
                                    :key="link.xpath"
                                >
                                    <base-link
                                        :href="link.to"
                                        target="_blank"
                                    >
                                        {{ link.to }}
                                    </base-link>
                                </li>
                            </ul>
                        </dd>
                    </dl>
                </div>
                <div
                    v-if="fixed_image_pages.length"
                    class="text-gray"
                >
                    <p>{{ $root.translate("The missing images on the following pages were fixed or removed.") }}</p>
                    <table class="table text-small">
                        <thead>
                            <tr>
                                <th>{{ $root.translate("Page") }}</th>
                                <th class="text-right">{{ $root.translate("Nb fixes") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="url in fixed_image_pages"
                                :key="url"
                            >
                                <td>{{ url }}</td>
                                <td class="text-right">{{ edited_record.fixes.images[url].length | nibnut.number("0,0") }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <table v-else-if="section === 'tickets'" class="table">
                <thead v-if="!!edited_record.content.tickets.length">
                    <tr>
                        <th>{{ $root.translate("Date") }}</th>
                        <th>{{ $root.translate("Ticket") }}</th>
                        <th>{{ $root.translate("Status") }}</th>
                    </tr>
                </thead>
                <tbody v-if="!edited_record.content.tickets.length">
                    <tr>
                        <td class="text-gray">
                            {{ $root.translate("No tickets during this period.") }}
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr
                        v-for="ticket in edited_record.content.tickets"
                        :key="ticket.id"
                    >
                        <td>{{ ticket.date }}</td>
                        <td>{{ ticket.title }}</td>
                        <td>{{ ticket.status }}</td>
                    </tr>
                </tbody>
                <tfoot v-if="!!edited_record && !!edited_record.buy_credits_page_url && false">
                    <tr>
                        <td colspan="3" class="text-center">
                            <base-link
                                :href="edited_record.buy_credits_page_url"
                            >
                                {{ translate("Buy Extra Tech Support Credits...") }}
                            </base-link>
                        </td>
                    </tr>
                </tfoot>
            </table>
            <table v-else-if="section === 'tech'" class="table summary">
                <tbody>
                    <tr>
                        <th>{{ $root.translate("MySQL Version") }}</th>
                        <td>
                            {{ edited_record.content.tech.mysql_version }}
                        </td>
                    </tr>
                    <tr>
                        <th>{{ $root.translate("PHP Version") }}</th>
                        <td>
                            {{ edited_record.content.tech.php_version }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="top-of-page-button-container">
            <base-link
                href="#top-of-page"
                class="top-of-page-button"
                @click.prevent.stop="scroll_to_hash('#top-of-page')"
            >
                <open-icon glyph="arrow-circle-up" size="lg" />
            </base-link>
        </div>
    </page>
</template>

<script type="text/javascript">
import { is_public_page } from "@/custom/mixins"

import {
    BaseLink,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"
import VueApexCharts from "vue-apexcharts"

export default {
    name: "ReportView",
    mixins: [is_public_page],
    components: {
        BaseLink,
        DefaultButton,
        OpenIcon,
        Apexchart: VueApexCharts
    },
    methods: {
        section_glyph (section) {
            if(section === "overview") return "info-circle"
            if(section === "analytics") return "chart-bar"
            if(section === "maintenance") return "tools"
            if(section === "links") return "link"
            if(section === "images") return "image"
            if(section === "tickets") return "ticket-alt"
            if(section === "tech") return "clipboard-list"
            return ""
        },
        section_title (section) {
            if(section === "overview") return this.$root.translate("Overview")
            if(section === "analytics") return this.$root.translate("Analytics")
            if(section === "maintenance") return this.$root.translate("Maintenance")
            if(section === "links") return this.$root.translate("Broken Links")
            if(section === "images") return this.$root.translate("Broken Images")
            if(section === "tickets") return this.$root.translate("Tickets")
            if(section === "tech") return this.$root.translate("Technical Info")
            return ""
        },
        raw_chart_data (chart_id) {
            const date = this.$moment(this.edited_record.from_date)
            const end_date = this.$moment(this.edited_record.to_date)
            const chart_data = {}
            while(date.isSameOrBefore(end_date, "day")) {
                const category = date.format("MM/DD")
                const index = date.format("YYYYMMDD") // 20210729
                chart_data[category] = this.edited_record.content.analytics.rows[index] ? this.edited_record.content.analytics.rows[index].visits : 0
                date.add(1, "day")
            }
            return chart_data
        },
        chart_options (chart_id) {
            const color = "#00bcb4"
            const categories = Object.keys(this.raw_chart_data(chart_id))
            return {
                chart: {
                    id: chart_id,
                    toolbar: {
                        show: !this.small_screen
                    }
                },
                title: {
                    text: this.$root.translate("Website Visits"),
                    offsetX: 0
                },
                fill: {
                    opacity: 0.3
                },
                colors: [color],
                dataLabels: {
                    enabled: false
                },
                yaxis: {
                    show: true,
                    tickAmount: 2,
                    /*
                    title: {
                        text: this.$root.translate("Visits")
                    },
                    */
                    labels: {
                        formatter: (value) => {
                            return this.$numeral(value).format("0,0.0").replace(/\.0/, "")
                        }
                    }
                },
                xaxis: {
                    tickAmount: this.small_screen ? 8 : undefined,
                    categories
                },
                tooltips: {
                    y: {
                        formatter: (value) => {
                            return this.$numeral(value).format("0,0.0").replace(/\.0/, "")
                        }
                    }
                }
            }
        },
        chart_data (chart_id) {
            const name = this.$root.translate("Visits")
            const data = Object.values(this.raw_chart_data(chart_id))
            return [
                {
                    name,
                    data
                }
            ]
        },
        print () {
            window.print()
        },
        scroll_to_hash (hash) {
            // Make sure to set the target's class to nibnut-scroll-target so the scroll is padded for the site's header's height
            this.scroll_to(document.getElementById(hash.replace(/#/, "")))
        }
    },
    computed: {
        title () {
            return this.$root.translate("WordPress maintenance report")
        },
        sections () {
            const edited_record = this.edited_record
            if(!!edited_record && !!edited_record.content) return Object.keys(edited_record.content)
            return []
        },
        fixed_link_pages () {
            const edited_record = this.edited_record
            if(!!edited_record && !!edited_record.fixes && !!edited_record.fixes.links) return Object.keys(edited_record.fixes.links)
            return []
        },
        broken_link_pages () {
            const edited_record = this.edited_record
            if(!!edited_record && !!edited_record.content && !!edited_record.content.links) return Object.keys(edited_record.content.links)
            return []
        },
        fixed_image_pages () {
            const edited_record = this.edited_record
            if(!!edited_record && !!edited_record.fixes && !!edited_record.fixes.images) return Object.keys(edited_record.fixes.images)
            return []
        },
        broken_image_pages () {
            const edited_record = this.edited_record
            if(!!edited_record && !!edited_record.content && !!edited_record.content.images) return Object.keys(edited_record.content.images)
            return []
        }
    },
    data () {
        return {
            entity: "site_report",
            entityName: "Report:::Reports"
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#report-view {
    .toc {
        display: inline-block;
        list-style: none;
        margin: $unit-1 0;
        padding: $unit-1 0;
        flex: 1 0 auto;

        .toc-item {
            display: inline-block;
            margin: 0;
            padding: $unit-1 0;

            &:not(:last-child) {
                margin-right: $unit-1;
            }

            &:not(:first-child) {
                &::before {
                    color: $gray-color-dark;
                    content: "|";
                    padding-right: $unit-2;
                }
            }
        }
        @media (max-width: $size-sm) {
            display: block;
            overflow-x: auto;
            padding-bottom: .75rem;
            text-align: center;
            flex: 1 1 auto;
        }
    }
    .top-of-page-button-container {
        position: absolute;
        top: 100vh;
        right: -3em;
        bottom: -5em;
        width: 3em;
        pointer-events: none;
        @media (max-width: $size-sm) {
            right: -1em;
        }

        .top-of-page-button {
            position: fixed;
            position: sticky;
            pointer-events: all;
            top: calc(100vh - 5rem);
            display: inline-block;
            text-decoration: none;
            font-size: 2rem;
            line-height: 2.2rem;
            text-align: center;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            color: $primary-color;
            background-color: $secondary-color;
        }
    }
}
</style>
