<template>
    <data-table
        id="client-invoices"
        :columns="visible_columns"
        :rows="filtered_rows"
        :searchable="false"
        :total="state.total"
        :found="state.found"
        :current-page="state.page"
        :total-pages="nb_pages"
        :can-add="false"
        :show-totals="false"
        @sort="sort_by"
        @search="search"
        @clear-search="clear_search"
        @page="goto_page"
        @click="record_edit($event)"
    >
        <template
            v-slot:summary="{ row }"
        >
            <div :class="{ 'text-error': invoice_is_past_due(row) }">
                <h6>{{ row.TxnDate | nibnut.date }} - {{ invoice_amount(row) | nibnut.currency("0,0.00") }}</h6>
                <div
                    class="text-gray"
                >
                    <span v-if="!!invoice_balance(row)">{{ $root.translate("Balance") }}: {{ invoice_balance(row) | nibnut.currency("0,0.00") }}</span>
                    <span v-else class="text-success">
                        <open-icon glyph="check-circle" class="ml-2" />
                        {{ $root.translate("Paid") }}
                    </span>
                    <span v-if="!!invoice_balance(row)">{{ $root.translate("Due") }}: {{ row.DueDate | nibnut.date }}</span>
                </div>
            </div>
        </template>
        <template
            v-slot:tbody="{ row, field }"
        >
            <span
                v-if="field.match(/^(TxnDate|DueDate)$/)"
                :class="{ 'text-error': invoice_is_past_due(row) }"
            >
                {{ row[field] | nibnut.date }}
            </span>
            <span
                v-else-if="field === 'HomeTotalAmt'"
                :class="{ 'text-error': invoice_is_past_due(row) }"
            >
                {{ invoice_amount(row) | nibnut.currency("0,0.00") }}
            </span>
            <span
                v-else-if="field === 'HomeBalance'"
                :class="{ 'text-error': invoice_is_past_due(row) }"
            >
                <span v-if="!!invoice_balance(row)">{{ invoice_balance(row) | nibnut.currency("0,0.00") }}</span>
                <span v-else class="text-success">
                    <open-icon glyph="check-circle" class="ml-2" />
                    {{ $root.translate("Paid") }}
                </span>
            </span>
            <!--
            <span
                v-else-if="field === 'EmailStatus'"
                :class="{ 'text-error': invoice_is_past_due(row) }"
            >
                <span v-if="row.EmailStatus === 'EmailSent'">{{ $root.translate("Sent") }}</span>
                <span v-else-if="row.EmailStatus === 'NeedToSend'">{{ $root.translate("Need to Send") }}</span>
                <span v-else>{{ $root.translate("Not Sent") }}</span>
            </span>
            //-->
            <span
                v-else
                :class="{ 'text-error': invoice_is_past_due(row) }"
            >
                {{ row[field] }}
            </span>
        </template>
    </data-table>
</template>

<script>
import { is_remote_data_table_source } from "@/nibnut/mixins"

import { OpenIcon } from "@/nibnut/components"

export default {
    name: "Invoices",
    mixins: [is_remote_data_table_source],
    components: {
        OpenIcon
    },
    mounted () {
        this.reroot()
    },
    watch: {
        id: "reroot"
    },
    methods: {
        pre_load () {
            this.set_state_values({
                total: 0,
                found: 0
            })
            this.set_state_value("qbo_id", this.id || 0)
        },
        reroot () {
            this.refresh()
        },
        quickbooks_url (path, search = null) {
            if(!this.app_context) return ""
            let url = `${this.app_context.settings.qbo_url}/${path}`
            if(search) url += `?${search}`
            return url
        },
        record_edit (record) {
            window.open(this.quickbooks_url("app/invoice", `txnId=${record.id}`))
        },
        invoice_amount (row) {
            return row.HomeTotalAmt || row.TotalAmt
        },
        invoice_balance (row) {
            return row.HomeBalance || row.Balance
        },
        invoice_is_past_due (row) {
            const date = this.$moment(row.DueDate)
            return (this.invoice_balance(row) > 0) && !!date && !!date.isBefore()
        }
    },
    computed: {
        can_refresh () {
            return !!this.profile_id && !!this.id
        },
        state_identifier () {
            return "client-invoices"
        },
        fields () {
            return [
                ...Object.keys(this.columns),
                "Id",
                "CustomerRef",
                "TotalAmt",
                "Balance"
            ]
        }
    },
    props: {
        id: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            entity: "invoice",
            columns: {
                DocNumber: { label: "#", sort: null, type: "numeric" },
                TxnDate: { label: "Date", sort: null, type: "amount" },
                DueDate: { label: "Due", sort: "asc", type: "amount" },
                HomeTotalAmt: { label: "Total", sort: null, type: "numeric" },
                HomeBalance: { label: "Balance", sort: null, type: "numeric" }
                // EmailStatus: { label: "Email Status", sort: null, type: "alpha" }
            },
            default_state: {
                per_page: 5,
                page: 1,
                sort_by: "DueDate",
                sort_dir: "desc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                qbo_id: 0
            }
        }
    }
}
</script>
