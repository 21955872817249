<template>
    <page
        id="site-credits"
        :title="title"
        :status="page_status"
        :waiting="waiting"
        class="public-page-content"
        @statused="statused"
    >
        <template v-slot:title>
            <h3 id="top-of-page">{{ title }}</h3>
            <h1 v-if="!!edited_record && !!edited_record.name">{{ edited_record.name }}</h1>
        </template>
        <!--
            Info Table: number of credits used / credits available ; show number of credits to carry over to the next month (unused evergreen)

            Order form to purchase extra credits
        //-->
        <site-credits
            :site="edited_record || {}"
        />
        <default-button
            v-if="!purchasing"
            color="primary"
            :block="true"
            class="mt-8"
            @click.prevent.stop="purchasing = true"
        >
            {{ $root.translate("Buy Additional Credits") }}
        </default-button>

        <modal-dialog
            id="payment-dialog"
            :show.sync="purchasing"
            class="payment-dialog"
        >
            <template v-slot:title>
                <span class="h5">{{ translate("Buy Additional Credits") }}</span>
            </template>
            <div id="checkout"></div>
        </modal-dialog>
    </page>
</template>

<script type="text/javascript">
import { is_public_page } from "@/custom/mixins"

import { ModalDialog, DefaultButton } from "@/nibnut/components"
import { SiteCredits } from "@/custom/components"

export default {
    name: "Credits",
    mixins: [is_public_page],
    components: {
        ModalDialog,
        DefaultButton,
        SiteCredits
    },
    watch: {
        purchasing: "reload_stripe"
    },
    methods: {
        post_load () {
            if(!this.STRIPE) this.STRIPE = window.Stripe(this.setting("stripe_key"))
        },
        reload_stripe () {
            if(this.purchasing) {
                setTimeout(() => {
                    const edited_record = this.edited_record
                    if(edited_record && edited_record.stripe_checkout_session) {
                        this.STRIPE.initEmbeddedCheckout({
                            fetchClientSecret: () => new Promise(resolve => resolve(edited_record.stripe_checkout_session))
                        }).then(checkout => {
                            this.STRIPE_CHECKOUT = checkout
                            this.STRIPE_CHECKOUT.mount("#checkout")
                        }).catch(error => {
                            console.log(error)
                        })
                    }
                }, 200)
            } else if(this.STRIPE_CHECKOUT) {
                this.STRIPE_CHECKOUT.destroy()
                this.STRIPE_CHECKOUT = null
            }
        }
    },
    computed: {
        title () {
            return this.$root.translate("Site Credits")
        },
        reload_query () {
            const reload_query = { relation_ids: this.relation_ids, fields: ["fieldset::credits"] }
            if(!!this.$route.query && !!this.$route.query.session_id) reload_query.stripe_session_id = this.$route.query.session_id
            return reload_query
        }
    },
    data () {
        return {
            entity: "site",
            entityName: "Credit:::Credits",
            purchasing: false
        }
    }
}
</script>

<style lang="scss">
#site-credits {
    .modal.payment-dialog {
        & > .modal-container {
            max-height: 95vh;
            max-width: 450px;
            & > .modal-header {
                height: 0;
                & > .modal-title {
                    display: none;
                }
            }
            & > .modal-body {
                padding: 0;
            }
        }
    }
}
</style>
