<template>
    <page
        id="tickets-list"
        :title="page_title"
        :status="page_status"
        :waiting="waiting || loading"
        @statused="statused"
    >
        <data-table
            id="tickets-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="false"
            @sort="sort_by"
            @search="search"
            @page="goto_page"
            @click="record_edit"
        >
            <template
                v-slot:controls
            >
                <div v-if="!ticket_folder_by_id(state.folder_id).userId">
                    <form-select
                        id="user_id"
                        name="user_id"
                        v-model="state.filter"
                        data-source="user"
                        :empty-value="0"
                        :empty-label="translate('Assigned to Anyone')"
                        :required="false"
                        :show-all="true"
                        @input="filter('user_id', $event)"
                    />
                </div>
            </template>
            <template
                v-slot:summary="{ row }"
            >
                <h6>
                    {{ row.subject }}
                    <span class="text-gray">#{{ row.number }}</span>
                </h6>
                <p v-if="!!row.preview" class="text-gray text-small" v-html="row.preview"></p>
                <p>{{ translate("Assigned To") }}: {{ row.assignee_name }}</p>
                <p>{{ translate("Waiting Since") }}: {{ row.waiting_since | nibnut.date_from_now }}</p>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <span
                    v-if="field === 'computed_contact_name'"
                    :class="{ 'text-bold': row_is_active(row), 'text-italic': !row.contact_id }"
                >
                    {{ row.computed_contact_name }}
                </span>
                <div
                    v-else-if="field === 'subject'"
                    :class="{ 'text-bold': row_is_active(row) }"
                >
                    {{ row.subject }}
                    <p v-if="!!row.preview" class="text-gray text-small" v-html="row.preview"></p>
                </div>
                <span
                    v-else-if="field === 'waiting_since'"
                    :class="{ 'text-bold': row_is_active(row) }"
                >
                    {{ row.waiting_since | nibnut.date_from_now }}
                </span>
                <span
                    v-else
                    :class="{ 'text-bold': row_is_active(row) }"
                >{{ row[field] }}</span>
            </template>
        </data-table>
    </page>
</template>

<script type="text/javascript">
import { is_page, is_remote_data_table_source } from "@/nibnut/mixins"
import { handles_freescout_folders } from "@/custom/mixins"

import {
    FormSelect
} from "@/nibnut/components"

export default {
    name: "TicketList",
    mixins: [is_page, is_remote_data_table_source, handles_freescout_folders],
    components: {
        FormSelect
    },
    methods: {
        pre_load () {
            this.set_state_value("folder_id", parseInt(this.$route.params.folder))
        },
        reset () {
            this.reset_state(true)
            this.refresh()
        },
        record_edit (record) {
            this.$router.push({ name: `${this.entity}.edit`, params: { folder: this.state.folder_id, id: record.id } })
        },
        row_is_active (row) {
            return !!row && !!row.status && (row.status === this.constants("ticket_statii", "FREESCOUT_STATE_ACTIVE").id)
        }
    },
    computed: {
        is_editing () {
            return !!this.$route.params.id
        },
        page_title () {
            const folder = this.ticket_folder_by_id(this.state.folder_id)
            if(folder.name) return this.translate("Ticket - {name}:::Tickets - {name}", { name: folder.name }, 2)
            return this.translate("Ticket:::Tickets", {}, 2)
        },
        visible_columns () {
            const folder = this.ticket_folder_by_id(this.state.folder_id)
            if(folder.userId) {
                const { assignee_name, ...columns } = this.columns
                return columns
            }
            return this.columns
        },
        fields () {
            return ["fieldset::list"]
        }
    },
    data () {
        return {
            entity: "ticket",
            // relation_ids: ["monitor", "plan"],
            columns: {
                computed_contact_name: { label: "Customer", sort: null, type: "alpha" },
                subject: { label: "Conversation", sort: null, type: "alpha" },
                assignee_name: { label: "Assigned To", sort: null, type: "alpha" },
                number: { label: "Number", sort: null, type: "numeric" },
                waiting_since: { label: "Waiting Since", sort: "desc", type: "numeric" }
            },
            default_state: {
                per_page: 25,
                page: 1,
                sort_by: "waiting_since",
                sort_dir: "desc",
                filter_on: "user_id",
                filter: 0,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                folder_id: 0
            }
        }
    }
}
</script>

<style lang="scss">
#tickets-list {
    td {
        vertical-align: top;
    }
}
</style>
