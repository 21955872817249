<template>
    <modal-dialog
        v-if="!!record"
        id="remote-client-picker"
        :show.sync="shown"
    >
        <template v-slot:title>
            <span class="h5">
                {{ $root.translate("Link {name} to {service} {service_entity_name}", { name: record.computed_name || record.name, service: service_name, service_entity_name }) }}
            </span>
        </template>

        <form-input
            id="query"
            name="query"
            v-model="query"
            :live="true"
            :required="false"
            :saving="querying"
        >
            <template v-slot:hint>{{ hint }}</template>
            <template v-slot:right_addon>
                <default-button
                    color="primary"
                    :disabled="query.length < 3"
                    loader-size="xs"
                    :waiting="querying"
                    @click.prevent="execute_query"
                >
                    <open-icon glyph="search" />
                </default-button>
            </template>
        </form-input>

        <table
            class="table"
        >
            <tbody>
                <tr
                    v-for="result in results"
                    :key="result.id"
                    @click.prevent="match = result.id"
                    :class="{'active': match === result.id}"
                >
                    <td>
                        <open-icon
                            :glyph="(match === result.id) ? 'check-circle' : 'circle'"
                            class="ml-2"
                        />
                        {{ result.name }}<span v-if="result.addl_info" class="text-muted ml-2">({{ result.addl_info }})</span>
                    </td>
                </tr>
                <tr v-if="canCreate">
                    <td
                        @click.prevent="match = fake_id"
                        :class="{'active': match === fake_id}"
                    >
                        <open-icon
                            :glyph="(match === fake_id) ? 'check-circle' : 'circle'"
                            class="ml-2"
                        />
                        {{ $root.translate("Create New {service_entity_name} on {service}", { service_entity_name, service: service_name }) }}
                    </td>
                </tr>
                <tr v-else-if="!results.length">
                    <td>
                        {{ $root.translate("No {service_entity_name} Found on {service}", { service_entity_name, service: service_name }) }}
                    </td>
                </tr>
            </tbody>
        </table>

        <template v-slot:footer>
            <div class="text-center">
                <default-button
                    :disabled="linking"
                    class="mr-2"
                    @click.prevent="shown = false"
                >
                    {{ $root.translate("Cancel") }}
                </default-button>
                <default-button
                    :disabled="!linkable || linking"
                    :waiting="linking"
                    color="primary"
                    class="ml-2"
                    @click.prevent="link_customer"
                >
                    {{ $root.translate("Link") }}
                </default-button>
            </div>
        </template>
    </modal-dialog>
</template>

<script>
import { string_utilities, misc_utilities } from "@/nibnut/mixins"

import {
    FormInput,
    DefaultButton,
    OpenIcon,
    ModalDialog
} from "../../nibnut/components"

export default {
    name: "ConnectDialog",
    mixins: [string_utilities, misc_utilities],
    components: {
        FormInput,
        DefaultButton,
        OpenIcon,
        ModalDialog
    },
    mounted () {
        this.reset()
    },
    watch: {
        "record.id": "reset"
    },
    methods: {
        reset () {
            this.match = this.fake_id
            this.results = []
            this.query = this.default_query
            this.execute_query()
        },
        execute_query () {
            if(!this.query || (this.query.length < 3)) return
            this.match = null
            this.querying = true
            this.$store.dispatch(
                "REMOTE_ENTITY_QUERY",
                {
                    entity: this.service_entity,
                    service: this.service,
                    query: this.query
                }
            ).then(response => {
                this.results = response
                if(this.results.length === 1) this.match = this.results[0].id
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.querying = false
            })
        },
        link_customer () {
            if(this.record) {
                const match = this.standardized_match
                if(!match) this.shown = false
                else if(!this.record.id) {
                    this.record[this.id_field] = match
                    this.shown = false
                } else {
                    this.linking = true
                    this.$store.dispatch("RECORD_SAVE", {
                        entity: this.entity,
                        id: this.record.id,
                        data: {
                            [this.id_field]: match,
                            relation_ids: this.relationIds
                        }
                    }).then(record => {
                        this.$emit("connected", record[this.id_field])
                        this.shown = false
                    }).catch(error => {
                        this.$error(error.message)
                    }).then(() => {
                        this.linking = false
                    })
                }
            }
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        is_freescout () {
            return (this.service === "freescout")
        },
        is_contacts_plus () {
            return (this.service === "contacts-plus")
        },
        service_name () {
            if(this.is_freescout) return "Freescout"
            if(this.is_contacts_plus) return "Contacts+"
            return "Quickbooks"
        },
        service_entity () {
            if(this.is_freescout && (this.entity === "user")) return "user"
            return "client"
        },
        service_entity_name () {
            if(this.is_freescout && (this.entity === "user")) return "User"
            return "Customer"
        },
        fake_id () {
            if(this.is_freescout) return this.constants("FAKE_FREESCOUT_ID")
            if(this.is_contacts_plus) return this.constants("FAKE_CONTACTS_PLUS_ID")
            return this.constants("FAKE_QBO_ID")
        },
        id_field () {
            if(this.is_freescout) return "freescout_id"
            if(this.is_contacts_plus) return "contacts_plus_id"
            return "quickbooks_id"
        },
        hint () {
            if(this.is_freescout && (this.entity === "user")) return this.$root.translate("Type a user's email address")
            if(this.is_freescout) return this.$root.translate("Type a customer's full last name")
            if(this.is_contacts_plus) return this.$root.translate("Type at least 3 characters of a contact's name or email")
            return this.$root.translate("Type at least 3 characters of a customer's name or email")
        },
        default_query () {
            if(!this.record) return ""
            if(this.is_freescout && (this.entity === "user")) return this.record.email
            if(this.is_freescout) return this.record.last_name
            return this.record.email || this.full_name(this.record)
        },
        standardized_match () {
            if(this.is_contacts_plus) return this.match || null
            return parseInt(this.match) || 0
        },
        linkable () {
            return this.canCreate || !!this.results.length
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        entity: {
            type: String,
            default: "contact"
        },
        record: {
            type: Object,
            required: true
        },
        service: {
            type: String,
            // validator: prop => !!prop && !!prop.match(/^(qbo|freescout|contacts-plus)$/),
            required: true
        },
        relationIds: {
            type: Array,
            default () {
                return []
            }
        },
        canCreate: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            query: "",
            results: [],
            querying: false,
            match: null,
            linking: false
        }
    }
}
</script>
