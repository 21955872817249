<template>
    <form-group
        v-bind="form_group_props"
    >
        <template v-slot:read_only><slot name="read_only"></slot></template>
        <template v-slot:label>
            <slot name="label">
                {{ $root.translate('Invitation expires in') }}
            </slot>
        </template>
        <template v-slot:hint><slot name="hint"></slot></template>
        <div class="btn-group btn-group-block">
            <base-button
                v-for="time in expiry_times"
                :key="time.value"
                :disabled="disabled"
                :active="(value===time.value)"
                @click.prevent="clicked(time.value)"
            >
                {{ time.label }}
            </base-button>
        </div>
    </form-group>
</template>

<script>
import is_nibnut_component from "../../mixins/IsNibnutComponent"

import FormGroup from "./FormGroup"
import BaseButton from "../Buttons/BaseButton"

export default {
    name: "InvitationExpiries",
    mixins: [is_nibnut_component],
    components: {
        FormGroup,
        BaseButton
    },
    methods: {
        clicked (value) {
            this.$emit("input", value, this.name)
        }
    },
    computed: {
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        },
        expiry_times () {
            return [
                { value: 3 * 60, label: this.$root.translate("3h") },
                { value: 6 * 60, label: this.$root.translate("6h") },
                { value: 12 * 60, label: this.$root.translate("12h") },
                { value: 18 * 60, label: this.$root.translate("18h") },
                { value: 24 * 60, label: this.$root.translate("24h") }
            ]
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            type: Number,
            required: true
        },
        required: {
            type: Boolean,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    }
}
</script>
