<template>
    <expandable v-if="!!state.total" :borderless="true" class="client-tickets">
        <template v-slot:title>
            <h5>
                {{ $root.translate("Tickets") }} ({{ state.total | nibnut.number("0,0") }})
            </h5>
        </template>
        <data-table
            id="client-tickets"
            :columns="visible_columns"
            :rows="filtered_rows"
            :searchable="false"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="false"
            @sort="sort_by"
            @search="search"
            @clear-search="clear_search"
            @page="goto_page"
            @click="edit_record"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6>
                    <figure
                        class="avatar mr-2"
                        :data-initial="row_initials(row)"
                    >
                        <img
                            v-if="row_avatar(row)"
                            :src="row_avatar(row)"
                            :alt="$root.translate('Assigned to {name}', { name: row.assignee_name })"
                        />
                    </figure>
                    {{ row.subject }}
                </h6>
                <div v-if="!!row.preview" class="text-gray text-small">{{ row.preview }}</div>
                <div class="text-small">{{ row.last_interaction_at | nibnut.date }} @ {{ row.last_interaction_at | nibnut.date("HH:mm") }}</div>
                <div>{{ row.status }}</div>
                <base-link
                    :id="`ticket-${row.id}`"
                    :href="row_url(row)"
                    target="freescout"
                    class="d-none"
                    @click.native.stop
                >Go</base-link>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <span v-if="field === 'assignee_name'">
                    <figure
                        class="avatar avatar-sm"
                        :data-initial="row_initials(row)"
                    >
                        <img
                            v-if="row_avatar(row)"
                            :src="row_avatar(row)"
                            :alt="$root.translate('Assigned to {name}', { name: row.assignee_name })"
                        />
                    </figure>
                </span>
                <div v-else-if="field === 'subject'">
                    {{ row.subject }}
                    <div v-if="!!row.preview" class="text-small text-gray">
                        {{ row.preview }}
                    </div>
                    <base-link
                        :id="`ticket-${row.id}`"
                        :href="row_url(row)"
                        target="freescout"
                        class="d-none"
                        @click.native.stop
                    >Go</base-link>
                </div>
                <span v-else-if="field === 'last_interaction_at'" class="text-nowrap text-small">
                    {{ row.last_interaction_at | nibnut.date("YY-MM-DD") }} @ {{ row.last_interaction_at | nibnut.date("HH:mm") }}
                </span>
                <span v-else>
                    {{ row[field] }}
                </span>
            </template>
        </data-table>
    </expandable>
</template>

<script>
import { mapGetters } from "vuex"

import { is_data_table_source } from "@/nibnut/mixins"

import { BaseLink } from "@/nibnut/components"
import { Expandable } from "@/custom/components"

export default {
    name: "Tickets",
    mixins: [is_data_table_source],
    components: {
        BaseLink,
        Expandable
    },
    watch: {
        dataVersion: "reset"
    },
    methods: {
        row_initials (row) {
            if(row.assignee_name && !row.assignee_avatar) {
                const matches = row.assignee_name.match(/^(.)[^\s]*(?:\s+(.))?/)
                if(matches) return `${matches[1] || ""}${matches[2] || ""}`
            }
            return "NA"
        },
        row_avatar (row) {
            if(row.assignee_avatar) return row.assignee_avatar
            return null
        },
        freescout_url (path) {
            if(!this.app_context) return ""
            return `https://${this.app_context.settings.freescout_domain}/${path}`
        },
        row_url (row) {
            return this.freescout_url(`conversation/${row.freescout_id}`)
        },
        edit_record (row) {
            const button = document.getElementById(`ticket-${row.id}`)
            if(button) button.click()
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        state_identifier () {
            return "client-tickets"
        },
        rows () {
            return this.entity_records("ticket").filter(ticket => ticket.computed_client_id === this.clientId)
        }
    },
    props: {
        clientId: {
            type: Number,
            default: 0
        },
        dataVersion: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            columns: {
                assignee_name: { label: " ", sort: false },
                subject: { label: "Ticket", sort: null, type: "alpha" },
                last_interaction_at: { label: "Date", sort: "desc" },
                status: { label: "Status", sort: null, type: "alpha" },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 5,
                page: 1,
                sort_by: "last_interaction_at",
                sort_dir: "desc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            }
        }
    }
}
</script>

<style lang="scss">
.client-tickets {
    .freescout-link {
        height: 2rem;
    }
}
</style>
