<template>
    <img :src="logo" :alt="alt" class="img-responsive app-logo" />
</template>

<script>
import { mapState } from "vuex"

import logo from "../../assets/img/logo.svg"

export default {
    name: "AppLogo",
    computed: {
        ...mapState(["app_context"]),
        logo () {
            return logo
        },
        alt () {
            if(this.app_context.settings && this.app_context.settings) return this.app_context.settings.application_name
            return ""
        }
    }
}
</script>
