<template>
    <div>
        <span
            v-if="!monitor || !monitor.active"
            class="text-gray"
        >
            <span v-if="compact">({{ $root.translate("n/a") }})</span>
            <span v-else>({{ $root.translate("Not monitored") }})</span>
        </span>
        <div v-else-if="!computed_checked_at">
            <span class="text-gray">
                <span v-if="compact">{{ $root.translate("Soon") }}</span>
                <span v-else>{{ $root.translate("First report coming soon...") }}</span>
            </span>
            <div class="text-gray text-small">
                <span v-if="compact">{{ $root.translate("Soon") }}</span>
                <span v-else>{{ $root.translate("Scheduled") }}:
                <span v-if="!!monitor.next_check_at_as_string && $moment(monitor.next_check_at_as_string).isAfter()">
                    {{ monitor.next_check_at_as_string | nibnut.date("MM-DD") }} @ {{ monitor.next_check_at_as_string | nibnut.date("HH:mm") }}
                </span>
                <span v-else>
                    {{ $root.translate("Any moment now...") }}
                </span>
                </span>
                <default-button
                    :title="$root.translate('Re-run')"
                    flavor="link"
                    size="sm"
                    @click.prevent.stop="regenerate"
                >
                    <open-icon glyph="sync" :spin="reloading" />
                </default-button>
            </div>
        </div>
        <div v-else>
            <span v-if="!monitor.computed_error_count && !monitor.computed_warning_count && !monitor.summary" class="text-success">
                <open-icon glyph="check-circle" /><span v-if="!compact"> {{ $root.translate("All good!") }}</span>
            </span>
            <span
                v-else-if="!!monitor.computed_error_count || !!monitor.summary"
                :data-tooltip="small_screen ? false : errors_tooltips('errors')"
                :class="{ tooltip: !small_screen && !!errors_tooltips('errors') }"
                class="text-error"
            >
                <open-icon glyph="times-circle" />
                <span v-if="!compact && !!monitor.computed_error_count">  {{ $root.translate("{number} error:::{number} errors", { number: monitor.computed_error_count }, monitor.computed_error_count) }}</span>
                <span v-else-if="!compact">  {{ monitor.summary }}</span>
            </span>
            <span
                v-else-if="!!monitor.computed_warning_count"
                :data-tooltip="small_screen ? false : errors_tooltips('warnings')"
                :class="{ tooltip: !small_screen && !!errors_tooltips('warnings') }"
                class="text-warning"
            >
                <open-icon glyph="exclamation-circle" /><span v-if="!compact">  {{ $root.translate("{number} warning:::{number} warnings", { number: monitor.computed_warning_count }, monitor.computed_warning_count) }}</span>
            </span>
            <div
                :class="{ 'd-inline': compact }"
                class="text-gray text-small"
            >
                {{ computed_checked_at | nibnut.date("MM-DD") }} @ {{ computed_checked_at | nibnut.date("HH:mm") }}
                <default-button
                    :title="$root.translate('Re-run')"
                    flavor="link"
                    size="sm"
                    @click.prevent.stop="regenerate"
                >
                    <open-icon glyph="sync" :spin="reloading" />
                </default-button>
            </div>
        </div>
    </div>
</template>

<script>
import ui_utilities from "@/nibnut/mixins/UiUtilities"
import misc_utilities from "@/nibnut/mixins/MiscUtilities"

import { DefaultButton, OpenIcon } from "@/nibnut/components"

export default {
    mixins: [ui_utilities, misc_utilities],
    components: {
        DefaultButton,
        OpenIcon
    },
    methods: {
        errors_tooltips (type) {
            if(!this.monitor || (this.monitor.type === this.constants("monitor_types", "TYPE_LINKS").id)) return null
            if(!this.monitor[type] || !this.monitor[type].length) {
                this.$store.dispatch("FETCH_RECORD", {
                    entity: "monitor",
                    id: this.monitor.id,
                    query: { fields: ["errors", "warnings"] }
                })
                return this.$root.translate("Loading...")
            }
            return this.monitor[type].join("\n")
        },
        regenerate () {
            this.reloading = true
            this.$store.dispatch(
                "RECORD_ACTION",
                {
                    entity: "monitor",
                    id: this.monitor.id,
                    action: "refresh",
                    method: "put"
                }
            ).then(() => {
                this.$success(this.$root.translate("Monitoring requested: you will be notified by email when the report is ready."))
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.reloading = false
            })
        }
    },
    computed: {
        computed_checked_at () {
            if(!this.monitor) return ""
            return this.monitor.computed_checked_at || this.monitor.computed_checked_at_as_string
        }
    },
    props: {
        monitor: {
            type: Object,
            required: true
        },
        compact: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            reloading: false
        }
    }
}
</script>
