<template>
    <div id="alerts-dialog">
        <modal-dialog
            id="alerts-dialog"
            :show.sync="shown"
            class="alerts-dialog"
        >
            <template v-slot:title>
                <span class="h5">{{ translate("Alerts") }}</span>
            </template>
            <alerts-list
                :topic-type="topicType"
                :topic-id="topicId"
            />
        </modal-dialog>

    </div>
</template>

<script>
import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import AlertsList from "@/custom/components/AlertsList"

export default {
    name: "AlertsDialog",
    components: {
        ModalDialog,
        AlertsList
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                if(!show) {
                    this.record_id = null
                    this.editing = false
                }
                this.$emit("update:show", show)
            }
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        topicType: {
            type: String,
            default: ""
        },
        topicId: {
            type: Number,
            default: 0
        }
    }
}
</script>
