<template>
    <div
        v-if="show"
        :class="{ active: show }"
        class="modal"
    >
        <dismiss-link
            @click="maybe_dismiss"
            class="modal-overlay"
        />
        <div
            role="dialog"
            aria-labelledby="`modal-header-${id}`"
            aria-modal="true"
            class="modal-container"
        >
            <div
                tabindex="0"
                @focus="loop_to_last"
            ></div>
            <div
                v-if="has_slot('title')||has_slot('subtitle')"
                class="modal-header"
            >
                <dismiss-link
                    v-if="dismissable"
                    id="first_focusable"
                    @click="dismiss"
                    class="btn btn-clear float-right"
                />
                <div v-if="has_slot('title')" class="modal-title">
                    <slot name="title"></slot>
                </div>
                <slot name="subtitle"></slot>
            </div>
            <div class="modal-body">
                <slot></slot>
            </div>
            <div
                v-if="has_slot('footer')"
                class="modal-footer"
            >
                <slot name="footer"></slot>
            </div>
            <div
                tabindex="0"
                @focus="loop_to_first"
            ></div>
        </div>
    </div>
</template>

<script>
import is_nibnut_component from "../../mixins/IsNibnutComponent"

import DismissLink from "./DismissLink"

export default {
    name: "ModalDialog",
    mixins: [is_nibnut_component],
    components: {
        DismissLink
    },
    created () {
        this.toggle_event_listener()
    },
    beforeDestroy () {
        if(this.show) this.dismiss()
    },
    watch: {
        show: "toggle_event_listener"
    },
    methods: {
        toggle_event_listener () {
            const escaped = event => {
                if((event.keyCode === 27) && this.show && this.dismissable) this.dismiss()
            }
            if(this.show) {
                document.addEventListener("keydown", escaped)
            } else {
                document.removeEventListener("keydown", escaped)
            }
        },
        loop_to_last () {
            const target = document.getElementById("last_focusable")
            if(!target) console.error("Unknown last focusable")
            else this.$nextTick(() => target.focus())
        },
        loop_to_first () {
            const target = document.getElementById("first_focusable")
            if(!target) console.error("Unknown first focusable")
            else this.$nextTick(() => target.focus())
        },
        dismiss () {
            this.$emit("update:show", false)
        },
        maybe_dismiss () {
            if(this.dismissable) this.dismiss()
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        show: {
            type: Boolean,
            default: false
        },
        dismissable: {
            type: Boolean,
            default: true
        }
    }
}
</script>
