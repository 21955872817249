<template>
    <div v-if="!!edited_record">
        <plans />
    </div>
</template>

<script>
import is_settings_panel from "./IsSettingsPanel"

import Plans from "./Plans"

export default {
    name: "Contracts",
    mixins: [is_settings_panel],
    components: {
        Plans
    }
}
</script>
