<template>
    <div
        :id="id"
        :class="{ [`nibnut-editor-${size}`]: true }"
        class="nibnut-editor"
    >
        <div v-if="!!editor">
            <editor-menu-bar
                :editor="editor"
            >
                <div
                    slot-scope="{ commands, isActive, getMarkAttrs }"
                    class="nibnut-editor-toolbar"
                >
                    <button
                        v-for="level in headers"
                        :key = level
                        type="button"
                        :class="{ 'btn-primary': isActive.heading({ level }), 'btn-link': !isActive.heading({ level }) }"
                        @click="commands.heading({ level })"
                        class="btn btn-sm"
                    >
                        {{ `H${level}` }}
                    </button>

                    <button
                        v-if="styles"
                        type="button"
                        :class="{ 'btn-primary': isActive.bold(), 'btn-link': !isActive.bold() }"
                        @click="commands.bold"
                        class="btn btn-sm"
                    >
                        <open-icon glyph="bold" :title="$root.translate('Bold')" />
                    </button>
                    <button
                        v-if="styles"
                        type="button"
                        :class="{ 'btn-primary': isActive.italic(), 'btn-link': !isActive.italic() }"
                        @click="commands.italic"
                        class="btn btn-sm"
                    >
                        <open-icon glyph="italic" :title="$root.translate('Italic')" />
                    </button>
                    <button
                        v-if="styles"
                        type="button"
                        :class="{ 'btn-primary': isActive.underline(), 'btn-link': !isActive.underline() }"
                        @click="commands.underline"
                        class="btn btn-sm"
                    >
                        <open-icon glyph="underline" :title="$root.translate('Underline')" />
                    </button>
                    <button
                        v-if="styles"
                        type="button"
                        :class="{ 'btn-primary': isActive.strike(), 'btn-link': !isActive.strike() }"
                        @click="commands.strike"
                        class="btn btn-sm mr-3"
                    >
                        <open-icon glyph="strikethrough" :title="$root.translate('Strikethrough')" />
                    </button>

                    <button
                        v-if="links"
                        type="button"
                        :class="{ 'btn-primary': isActive.link(), 'btn-link': !isActive.link() }"
                        @click="link_edit(commands.link, getMarkAttrs('link'))"
                        class="btn btn-sm mr-3"
                    >
                        <open-icon glyph="link" :title="$root.translate('Link')" />
                    </button>

                    <button
                        v-if="lists"
                        type="button"
                        :class="{ 'btn-primary': isActive.bullet_list(), 'btn-link': !isActive.bullet_list() }"
                        @click="commands.bullet_list"
                        class="btn btn-sm"
                    >
                        <open-icon glyph="list" :title="$root.translate('Bullet List')" />
                    </button>
                    <button
                        v-if="lists"
                        type="button"
                        :class="{ 'btn-primary': isActive.ordered_list(), 'btn-link': !isActive.ordered_list() }"
                        @click="commands.ordered_list"
                        class="btn btn-sm"
                    >
                        <open-icon glyph="list-ol" :title="$root.translate('Ordered List')" />
                    </button>

                    <button
                        v-if="quotes"
                        type="button"
                        :class="{ 'btn-primary': isActive.blockquote(), 'btn-link': !isActive.blockquote() }"
                        @click="commands.blockquote"
                        class="btn btn-sm"
                    >
                        <open-icon glyph="quote-right" :title="$root.translate('Block Quote')" />
                    </button>

                    <div
                        v-if="!!snippets.length"
                        ref="snippet_picker"
                        :class="{ active: snippet_picking }"
                        class="dropdown hover-disabled"
                    >
                        <default-button
                            flavor="link"
                            tabindex="0"
                            @click.prevent.stop="snippet_toggle_picking"
                        >
                            <open-icon glyph="comment" :title="$root.translate('Insert Saved Reply...')" />
                        </default-button>
                        <!-- menu component -->
                        <ul class="menu">
                            <li
                                v-for="(snippet, index) in snippets"
                                :key="snippet.title"
                                class="menu-item"
                            >
                                <base-link
                                    href="#"
                                    :title="snippet.title"
                                    @click.stop.prevent="snippet_pick(index)"
                                >
                                    {{ snippet.title }}
                                </base-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </editor-menu-bar>

            <editor-content
                :editor="editor"
                class="nibnut-editor-content"
            >
                <div slot="content">{{ value }}</div>
            </editor-content>
        </div>

        <modal-dialog
            :id="`${id}-link-editor`"
            ref="link_editor"
            :show.sync="linking"
        >
            <div>
                <form-input
                    :id="`${id}-link-editor-url`"
                    name="link_url"
                    v-model="link_url"
                    :required="false"
                >
                    <template v-slot:label>{{ $root.translate("Url") }}</template>
                </form-input>
            </div>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        class="mr-2"
                        @click.prevent="linking=false"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        color="primary"
                        class="ml-2"
                        @click.prevent="link_save"
                    >
                        {{ $root.translate("Save") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>
    </div>
</template>

<script type="text/javascript">
import is_nibnut_component from "../../mixins/IsNibnutComponent"
import is_alpha_numerical_input from "../../mixins/IsAlphaNumericalInput"

import {
    Editor,
    EditorMenuBar,
    EditorContent
} from "tiptap"
import {
    // Nodes
    Blockquote,
    HardBreak,
    Heading,

    // Marks
    Bold,
    Italic,
    Link,
    Strike,
    Underline,

    BulletList,
    OrderedList,
    ListItem,

    History
} from "tiptap-extensions"
// import { DOMParser } from "prosemirror-model"

import ModalDialog from "../ModalDialog/ModalDialog"
import FormInput from "./FormInput"
import BaseLink from "../Links/BaseLink"
import DefaultButton from "../Buttons/DefaultButton"
import OpenIcon from "../OpenIcon"

export default {
    name: "BaseEditor",
    mixins: [is_nibnut_component, is_alpha_numerical_input],
    components: {
        EditorMenuBar,
        EditorContent,
        ModalDialog,
        FormInput,
        BaseLink,
        DefaultButton,
        OpenIcon
    },
    mounted () {
        const extensions = [new HardBreak(), new History()]
        if(this.headers > 0) extensions.push(new Heading({ maxLevel: 3 }))
        if(this.styles) {
            extensions.push(new Bold())
            extensions.push(new Italic())
            extensions.push(new Underline())
            extensions.push(new Strike())
        }
        if(this.lists) {
            extensions.push(new BulletList())
            extensions.push(new OrderedList())
            extensions.push(new ListItem())
        }
        if(this.quotes) extensions.push(new Blockquote())
        if(this.links) extensions.push(new Link())

        this.editor = new Editor({
            extensions,
            onUpdate: ({ getHTML }) => {
                this.editor_html = getHTML()
            },
            onTransaction: ({ getHTML }) => {
                this.editor_html = getHTML()
            },
            onBlur: () => {
                this.$emit("input", this.editor_html, this.name)
            }
        })
        if(this.editor) {
            this.editor_html = this.value
            this.editor.setContent(this.value)
        }
    },
    beforeDestroy () {
        if(this.editor) {
            this.editor.destroy()
            this.editor = null
        }
    },
    watch: {
        value (new_value, old_value) {
            if(!this.bootstraped || (new_value !== old_value)) {
                this.bootstraped = true
                this.editor_html = this.value
                this.editor.setContent(this.value)
            }
        }
    },
    methods: {
        link_edit (command, attrs) {
            this.link_save_cmd = command
            this.link_url = attrs.href
            this.linking = true
            this.$nextTick(() => {
                this.$refs.link_editor.$el.getElementsByTagName("input")[0].focus()
            })
        },
        link_save () {
            if(!this.link_url) this.link_save_cmd({ href: null })
            else {
                if(!this.link_url.match(/^(?:mailto|tel):/i) && !this.link_url.match(/^(?:https?:)?\/\//i)) this.link_url = "https://" + this.link_url
                this.link_save_cmd({ href: this.link_url, target: "_blank" })
            }
            this.linking = false
        },
        snippet_start_picking () {
            document.body.addEventListener("click", this.maybe_stop_snippet_picking)
            this.snippet_picking = true
        },
        snippet_pick (index) {
            // appends, but does not replace selection....
            /*
            console.log(this.editor_html)
            const html = `<p>${this.snippets[index].body.trim()}</p>`
            const parser = new window.DOMParser()
            const element = parser.parseFromString(html, "text/html").body.firstElementChild
            const node = DOMParser.fromSchema(this.editor.state.schema).parse(element)
            const transaction = this.editor.view.state.tr.insert(0, node)
            this.editor.view.dispatch(transaction)
            */
            this.$emit("input", this.snippets[index].body.trim(), this.name)
            // this.editor.setContent(this.snippets[index].body.trim())

            this.snippet_stop_picking()
        },
        snippet_stop_picking () {
            this.snippet_picking = false
            document.body.removeEventListener("click", this.maybe_stop_snippet_picking)
        },
        snippet_toggle_picking () {
            if(this.snippet_picking) this.snippet_stop_picking()
            else this.snippet_start_picking()
        },
        maybe_stop_snippet_picking (event) {
            if(event.target && this.$refs.snippet_picker && !this.$refs.snippet_picker.contains(event.target)) {
                this.snippet_stop_picking()
            }
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            default: ""
        },
        headers: {
            type: Number,
            validator: prop => (prop >= 0) && (prop <= 6),
            default: 3
        },
        styles: {
            type: Boolean,
            default: true
        },
        links: {
            type: Boolean,
            default: true
        },
        lists: {
            type: Boolean,
            default: true
        },
        quotes: {
            type: Boolean,
            default: true
        },
        size: {
            type: String,
            validator: prop => !prop || prop.match(/^(sm|md|lg|full)$/i),
            default: "md"
        },
        snippets: {
            type: Array,
            default () {
                return []
            }
        }
    },
    data () {
        return {
            editor: null,
            editor_html: "",
            bootstraped: false,

            link_save_cmd: null,
            link_url: null,
            linking: false,

            snippet_picking: false
        }
    }
}
</script>

<style lang="scss">
@import "../../../assets/sass/variables";

.nibnut-editor {
    & > div:first-of-type {
        background: $bg-color-light;
        background-image: none;
        border: $border-width solid $border-color-dark;
        border-radius: $border-radius;
        color: $body-font-color;
        display: block;
        font-size: $font-size;
        line-height: $line-height;
        max-width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        transition: background .2s, border .2s, box-shadow .2s, color .2s;
        width: 100%;

        .nibnut-editor-toolbar {
            padding: $control-padding-y $control-padding-x;
            background-color: $bg-color-light;
        }
        .nibnut-editor-content {
            box-shadow: 0 0.1rem 0.1rem rgba(48,55,66,.1) inset;
            overflow: auto;
            flex: 1 0 auto;

            & > .ProseMirror {
                height: 100%;
                appearance: none;
                outline: none;
                padding: ($control-padding-y * 2) $control-padding-x $control-padding-y $control-padding-x;

                ul {
                    list-style: disc;
                    margin: $unit-4 0 $unit-4 $unit-4;
                }
                ol {
                    list-style: decimal;
                }
                &:focus {
                    border-color: transparent;
                }

                & > :last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.nibnut-editor-sm {
        & > div:first-of-type > .nibnut-editor-content {
            flex-basis: 2rem;
        }
    }
    &.nibnut-editor-md {
        & > div:first-of-type > .nibnut-editor-content {
            flex-basis: 3.5rem;
        }
    }
    &.nibnut-editor-lg {
        & > div:first-of-type > .nibnut-editor-content {
            flex-basis: 7rem;
        }
    }
    &.nibnut-editor-full {
        display: flex;
        flex-direction: column;

        & > div {
            flex: 0 0 auto;
        }
        & > div:last-of-type {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;

            & > .nibnut-editor-toolbar {
                flex: 0 0 auto;
            }
            & > .nibnut-editor-content {
                flex: 1 0 auto;
            }
        }
    }
}
</style>
