<template>
    <div v-if="!!record" class="card">
        <div class="card-header">
            <div class="card-title h5">
                {{ $root.translate("Stripe") }}
            </div>
        </div>
        <div class="card-body">
            <form-select
                id="extra_credit_product_id"
                name="extra_credit_product_id"
                :value="record.extra_credit_product_id"
                :data-source="record.stripe.products"
                :show-all="true"
                :required="true"
                @input="save"
            >
                <template v-slot:label>{{ $root.translate("Extra Credit Product") }}</template>
            </form-select>
        </div>
    </div>
</template>

<script type="text/javascript">
import { FormSelect } from "@/nibnut/components"

export default {
    name: "StripeConnector",
    components: {
        FormSelect
    },
    methods: {
        save (value, field) {
            this.$emit("input", value, field)
        }
    },
    computed: {
    },
    props: {
        record: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            disconnecting: false
        }
    }
}
</script>
