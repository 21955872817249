<template>
    <page
        id="license-editor"
        :title="page_title"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <template v-slot:title>
            <ul class="breadcrumb mb-4">
                <router-link
                    :to="{ name: 'license.list' }"
                    tag="li"
                    class="breadcrumb-item"
                >
                    <base-link
                        :href="{ name: 'license.list' }"
                    >
                        {{ $root.translate("Licenses") }}
                    </base-link>
                </router-link>
                <li
                    v-if="!!edited_record && !!edited_record.id"
                    class="breadcrumb-item"
                >
                    {{ edited_record.name }}
                </li>
            </ul>
        </template>

        <base-form
            v-if="!!edited_record"
            :has-required-fields="true"
            class="mt-8"
        >
            <div class="columns">
                <div class="column col-6 col-md-6 col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="columns">
                                <div class="column">
                                    <form-input
                                        id="name"
                                        name="name"
                                        v-model="edited_record.name"
                                        :required="true"
                                        :saving="saving('name')"
                                        :error="has_error('name')"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ $root.translate("Name") }}</template>
                                    </form-input>
                                </div>
                                <div class="column col-auto">
                                    <form-select
                                        id="type"
                                        name="type"
                                        v-model="edited_record.type"
                                        :data-source="license_types"
                                        :required="true"
                                        :error="has_error('type')"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ $root.translate("Type") }}</template>
                                    </form-select>
                                </div>
                            </div>

                            <form-input
                                id="license_key"
                                name="license_key"
                                v-model="edited_record.license_key"
                                :required="true"
                                :saving="saving('license_key')"
                                :error="has_error('license_key')"
                                @input="save"
                            >
                                <template v-slot:label>{{ $root.translate("Key") }}</template>
                                <template v-slot:right_addon>
                                    <default-button
                                        v-if="!!edited_record.license_key"
                                        v-clipboard:copy="edited_record.license_key"
                                        v-clipboard:success="copied_to_clipboard"
                                    >
                                        <open-icon v-if="copied" glyph="check-circle" />
                                        <open-icon v-else glyph="clipboard" />
                                    </default-button>
                                </template>
                            </form-input>

                            <div class="columns">
                                <div class="column col-6 col-md-12 mb-2">
                                    <form-select
                                        id="repeat"
                                        name="repeat"
                                        v-model="edited_record.repeat"
                                        :data-source="repeats"
                                        :required="true"
                                        :error="has_error('repeat')"
                                        @input="save_repeat"
                                    >
                                        <template v-slot:label>{{ $root.translate("Renewal") }}</template>
                                    </form-select>
                                </div>
                                <div class="column col-6 col-md-12 mb-2">
                                    <form-date-input
                                        id="repeat_on"
                                        name="repeat_on"
                                        v-model="edited_record.repeat_on"
                                        position="bottom"
                                        :required="false"
                                        :min="min_repeat"
                                        :max="max_repeat"
                                        :saving="saving('repeat_on')"
                                        :error="has_error('repeat_on')"
                                        @input="save_date"
                                    >
                                        <template v-slot:label>
                                            <span v-if="edited_record.repeat === constants('repeats', 'REPEAT_LIFETIME').id">{{ $root.translate("Acquired On") }}</span>
                                            <span v-else>{{ $root.translate("Renews Next On") }}</span>
                                        </template>
                                    </form-date-input>
                                </div>

                                <div class="column col-6 col-md-12">
                                    <form-input
                                        id="purchase_price"
                                        name="purchase_price"
                                        v-model="edited_record.purchase_price"
                                        glyph="dollar-sign"
                                        glyph-position="left"
                                        :required="false"
                                        :saving="saving('purchase_price')"
                                        :error="has_error('purchase_price')"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ $root.translate("Cost") }}</template>
                                        <template v-slot:hint>{{ $root.translate("How much you pay") }}</template>
                                    </form-input>
                                </div>
                                <div class="column col-6 col-md-12">
                                    <form-input
                                        id="sale_price"
                                        name="sale_price"
                                        v-model="edited_record.sale_price"
                                        glyph="dollar-sign"
                                        glyph-position="left"
                                        :required="false"
                                        :saving="saving('sale_price')"
                                        :error="has_error('sale_price')"
                                        @input="save_price"
                                    >
                                        <template v-slot:label>{{ $root.translate("Default Revenue") }}</template>
                                        <template v-slot:hint>{{ $root.translate("How much you charge clients") }}</template>
                                        <template v-slot:right_addon>
                                            <span class="input-group-addon">/</span>
                                            <select
                                                :value="edited_record.sale_price_repeat"
                                                class="form-select"
                                                @change="save_price($event.target.value, 'sale_price_repeat')"
                                            >
                                                <option
                                                    v-for="repeat in price_repeats"
                                                    :key="repeat.id"
                                                    :value="repeat.id"
                                                >
                                                    {{ repeat.period }}
                                                </option>
                                            </select>
                                        </template>
                                    </form-input>
                                </div>
                            </div>

                            <div
                                v-if="!!edited_record && !!edited_record.id"
                                class="text-center mt-7"
                            >
                                <default-button
                                    flavor="link"
                                    color="error"
                                    size="sm"
                                    @click.prevent="confirm_record_delete"
                                >
                                    {{ $root.translate("Delete License") }}
                                </default-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column col-6 col-md-6 col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <form-input
                                id="provider_url"
                                name="provider_url"
                                v-model="edited_record.provider_url"
                                :required="false"
                                :saving="saving('provider_url')"
                                :error="has_error('provider_url')"
                                class="mt-2"
                                @input="save"
                            >
                                <template v-slot:label>{{ $root.translate("Provider URL") }}</template>
                                <template v-slot:right_addon>
                                    <a
                                        v-if="!!edited_record.provider_url"
                                        :href="edited_record.provider_url"
                                        target="_blank"
                                        class="btn"
                                    >
                                        <open-icon glyph="external-link-alt" />
                                    </a>
                                </template>
                            </form-input>

                            <form-input
                                id="source_url"
                                name="source_url"
                                v-model="edited_record.source_url"
                                :required="false"
                                :saving="saving('source_url')"
                                :error="has_error('source_url')"
                                @input="save"
                            >
                                <template v-slot:label>{{ $root.translate("Source URL") }}</template>
                                <template v-slot:right_addon>
                                    <a
                                        v-if="!!edited_record.source_url"
                                        :href="edited_record.source_url"
                                        target="_blank"
                                        class="btn"
                                    >
                                        <open-icon glyph="external-link-alt" />
                                    </a>
                                </template>
                            </form-input>

                            <form-editor
                                id="description"
                                name="description"
                                v-model="edited_record.description"
                                size="lg"
                                :required="false"
                                :full-height="false"
                                :saving="saving('description')"
                                :error="has_error('description')"
                                @input="save"
                            >
                                <template v-slot:label>{{ $root.translate("Description") }}</template>
                            </form-editor>
                        </div>
                    </div>
                </div>
            </div>

            <site-license-list
                v-if="!!edited_record.id"
                :license-id="edited_record.id"
                class="mt-8"
            />

            <div
                v-if="!!edited_record && !edited_record.id"
                class="text-center mt-4"
            >
                <default-button
                    :disabled="creating"
                    class="mr-2"
                    @click.prevent="$router.go(-1)"
                >
                    {{ $root.translate("Cancel") }}
                </default-button>
                <default-button
                    :disabled="creating"
                    :waiting="creating"
                    color="primary"
                    class="ml-2"
                    @click.prevent="create_record"
                >
                    {{ $root.translate("Save") }}
                </default-button>
            </div>
        </base-form>

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            :waiting="deleting_record"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </page>
</template>

<script type="text/javascript">
import { is_record_editor_page } from "@/nibnut/mixins"
import { handles_copy_paste } from "@/custom/mixins"

import {
    BaseForm,
    FormInput,
    FormSelect,
    FormDateInput,
    FormEditor,
    BaseLink,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"
import {
    SiteLicenseList
} from "@/custom/components"

export default {
    name: "ClientEditor",
    mixins: [is_record_editor_page, handles_copy_paste],
    components: {
        BaseForm,
        FormInput,
        FormSelect,
        FormDateInput,
        FormEditor,
        BaseLink,
        DefaultButton,
        OpenIcon,
        SiteLicenseList
    },
    methods: {
        confirm_record_delete () {
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete {name}", { name: this.edited_record.name }),
                    message: this.$root.translate("Do you really want to delete this license? It will also be unlinked from sites and clients. There is no undo..."),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") this.record_delete()
            else this.done_confirming()
        },
        record_deleted (record) {
            this.$router.push({ name: "license.list" })
        },
        create_record () {
            this.creating = true
            const data = {
                ...this.edited_record
            }
            if(data.repeat_on) data.repeat_on = this.$moment(data.repeat_on).format("YYYY-MM-DD")
            this.$store.dispatch("CREATE_RECORD", {
                entity: this.entity,
                data
            }).then(record => {
                this.$router.replace({ name: "license.edit", params: { id: record.id } })
            }).catch(this.receive_error).then(() => {
                this.creating = false
            })
        },
        save_repeat (value, field) {
            this.save(value, field)
            if(!this.edited_record.id) {
                if(value === this.constants("repeats", "REPEAT_LIFETIME").id) this.edited_record.repeat_on = this.$moment()
                else if(value === this.constants("repeats", "REPEAT_YEARLY").id) this.edited_record.repeat_on = this.$moment().add(1, "year")
                else this.edited_record.repeat_on = this.$moment().add(1, "month") // .startOf("month")
            }
        },
        save_price (value, field) {
            this.save_data_for_record_id(this.entity, this.edited_record.id, {
                [field]: value,
                fields: ["fieldset::default", "ns::site_license;fieldset::sublist"],
                relation_ids: ["site_license"]
            })
        }
    },
    computed: {
        license_types () {
            return Object.values(this.constants("license_types"))
        },
        repeats () {
            return Object.values(this.constants("repeats"))
        },
        price_repeats () {
            const for_life_id = this.constants("repeats", "REPEAT_LIFETIME").id
            return this.repeats.filter(repeat => repeat.id !== for_life_id)
        },
        min_repeat () {
            if(!!this.edited_record && (this.edited_record.repeat === this.constants("repeats", "REPEAT_LIFETIME").id)) return null
            return this.$moment().startOf("day")
        },
        max_repeat () {
            if(!!this.edited_record && (this.edited_record.repeat === this.constants("repeats", "REPEAT_LIFETIME").id)) return this.$moment().endOf("day")
            return null
        }
    },
    data () {
        return {
            entity: "license",
            entityName: "License:::Licenses",

            creating: false
        }
    }
}
</script>
