<template>
    <section
        v-if="!!type"
        :class="{ [`message-type-${type}`]: true }"
        class="ticket-message ticket-message-editor p-6"
    >
        <div class="form-horizontal text-right">
            <form-token-input
                v-if="cc_bcc_mode"
                id="cc"
                name="cc"
                :value="message.cc"
                :required="false"
                size="sm"
                @input="add_cc_bcc"
                @delete="remove_cc_bcc"
            >
                <template v-slot:label>{{ translate("Cc") }}</template>
            </form-token-input>
            <form-token-input
                v-if="cc_bcc_mode"
                id="bcc"
                name="bcc"
                :value="message.bcc"
                :required="false"
                size="sm"
                @input="add_cc_bcc"
                @delete="remove_cc_bcc"
            >
                <template v-slot:label>{{ translate("Bcc") }}</template>
            </form-token-input>
            <default-button
                v-else
                flavor="link"
                size="sm"
                @click.prevent.stop="cc_bcc_mode = !cc_bcc_mode"
            >
                {{ $root.translate("Cc/Bcc") }}
            </default-button>
        </div>
        <form-editor
            id="ticket-message-editor-text"
            name="text"
            v-model="message.text"
            :snippets="app_context.settings.freescout_snippets"
            :required="true"
            size="lg"
            :full-height="false"
        />
        <div class="columns justify-end align-items-center form-horizontal">
            <div class="column">
                <default-button
                    flavor="link"
                    :disabled="!!creating"
                    size="sm"
                    @click.prevent.stop="$emit('update:type', null)"
                >
                    {{ $root.translate("Cancel") }}
                </default-button>
            </div>
            <div class="column col-auto">
                <form-select
                    id="ticket-message-editor-status"
                    name="status"
                    v-model="message.status"
                    :data-source="statii"
                    :required="true"
                    :show-all="true"
                    :disabled="!!creating"
                    size="sm"
                >
                    <template v-slot:label>{{ $root.translate("Status") }}</template>
                </form-select>
            </div>
            <!--
            <div class="column col-auto">
                <form-select
                    id="ticket-message-editor-user_id"
                    name="user_id"
                    v-model="message.user_id"
                    data-source="user"
                    :required="true"
                    :show-all="true"
                    :disabled="!!creating"
                    size="sm"
                >
                    <template v-slot:label>{{ $root.translate("Assign to") }}</template>
                </form-select>
            </div>
            //-->
            <div class="column col-auto">
                <default-button
                    :color="(type === 'note') ? 'primary' : 'tertiary'"
                    :disabled="!!creating"
                    :waiting="!!creating"
                    size="sm"
                    @click.prevent.stop="create"
                >
                    <span v-if="type === 'note'">{{ $root.translate("Add Note") }}</span>
                    <span v-else>{{ $root.translate("Send Reply") }}</span>
                </default-button>
            </div>
        </div>
    </section>
</template>

<script>
import { misc_utilities, profile_utilities } from "@/nibnut/mixins"

import { FormEditor, FormTokenInput, FormSelect, DefaultButton } from "../../nibnut/components"

export default {
    name: "MessageEditor",
    mixins: [misc_utilities, profile_utilities],
    components: {
        FormEditor,
        FormTokenInput,
        FormSelect,
        DefaultButton
    },
    watch: {
        type: "reset"
    },
    methods: {
        reset () {
            if(this.type) {
                this.cc_bcc_mode = false
                this.creating = false
                this.message.type = this.type
                this.message.text = `<p></p>${this.profile.email_signature || ""}`
                this.message.user_id = this.ticket.user_id // will need to be switched to user: user.freescout_id !!! API module bug ignores this parameter!!!
                this.message.status = this.constants("ticket_statii", "FREESCOUT_STATE_PENDING").id
                this.message.cc = []
                this.message.bcc = []
            }
        },
        add_cc_bcc (value, field) {
            const email = value.toLowerCase()
            const index = this.message[field].indexOf(email)
            if(index < 0) this.message[field].push(email)
        },
        remove_cc_bcc (value, field) {
            const index = this.message[field].indexOf(value.toLowerCase())
            if(index >= 0) this.message[field].splice(index, 1)
        },
        create () {
            this.creating = true
            const data = { ...this.message }
            if(!this.cc_bcc_mode) {
                data.cc = []
                data.bcc = []
            }
            this.$store.dispatch(
                "RECORD_ACTION",
                {
                    entity: "ticket",
                    id: this.ticket.id,
                    action: "reply",
                    data: this.message,
                    method: "post"
                }
            ).then(() => {
                this.$emit("update:type", null)
            }).catch(this.receive_error).then(() => {
                this.creating = false
            })
        }
    },
    computed: {
        statii () {
            return Object.values(this.constants("ticket_statii"))
        }
    },
    props: {
        type: {
            type: String
        },
        ticket: {
            type: Object
        }
    },
    data () {
        return {
            cc_bcc_mode: false,
            message: {
                type: "",
                text: "",
                user_id: 0,
                status: "",
                cc: [],
                bcc: []
            },
            creating: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.ticket-message-editor {
    .form-autocomplete.nibnut-token-input {
        flex: 1 0 auto;
    }
}
</style>
