<template>
    <div id="alerts-list-container">
        <data-table
            id="alerts-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="true"
            entity-name="alert:::alerts"
            @sort="sort_by"
            @search="search"
            @clear-search="clear_search"
            @page="goto_page"
            @add="record_edit(0)"
            @click="record_edit($event.id)"
        >
            <template
                v-slot:controls
            >
                <div>
                    <form-select
                        id="archived"
                        name="archived"
                        :value="state.archived"
                        :data-source="filters"
                        :show-all="true"
                        :required="true"
                        @input="toggle_archived"
                    />
                </div>
            </template>
            <template
                v-slot:summary="{ row }"
            >
                <h5>{{ row.send_on | nibnut.date }}</h5>
                <h6>{{ row.email }}</h6>
                <h6
                    v-if="topicType === '*'"
                    class="text-small"
                >
                    <base-link
                        :href="{ name: `${entity_from_topic_type(row.topic_type)}.edit`, params: { id: row.topic_id } }"
                    >
                        {{ row.computed_topic_name }}
                        <open-icon glyph="arrow-circle-right" class="ml-1" />
                    </base-link>
                </h6>
                <div>
                    {{ row.message | nibnut.smart_truncate(80) }}
                </div>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <span v-if="field === 'send_on'">
                    {{ row.send_on | nibnut.date }}
                </span>
                <span v-else-if="field === 'message'">
                    {{ row.message | nibnut.smart_truncate(80) }}
                </span>
                <base-link
                    v-else-if="field === 'computed_topic_name'"
                    :href="{ name: `${entity_from_topic_type(row.topic_type)}.edit`, params: { id: row.topic_id } }"
                >
                    {{ row.computed_topic_name }}
                    <open-icon glyph="arrow-circle-right" class="ml-1" />
                </base-link>
                <div v-else-if="field === '_buttons'" class="text-right">
                    <default-button
                        flavor="link"
                        color="error"
                        @click.prevent.stop="confirm_record_delete(row)"
                    >
                        <open-icon glyph="trash" />
                    </default-button>
                </div>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>

        <modal-dialog
            v-if="!!edited_record"
            id="alert-editor"
            :show.sync="editing"
        >
            <template v-slot:title>
                <span class="h5">{{ translate("Alert") }}</span>
            </template>

            <form-input
                id="email"
                name="email"
                v-model="edited_record.email"
                :required="true"
                :disabled="is_past"
                :saving="saving('email')"
                :error="has_error('email')"
                @input="save"
            >
                <template v-slot:label>{{ translate("Send To") }}</template>
            </form-input>
            <form-date-input
                id="send_on"
                name="send_on"
                v-model="edited_record.send_on"
                position="bottom"
                :min="$moment({ hour: 0 }).add(1, 'day')"
                calendar-size="sm"
                :required="true"
                :disabled="is_past"
                :saving="saving('send_on')"
                :error="has_error('send_on')"
                @input="save_as_date"
            >
                <template v-slot:label>{{ translate("Send On") }}</template>
                <template v-slot:hint>
                    <span v-if="!is_past">
                        {{ translate("Send in") }}
                        <a
                            v-for="months in presets"
                            :key="months"
                            href="#"
                            class="mx-2"
                            @click.prevent="send_in(months)"
                        >{{ months | nibnut.number("0,0") }}</a>
                        {{ translate("months") }}
                    </span>
                </template>
            </form-date-input>
            <form-textbox
                id="message"
                name="message"
                v-model="edited_record.message"
                size="lg"
                :required="true"
                :disabled="is_past"
                :saving="saving('message')"
                :error="has_error('message')"
                @input="save"
            >
                <template v-slot:label>{{ translate("Message") }}</template>
            </form-textbox>

            <template v-slot:footer>
                <div v-if="!edited_record.id" class="text-center">
                    <default-button
                        :disabled="creating_record"
                        class="mr-2"
                        @click.prevent="editing = false"
                    >
                        {{ translate("Cancel") }}
                    </default-button>
                    <default-button
                        :disabled="creating_record"
                        :waiting="creating_record"
                        color="primary"
                        class="ml-2"
                        @click.prevent="record_save"
                    >
                        {{ translate("Create") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script type="text/javascript">
import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"
import is_record_editor from "@/nibnut/mixins/IsRecordEditor"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import FormDateInput from "@/nibnut/components/Inputs/FormDateInput"
import FormTextbox from "@/nibnut/components/Inputs/FormTextbox"
import FormSelect from "@/nibnut/components/Inputs/FormSelect"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "AlertsList",
    mixins: [is_remote_data_table_source, is_record_editor],
    components: {
        ModalDialog,
        FormInput,
        FormDateInput,
        FormTextbox,
        FormSelect,
        BaseLink,
        DefaultButton,
        OpenIcon
    },
    mounted () {
        this.reset()
    },
    watch: {
        topicType: "reset",
        topicId: "reset"
    },
    methods: {
        grab_record_id () {
        },
        reset () {
            this.record_id = null
            this.editing = false
            this.reset_state()
            this.refresh()
        },
        pre_load () {
            this.set_state_values({
                topic_type: this.topicType,
                topic_id: this.topicId
            })
        },
        post_load () {
            if(this.record_id !== null) {
                if(this.shell_record) {
                    this.shell_record.topic_type = this.topicType
                    this.shell_record.topic_id = this.topicId
                }
                this.editing = true
            }
        },
        send_in (months) {
            if(months) {
                const from_date = this.$moment({ hour: 0 }).add(months, "months")
                this.save(from_date.format("YYYY-MM-DD"), "send_on")
            }
        },
        entity_from_topic_type (topic_type) {
            if(topic_type) return topic_type.replace(/^App\\+/g, "").toLowerCase()
            return ""
        },
        confirm_record_delete (row) {
            this.record_id = row.id
            this.confirm(
                {
                    type: "error",
                    title: this.translate("Delete Alert"),
                    message: this.translate("Do you really want to delete this alert? ({email}, {date}) There is no undo...", { email: row.email, date: this.nibnut_filter("nibnut.date", [row.send_on]) }),
                    cancel: this.translate("Keep"),
                    ok: this.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") this.record_delete()
            else this.done_confirming()
        },
        record_edit (id) {
            this.record_id = id
            this.reload()
        },
        record_save () {
            const data = { ...this.edited_record, ...this.reload_query }
            const is_new = !data.id
            if(is_new) this.creating_record = true
            return this.save_data_for_record_id(this.entity, data.id || 0, data).then(record => {
                this.creating_record = false
                this.editing = false
                this.record_id = null
                this.refresh()
            })
        },
        record_deleted (record) {
            this.record_id = null
            this.refresh()
        }
    },
    computed: {
        visible_columns () {
            const columns = { ...this.columns }
            if(this.topicType !== "*") delete columns.computed_topic_name
            return columns
        },
        cleanup_before_reload () {
            return false
        },
        can_reload () {
            return this.can_refresh && (this.record_id !== null)
        },
        can_refresh () {
            return !this.loading && !!this.topicType && !!this.topicId
        },
        fields () {
            return ["fieldset::list"]
        },
        filters () {
            return [
                { id: false, name: this.translate("Upcoming") },
                { id: true, name: this.translate("Past") }
            ]
        },
        is_past () {
            const edited_record = this.edited_record
            return !edited_record || !edited_record.send_on || this.$moment(edited_record.send_on).isBefore()
        },
        presets () {
            return [1, 3, 6, 9, 12]
        }
    },
    props: {
        topicType: {
            type: String,
            default: ""
        },
        topicId: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            entity: "alert",
            relation_ids: ["topic"],
            record_id: null,
            columns: {
                send_on: { label: "Date", sort: "asc", type: "amount" },
                email: { label: "To", sort: null, type: "alpha" },
                message: { label: "Message", sort: false },
                computed_topic_name: { label: "About", sort: null },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "send_on",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                topic_type: "",
                topic_id: 0
            },
            editing: false
        }
    }
}
</script>

<style lang="scss">
.modal.alerts-dialog {
    .nibnut-data-table thead th {
        top: 0;
    }
}
</style>
