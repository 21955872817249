<template>
    <page
        id="client-editor"
        :title="page_title"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <template v-slot:title>
            <ul class="breadcrumb">
                <router-link
                    :to="{ name: 'client.list' }"
                    tag="li"
                    class="breadcrumb-item"
                >
                    <base-link
                        :href="{ name: 'client.list' }"
                    >
                        {{ $root.translate("Clients") }}
                    </base-link>
                </router-link>
                <li
                    v-if="!!edited_record && !!edited_record.id"
                    class="breadcrumb-item"
                >
                    {{ edited_record.first_name }} {{ edited_record.last_name }}
                </li>
            </ul>
        </template>

        <alert-button
            :topic="edited_record"
            @click.prevent.stop="alerts_shown = !alerts_shown"
        />

        <base-form
            v-if="!!edited_record"
            :has-required-fields="true"
            class="mt-8"
        >
            <div class="columns">
                <div class="column col-6 col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <form-input
                                id="company"
                                name="company"
                                v-model="edited_record.company"
                                :required="false"
                                :saving="saving('company')"
                                :error="has_error('company')"
                                @input="save"
                            >
                                <template v-slot:label>{{ $root.translate("Company") }}</template>
                            </form-input>

                            <div class="text-gray text-uppercase my-2 columns remote-connections">
                                <div
                                    v-if="!!edited_record.first_name && !!edited_record.last_name"
                                    class="column flex-static col-sm-12"
                                >
                                    {{ $root.translate("QuickBooks") }}:
                                    <remote-connection-button
                                        flavor="link"
                                        :remote-id="edited_record.quickbooks_id"
                                        remote-service-name="QBO"
                                        :url="quickbooks_url('app/customerdetail', `nameId=${edited_record.quickbooks_id}`)"
                                        class="quickbooks-button"
                                        @click="quickbox_toggle"
                                    >
                                        <quickbooks-logo :white="!edited_record.quickbooks_id" />
                                    </remote-connection-button>
                                    <!--
                                    <default-button
                                        v-if="!!edited_record.quickbooks_id"
                                        flavor="link"
                                        :title="$root.translate('Disconnect QBO Customer')"
                                        class="quickbooks-button quickbooks-unlink-button ml-2 mr-1"
                                        @click.prevent="save(null, 'quickbooks_id')"
                                    >
                                        <quickbooks-logo :white="true" />
                                        <open-icon glyph="ban" size="2x" class="text-error" />
                                    </default-button>
                                    <base-link
                                        v-if="!!edited_record.quickbooks_id"
                                        :href="quickbooks_url('app/customerdetail', `nameId=${edited_record.quickbooks_id}`)"
                                        :title="$root.translate('View QBO Customer')"
                                        target="_blank"
                                        class="quickbooks-button btn btn-link"
                                    >
                                        <quickbooks-logo />
                                    </base-link>
                                    <default-button
                                        v-else
                                        flavor="link"
                                        :title="$root.translate('Connect to QBO Customer')"
                                        class="quickbooks-button ml-1"
                                        @click.prevent="quickbooks_connect"
                                    >
                                        <quickbooks-logo :white="true" />
                                    </default-button>
                                    //-->
                                </div>
                                <form-toggle-input
                                    id="go_card_less"
                                    name="go_card_less"
                                    type="checkbox"
                                    :value="!!edited_record.go_card_less"
                                    :required="false"
                                    :error="has_error('go_card_less')"
                                    :placeholder="$root.translate('GoCardLess')"
                                    class="column flex-variable col-sm-6"
                                    @input="save"
                                >
                                    {{ $root.translate('GoCardLess') }}
                                </form-toggle-input>
                                <form-toggle-input
                                    id="wpe_review_provided"
                                    name="wpe_review_provided"
                                    type="checkbox"
                                    :value="!!edited_record.wpe_review_provided"
                                    :required="false"
                                    :error="has_error('wpe_review_provided')"
                                    :placeholder="$root.translate('Review')"
                                    class="column flex-variable col-sm-6"
                                    @input="save"
                                >
                                    {{ $root.translate('Review') }}
                                </form-toggle-input>
                                <form-toggle-input
                                    id="one_time_client"
                                    name="one_time_client"
                                    type="checkbox"
                                    :value="!!edited_record.one_time_client"
                                    :required="false"
                                    :error="has_error('one_time_client')"
                                    :placeholder="$root.translate('One Time Client')"
                                    class="column flex-static col-sm-6"
                                    @input="save"
                                >
                                    {{ $root.translate('One Time Client') }}
                                </form-toggle-input>
                            </div>

                            <div class="columns">
                                <div class="column col-6 col-md-12">
                                    <form-input
                                        id="email"
                                        name="email"
                                        type="email"
                                        v-model="edited_record.email"
                                        :required="false"
                                        :saving="saving('email')"
                                        :error="has_error('email')"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ $root.translate("Email") }}</template>
                                        <template v-slot:right_addon>
                                            <mailto-link
                                                v-if="!!edited_record.email"
                                                :email="edited_record.email"
                                                :copy="true"
                                                class="btn btn-primary input-group-btn"
                                            >
                                                <template v-slot:default="{ copied }">
                                                    <open-icon :glyph="copied ? 'check-circle' : 'envelope'" />
                                                </template>
                                            </mailto-link>
                                        </template>
                                    </form-input>

                                    <form-date-input
                                        id="last_contacted_on"
                                        name="last_contacted_on"
                                        v-model="edited_record.last_contacted_on"
                                        position="bottom"
                                        :required="false"
                                        :placeholder="$root.translate('Never contacted')"
                                        :max="$moment({ hour: 0 })"
                                        :saving="saving('last_contacted_on')"
                                        :error="has_error('last_contacted_on')"
                                        @input="save_date"
                                    >
                                        <template v-slot:label>{{ $root.translate("Last Contacted") }}</template>
                                        <template v-slot:hint>
                                            <a
                                                href="#"
                                                class="mx-2"
                                                @click.prevent="last_contact_today"
                                            >{{ $root.translate("Today") }}</a>
                                        </template>
                                    </form-date-input>
                                </div>
                                <div class="column col-6 col-md-12">
                                    <form-input
                                        id="phone"
                                        name="phone"
                                        type="tel"
                                        v-model="edited_record.phone"
                                        :required="false"
                                        :saving="saving('phone')"
                                        :error="has_error('phone')"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ $root.translate("Phone") }}</template>
                                        <template v-slot:right_addon>
                                            <base-link
                                                v-if="!!edited_record.phone && small_screen"
                                                :href="edited_record.phone | nibnut.telto"
                                                class="btn btn-primary input-group-btn"
                                            >
                                                <open-icon glyph="phone" />
                                            </base-link>
                                        </template>
                                    </form-input>

                                    <form-date-input
                                        id="next_contact_on"
                                        name="next_contact_on"
                                        v-model="edited_record.next_contact_on"
                                        position="bottom"
                                        :min="$moment({ hour: 0 }).add(1, 'day')"
                                        :required="false"
                                        :saving="saving('next_contact_on')"
                                        :error="has_error('next_contact_on')"
                                        @input="save_date"
                                    >
                                        <template v-slot:label>{{ $root.translate("Next Contact") }}</template>
                                        <template v-slot:hint>
                                            {{ $root.translate("Schedule in") }}
                                            <a
                                                v-for="months in client_contact_delays"
                                                :key="months"
                                                href="#"
                                                class="mx-2"
                                                @click.prevent="contact_client_in(months)"
                                            >{{ months | nibnut.number("0,0") }}</a>
                                            {{ $root.translate("months") }}
                                        </template>
                                    </form-date-input>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="!!edited_record" class="card mt-4">
                        <div class="card-header">
                            <div class="card-title h5">
                                <span v-if="!!edited_record.id">{{ $root.translate("Contacts") }}</span>
                                <span v-else>{{ $root.translate("Contact") }}</span>
                            </div>
                        </div>
                        <div class="card-body">
                            <contacts
                                :client="edited_record"
                                :shell="shell_contact"
                                @input="save($event, 'main_contact_id')"
                                @connected="contact_connected"
                            />

                            <expandable :borderless="true">
                                <template v-slot:title>{{ $root.translate("Address") }}</template>
                                <address-input
                                    id="address"
                                    name="address"
                                    :value="address"
                                    :required="false"
                                    @input="save_data_for_record_id(entity, edited_record.id, $event)"
                                />
                            </expandable>

                            <form-editor
                                id="notes"
                                name="notes"
                                v-model="edited_record.notes"
                                size="lg"
                                :required="false"
                                :full-height="false"
                                :saving="saving('notes')"
                                :error="has_error('notes')"
                                @input="save"
                            >
                                <template v-slot:label>{{ $root.translate("Notes") }}</template>
                            </form-editor>
                        </div>
                        <div v-if="!!edited_record && !!edited_record.id" class="text-center my-4">
                            <default-button
                                flavor="link"
                                color="error"
                                size="sm"
                                @click.prevent="confirm_record_delete"
                            >
                                {{ $root.translate("Delete Client") }}
                            </default-button>
                        </div>
                    </div>
                </div>
                <div class="column col-6 col-sm-12">
                    <div class="card full-height">
                        <div class="card-body">
                            <div class="columns">
                                <div class="column col-6 col-sm-12">
                                    <form-select
                                        id="type"
                                        name="type"
                                        v-model="edited_record.type"
                                        :data-source="client_types"
                                        :required="true"
                                        :saving="saving('type')"
                                        :error="has_error('type')"
                                        class="col-12"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ $root.translate("Tag") }}</template>
                                    </form-select>
                                </div>
                                <div class="column col-6 col-sm-12">
                                    <form-select
                                        id="category"
                                        name="category"
                                        v-model="edited_record.category"
                                        data-source="client"
                                        :required="false"
                                        :ad-hoc="true"
                                        :show-all="true"
                                        empty-value=""
                                        :saving="saving('category')"
                                        :error="has_error('category')"
                                        @input="save"
                                        @create="create_autosuggest_value"
                                    >
                                        <template v-slot:label>{{ $root.translate("Type") }}</template>
                                    </form-select>
                                </div>
                                <div class="column col-6 col-xl-4 col-md-12">
                                    <form-date-input
                                        id="joined_on"
                                        name="joined_on"
                                        v-model="edited_record.joined_on"
                                        position="bottom"
                                        date-format="MM/DD/YY"
                                        :required="false"
                                        :saving="saving('joined_on')"
                                        :error="has_error('joined_on')"
                                        @input="save_date"
                                    >
                                        <template v-slot:label>
                                            {{ $root.translate("Since") }} <span class="text-small text-gray">({{ edited_record.joined_on | nibnut.date_from_now(true) }})</span>
                                        </template>
                                    </form-date-input>
                                </div>
                                <div class="column col-6 col-xl-8 col-md-12 referred-by-container">
                                    <form-input
                                        id="referred_by"
                                        name="referred_by"
                                        v-model="edited_record.referred_by"
                                        :required="false"
                                        :saving="saving('referred_by')"
                                        :error="has_error('referred_by')"
                                        class="referred-by"
                                        @input="save"
                                    >
                                        <template v-slot:label>
                                            {{ $root.translate("Referred By") }}
                                            <small v-if="edited_record.computed_lifetime_value" class="text-gray">({{ $root.translate("LTV") }}: {{ edited_record.computed_lifetime_value | nibnut.currency }})</small>
                                        </template>
                                    </form-input>
                                    <form-toggle-input
                                        id="gift_given_on"
                                        name="gift_given_on"
                                        type="checkbox"
                                        :value="!!edited_record.gift_given_on"
                                        :required="false"
                                        :error="has_error('gift_given_on')"
                                        :placeholder="$root.translate('StarBucks Card Sent')"
                                        class="sb"
                                        @input="save_gift_given"
                                    >
                                        <template v-slot:label>{{ $root.translate('SB') }}</template>
                                    </form-toggle-input>
                                </div>
                            </div>

                            <tickets
                                v-if="!!edited_record"
                                :client-id="edited_record.id"
                                :data-version="tickets_version"
                                class="mt-4"
                            />

                            <data-table
                                v-if="!!edited_record && !!edited_record.id"
                                id="client-sites"
                                entity-name="site:::sites"
                                :columns="visible_columns"
                                :rows="filtered_rows"
                                :search="state.search"
                                :total="state.total"
                                :found="state.found"
                                :current-page="state.page"
                                :total-pages="nb_pages"
                                :can-add="false"
                                :compact="$mq.match(/^(md|lg|tl|xl|fred)$/i)"
                                class="mt-4"
                                @sort="sort_by"
                                @search="search"
                                @clear-search="clear_search"
                                @page="goto_page"
                                @click="record_edit('site', $event.id)"
                            >
                                <template v-slot:controls>
                                    <h5>
                                        {{ $root.translate("Sites") }}
                                        <default-button
                                            :title="$root.translate('New Site')"
                                            flavor="link"
                                            @click.prevent="adding_site = true"
                                        >
                                            <open-icon glyph="plus" />
                                        </default-button>
                                    </h5>
                                </template>
                                <template
                                    v-slot:summary="{ row }"
                                >
                                    <h6>{{ row.name }}</h6>
                                    <div class="text-gray text-small">
                                        <a
                                            :href="row.url"
                                            target="_blank"
                                            @click.stop
                                        >
                                            {{ row.domain }}
                                        </a><span v-if="!!row.ip_address"> ({{ row.ip_address }})</span>
                                    </div>
                                    <div
                                        v-if="row.computed_value !== null"
                                        class="text-gray text-small"
                                    >
                                        {{ (!!row.plan_names && !!row.plan_names.length) ? row.plan_names.join(", ") : "" }} ({{ row.computed_value | nibnut.currency("0,0.00", $root.translate("Free")) }})
                                    </div>
                                    <monitor-check-status-cell
                                        v-if="!!site_web_monitor(row).active"
                                        :monitor="site_web_monitor(row)"
                                        class="mt-2"
                                    />
                                    <monitor-check-status-cell
                                        v-if="!!site_domain_monitor(row).active"
                                        :monitor="site_domain_monitor(row)"
                                        class="mt-2"
                                    />
                                    <monitor-check-status-cell
                                        v-if="!!site_ssl_monitor(row).active"
                                        :monitor="site_ssl_monitor(row)"
                                        class="mt-2"
                                    />
                                </template>
                                <template
                                    v-slot:tbody="{ row, field }"
                                >
                                    <div v-if="field === 'name'">
                                        {{ row.name }}
                                        <div class="text-gray text-small">
                                            <a
                                                :href="row.url"
                                                target="_blank"
                                                @click.stop
                                            >
                                                {{ row.domain }}
                                            </a><span v-if="!!row.ip_address"> ({{ row.ip_address }})</span>
                                        </div>
                                        <div
                                            v-if="!!row.plan_names && !!row.plan_names.length && (row.computed_value !== null)"
                                            class="text-gray text-small"
                                        >
                                            {{ row.plan_names.join(", ") }} ({{ row.computed_value | nibnut.currency("0,0.00", $root.translate("Free")) }})
                                        </div>
                                    </div>
                                    <monitor-check-status-cell
                                        v-else-if="field === 'last_web_check'"
                                        :monitor="site_web_monitor(row)"
                                        :compact="!!$mq.match(/^(xs|sm|md|lg|tl|xl|fred)$/i)"
                                    />
                                    <monitor-check-status-cell
                                        v-else-if="field === 'last_domain_check'"
                                        :monitor="site_domain_monitor(row)"
                                        :compact="!!$mq.match(/^(xs|sm|md|lg|tl|xl|fred)$/i)"
                                    />
                                    <monitor-check-status-cell
                                        v-else-if="field === 'last_ssl_check'"
                                        :monitor="site_ssl_monitor(row)"
                                        :compact="!!$mq.match(/^(xs|sm|md|lg|tl|xl|fred)$/i)"
                                    />
                                    <monitor-check-status-cell
                                        v-else-if="field === 'last_links_check'"
                                        :monitor="site_link_monitor(row)"
                                        :compact="!!$mq.match(/^(xs|sm|md|lg|tl|xl|fred)$/i)"
                                    />
                                    <span v-else>{{ row[field] }}</span>
                                </template>
                            </data-table>
                            <div
                                v-if="!!edited_record && !!edited_record.id && !!edited_record.computed_revenue"
                                class="columns mt-2"
                            >
                                <div class="column col-6 text-right">
                                    {{ edited_record.computed_revenue | nibnut.currency }}&nbsp;/&nbsp;{{ $root.translate("month") }}
                                </div>
                                <div class="column col-6 text-left">
                                    {{ (edited_record.computed_revenue * 12) | nibnut.currency }}&nbsp;/&nbsp;{{ $root.translate("year") }}

                                    <default-button
                                        v-if="!!edited_record && !!edited_record.id"
                                        :title="$root.translate('Refresh totals')"
                                        :disabled="refreshing_totals"
                                        flavor="link"
                                        size="sm"
                                        class="ml-4"
                                        @click.prevent="refresh_totals"
                                    >
                                        <open-icon
                                            glyph="sync"
                                            :spin="refreshing_totals"
                                        />
                                    </default-button>
                                </div>
                            </div>

                            <div v-if="!!edited_record && !!edited_record.id && !!edited_record.quickbooks_id" class="mt-4">
                                <expandable :borderless="true">
                                    <template v-slot:title>
                                        <h5>{{ $root.translate("Invoices") }}</h5>
                                    </template>
                                    <invoices
                                        :id="edited_record.quickbooks_id"
                                    />
                                </expandable>
                                <div class="columns mt-2">
                                    <div class="column col-6 text-right">
                                        {{ $root.translate("Total Paid") }}: {{ edited_record.computed_balance_paid | nibnut.currency("0,0.00") }}
                                    </div>
                                    <div class="column col-6 text-left">
                                        {{ $root.translate("Total Owed") }}: {{ edited_record.computed_balance_owed | nibnut.currency("0,0.00") }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="!!edited_record && !edited_record.id"
                class="text-center mt-4"
            >
                <default-button
                    :disabled="creating"
                    class="mr-2"
                    @click.prevent="$router.go(-1)"
                >
                    {{ $root.translate("Cancel") }}
                </default-button>
                <default-button
                    :disabled="creating"
                    :waiting="creating"
                    color="primary"
                    class="ml-2"
                    @click.prevent="create_record"
                >
                    {{ $root.translate("Save") }}
                </default-button>
            </div>
        </base-form>

        <new-site
            v-if="!!edited_record"
            :show.sync="adding_site"
            :client-id="edited_record.id"
        />

        <connect-dialog
            :show.sync="connecting"
            entity="client"
            :record="edited_record || {}"
            :service="connection_service"
        />

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            :waiting="deleting_record"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
        <alerts-dialog
            :show.sync="alerts_shown"
            :topic-type="alert_topic_type"
            :topic-id="alert_topic_id"
        />
    </page>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex"

import { is_record_editor_page, is_data_table_source } from "@/nibnut/mixins"
import { is_sites_list_data_source, alertable } from "@/custom/mixins"

import {
    BaseForm,
    FormInput,
    FormToggleInput,
    FormSelect,
    AddressInput,
    MailtoLink,
    BaseLink,
    FormEditor,
    FormDateInput,
    DefaultButton,
    OpenIcon
} from "../../nibnut/components"

import {
    MonitorCheckStatusCell,
    QuickbooksLogo,
    RemoteConnectionButton,
    Expandable
} from "@/custom/components"

import {
    NewSite
} from "@/custom/dialogs"

import Contacts from "./Contacts"
import Invoices from "./Invoices"
import Tickets from "./Tickets"
import ConnectDialog from "./ConnectDialog"

export default {
    name: "ClientEditor",
    mixins: [is_record_editor_page, is_data_table_source, is_sites_list_data_source, alertable],
    components: {
        BaseForm,
        FormInput,
        FormToggleInput,
        FormSelect,
        AddressInput,
        MailtoLink,
        BaseLink,
        FormEditor,
        FormDateInput,
        DefaultButton,
        OpenIcon,
        MonitorCheckStatusCell,
        QuickbooksLogo,
        RemoteConnectionButton,
        Expandable,
        NewSite,
        Contacts,
        Invoices,
        Tickets,
        ConnectDialog
    },
    methods: {
        post_load () {
            if(!this.edited_record.id) {
                this.$store.dispatch(
                    "FETCH_RECORD_SHELL",
                    { entity: "contact" }
                ).then(record => {
                    this.shell_contact = record
                }).catch(error => {
                    this.$error(error.message)
                })
            }
            const rows = this.rows
            this.set_state_values({
                total: rows.length,
                found: rows.length
            })
        },
        confirm_record_delete () {
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete {name}", { name: this.edited_record.computed_name }),
                    message: this.$root.translate("Do you really want to delete this client? All its sites, logs and data will also be deleted. There is no undo..."),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") this.record_delete()
            else this.done_confirming()
        },
        record_deleted (record) {
            this.$router.push({ name: "client.list" })
        },
        quickbox_toggle () {
            if(this.edited_record.quickbooks_id) this.save(null, "quickbooks_id")
            else this.quickbooks_connect()
        },
        quickbooks_url (path, search = null) {
            if(!this.app_context) return ""
            let url = `${this.app_context.settings.qbo_url}/${path}`
            if(search) url += `?${search}`
            return url
        },
        quickbooks_connect () {
            this.connection_service = "qbo"
            this.connecting = true
        },
        create_autosuggest_value (value, field) {
            this.save(value, field)
        },
        create_record () {
            const data = {
                ...this.edited_record
            }
            if(data.joined_on && this.$moment.isMoment(data.joined_on)) data.joined_on = data.joined_on.format("YYYY-MM-DD")
            if(data.last_contacted_on && this.$moment.isMoment(data.last_contacted_on)) data.last_contacted_on = data.last_contacted_on.format("YYYY-MM-DD")
            if(data.next_contact_on && this.$moment.isMoment(data.next_contact_on)) data.next_contact_on = data.next_contact_on.format("YYYY-MM-DD")
            this.creating = true
            this.$store.dispatch("CREATE_RECORD", {
                entity: this.entity,
                data
            }).then(client => {
                if(this.shell_contact && (!!this.shell_contact.first_name || !!this.shell_contact.last_name || !!this.shell_contact.position || !!this.shell_contact.email || !!this.shell_contact.phone)) {
                    this.$store.dispatch("CREATE_RECORD", {
                        entity: "contact",
                        data: {
                            ...this.shell_contact,
                            client_id: client.id
                        }
                    }).then(record => {
                        this.shell_contact = null
                        this.$router.replace({ name: "client.edit", params: { id: client.id } })
                    }).catch(this.receive_error).then(() => {
                        this.creating = false
                    })
                } else this.shell_contact = null
            }).catch(error => {
                this.receive_error(error)
                this.creating = false
            })
        },
        last_contact_today () {
            this.save(this.$moment().format("YYYY-MM-DD"), "last_contacted_on")
        },
        contact_client_in (months) {
            if(months) {
                const from_date = this.edited_record.last_contacted_on ? this.$moment(this.edited_record.last_contacted_on) : this.$moment({ hour: 0 })
                const contact_date = from_date.add(months, "months")
                this.save(this.$moment.max(contact_date, this.$moment({ hour: 0 })).format("YYYY-MM-DD"), "next_contact_on")
            }
        },
        refresh_totals () {
            this.refreshing_totals = true
            this.$store.dispatch(
                "RECORD_ACTION",
                {
                    entity: this.entity,
                    id: this.edited_record.id,
                    action: "recalculate"
                }
            ).catch(this.receive_error).then(() => {
                this.refreshing_totals = false
            })
        },
        save_gift_given (value, field) {
            if(!value) value = null
            else value = this.$moment().format("YYYY-MM-DD")
            this.save(value, field)
        },
        contact_connected (freescout_id) {
            if(!freescout_id) {
                this.entity_records("ticket").filter(ticket => ticket.computed_client_id === this.edited_record.id).forEach(ticket => {
                    this.$store.dispatch("SWEEP", { entity: "ticket", id: ticket.id })
                })
            }
            this.tickets_version += 1
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        raw_sites () {
            return this.entity_records("site")
        },
        rows () {
            if(!this.edited_record || !this.edited_record.id) return []
            return this.raw_sites.filter(site => site.client_id === this.edited_record.id)
        },
        client_types () {
            return Object.values(this.constants("client_types"))
        },
        client_contact_delays () {
            return [1, 3, 6, 9, 12]
        },
        address () {
            return {
                line1: this.edited_record ? this.edited_record.line1 : "",
                line2: this.edited_record ? this.edited_record.line2 : "",
                city: this.edited_record ? this.edited_record.city : "",
                state: this.edited_record ? this.edited_record.state : "",
                country: this.edited_record ? this.edited_record.country : "",
                zip: this.edited_record ? this.edited_record.zip : "",
                longitude: this.edited_record ? this.edited_record.longitude : "",
                latitude: this.edited_record ? this.edited_record.latitude : ""
            }
        },
        alert_topic_type () {
            return "App\\Client"
        },
        alert_topic_id () {
            return this.edited_record ? this.edited_record.id : 0
        }
    },
    data () {
        return {
            entity: "client",
            entityName: "Client:::Clients",
            relation_ids: ["contact", "site", "monitor", "plan", "ticket"],

            columns: {
                name: { label: "Name", sort: "asc", type: "alpha" },
                last_web_check: { label: "Web", sort: null, type: "numeric" },
                last_domain_check: { label: "Domain", sort: null, type: "numeric" },
                last_ssl_check: { label: "SSL", sort: null, type: "numeric" },
                last_links_check: { label: "Link", sort: null, type: "numeric" }
            },
            shell_contact: null,

            adding_site: false,

            connecting: false,
            connection_service: "",

            creating: false,
            refreshing_totals: false,

            tickets_version: 0
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#client-editor {
    .btn.btn-link.quickbooks-button,
    .btn.btn-link.freescout-button,
    .btn.btn-link.contacts-plus-button {
        &, &:hover, &:visited, &:active {
            border: 0;
        }
    }
    .btn.btn-link.freescout-unlink-button,
    .btn.btn-link.quickbooks-unlink-button {
        .las {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .referred-by-container {
        display: flex;

        & > .referred-by {
            flex: 1 0 auto;
            margin-right: $unit-1;
        }
        & > .sb {
            flex: 0 0 auto;
            margin-left: $unit-1;
            text-align: center;

            .form-checkbox > i {
                left: 50%;
                transform: translate(-50%, 0);
            }
        }
    }
    .card.full-height {
        height: 100%;
    }
    .data-table-controls {
        white-space: nowrap;
        h5 {
            margin: 0;
        }
    }
}
</style>
