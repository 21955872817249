<template>
    <div v-if="!!edited_record" class="columns">
        <div class="column col-6 col-md-12 mb-2">
            <form-editor
                id="maintenance_report_email_message"
                name="maintenance_report_email_message"
                v-model="edited_record.maintenance_report_email_message"
                size="lg"
                :required="false"
                :full-height="false"
                :saving="saving('maintenance_report_email_message')"
                :error="has_error('maintenance_report_email_message')"
                @input="save"
            >
                <template v-slot:label>{{ $root.translate("Email Message") }}</template>
            </form-editor>

            <form-editor
                id="no_ga_connection"
                name="no_ga_connection"
                v-model="edited_record.no_ga_connection"
                size="lg"
                :required="false"
                :full-height="false"
                :saving="saving('no_ga_connection')"
                :error="has_error('no_ga_connection')"
                @input="save"
            >
                <template v-slot:label>{{ $root.translate("No GA Connection") }}</template>
            </form-editor>

            <form-editor
                id="no_ga_connection_whitelabel"
                name="no_ga_connection_whitelabel"
                v-model="edited_record.no_ga_connection_whitelabel"
                size="lg"
                :required="false"
                :full-height="false"
                :saving="saving('no_ga_connection_whitelabel')"
                :error="has_error('no_ga_connection_whitelabel')"
                @input="save"
            >
                <template v-slot:label>{{ $root.translate("No GA Connection (White Label Reports)") }}</template>
            </form-editor>
        </div>
        <div class="column col-6 col-md-12 mb-2">
            <table class="table">
                <thead>
                    <tr>
                        <td colspan="2">{{ $root.translate("Maintenance Tasks") }}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(task, index) in edited_record.maintenance_report_tasks"
                        :key="index"
                    >
                        <td class="col-12">
                            <form-input
                                :id="`task-${index}`"
                                name="maintenance_report_tasks"
                                :value="task"
                                :required="false"
                                @input="save_row(index, $event)"
                            />
                        </td>
                        <td class="text-right">
                            <default-button
                                flavor="link"
                                color="error"
                                size="sm"
                                @click.prevent="confirm_row_delete(index)"
                            >
                                <open-icon glyph="trash" />
                            </default-button>
                        </td>
                    </tr>
                    <tr>
                        <td class="col-12">
                            <form-input
                                id="task-new"
                                name="maintenance_report_tasks"
                                :value="new_task"
                                :placeholder="$root.translate('Add a new task...')"
                                :required="false"
                                @input="new_task=$event"
                            />
                        </td>
                        <td class="no-wrap">
                            <default-button
                                flavor="link"
                                size="sm"
                                class="mr-1"
                                @click.prevent="reset_new_task"
                            >
                                <open-icon glyph="times" />
                            </default-button>
                            <default-button
                                flavor="link"
                                color="success"
                                size="sm"
                                class="ml-1"
                                @click.prevent="add_row"
                            >
                                <open-icon glyph="check" />
                            </default-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import is_settings_panel from "./IsSettingsPanel"

import { FormInput, FormEditor, DefaultButton, OpenIcon } from "../../nibnut/components"

export default {
    name: "Maintenance",
    mixins: [is_settings_panel],
    components: {
        FormInput,
        FormEditor,
        DefaultButton,
        OpenIcon
    },
    methods: {
        save_row (index, value) {
            const maintenance_report_tasks = [...this.edited_record.maintenance_report_tasks]
            if(index < 0) maintenance_report_tasks.push(value)
            else maintenance_report_tasks[index] = value
            return this.save(maintenance_report_tasks, "maintenance_report_tasks").then(() => {
                this.new_task = ""
            })
        },
        add_row () {
            this.save_row(-1, this.new_task)
        },
        reset_new_task () {
            this.new_task = ""
        },
        confirm_row_delete (index) {
            this.task_index = index
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete the value"),
                    message: this.$root.translate("Do you really want to delete \"{value}\"?", { value: this.edited_record.maintenance_report_tasks[index] }),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-row"
            )
        },
        confirmed () {
            if(this.confirming === "delete-row") {
                this.save_row(this.task_index, "").then(response => {
                    this.task_index = -1
                    this.done_confirming()
                })
            } else this.done_confirming()
        }
    },
    data () {
        return {
            task_index: -1,
            new_task: ""
        }
    }
}
</script>
