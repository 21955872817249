import { mapState } from "vuex"

import profile_utilities from "@/nibnut/mixins/ProfileUtilities"

const handles_freescout_folders = {
    mixins: [profile_utilities],
    methods: {
        ticket_folder_by_id (id) {
            if(!id) return {}
            return this.freescout_folders.find(folder => folder.id === id) || {}
        },
        ticket_folders_by_freescout_user_id (freescout_user_id = 0) {
            if(!freescout_user_id) freescout_user_id = this.profile.freescout_id
            return this.freescout_folders.filter(folder => !folder.userId || (folder.userId === freescout_user_id))
        },
        tickets_count_for_folders (folders, freescout_field) {
            if(!Array.isArray(folders)) return folders[freescout_field] || 0
            return folders.reduce((value, folder) => value + folder[freescout_field], 0)
        },
        active_tickets_count_for_folders (folders) {
            return this.tickets_count_for_folders(folders, "activeCount")
        }
    },
    computed: {
        ...mapState(["freescout_folders"]),
        ticket_folders () {
            return this.ticket_folders_by_freescout_user_id()
        },
        opened_ticket_folders () {
            return this.ticket_folders_by_freescout_user_id().filter(folder => folder.name.match(/^(unassigned|mine|starred|drafts?)$/i))
        },
        active_tickets_count () {
            return this.active_tickets_count_for_folders(this.opened_ticket_folders, "activeCount")
        }
    }
}

export default handles_freescout_folders
