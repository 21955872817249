<template>
    <div class="btn-feedback">
        <default-button
            v-bind="button_props"
            :block="true"
            :disabled="is_disabled"
            v-on="$listeners"
        >
            <span
                v-if="status !== null"
                :class="{ 'btn-success': (status === true), 'btn-error': (status === false) }"
                class="status-indicator"
            >
                <open-icon v-if="status === true" glyph="check-circle" />
                <open-icon v-else glyph="times" />
            </span>
            <span :class="{ 'd-invisible': (status !== null) }"><slot></slot></span>
        </default-button>
        <slot v-if="status !== null" name="message"></slot>
    </div>
</template>

<script>
import DefaultButton from "./DefaultButton"
import OpenIcon from "../OpenIcon"

let timer = null

export default {
    name: "FeedbackButton",
    components: {
        DefaultButton,
        OpenIcon
    },
    watch: {
        status: "reset_timer"
    },
    methods: {
        reset_timer () {
            if(timer) {
                clearTimeout(timer)
                timer = null
            }
            if(!!this.dismissAfter && (this.status !== null)) {
                timer = setTimeout(() => {
                    timer = null
                    this.$emit("dismiss")
                }, this.dismissAfter)
            }
        }
    },
    computed: {
        button_props () {
            const { status, dismissAfter, disabled, block, ...button_props } = this.$props
            return button_props
        },
        is_disabled () {
            return this.disabled || (this.singleUse && (this.status !== null))
        }
    },
    props: {
        status: {
            validator: prop => (prop === null) || (prop === false) || (prop === true),
            default: null
        },
        label: {
            type: String,
            validator: prop => !!prop
        },
        type: {
            type: String,
            default: "button"
        },
        flavor: {
            type: String,
            validator: prop => !prop || prop.match(/^(link|normal)$/i),
            default: "normal"
        },
        color: {
            type: String,
            validator: prop => !prop || prop.match(/^(primary|regular|error|warning|clear)$/i),
            default: "regular"
        },
        size: {
            type: String,
            validator: prop => !prop || prop.match(/^(xs|sm|md|lg)$/i),
            default: "md"
        },
        active: {
            type: Boolean,
            default: false
        },
        waiting: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        singleUse: {
            type: Boolean,
            default: false
        },
        dismissAfter: { // In milliseconds; 0 never dismisses
            type: Number,
            validator: prop => prop >= 0,
            default: 7000
        }
    }
}
</script>

<style lang="scss">
.btn-feedback {
    .status-indicator {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
