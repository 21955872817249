<template>
    <page
        id="report-broken-links"
        :title="title"
        :status="page_status"
        :waiting="waiting"
        class="public-page-content"
        @statused="statused"
    >
        <template v-slot:title>
            <h1>{{ title }}</h1>
            <h3>
                <a
                    :href="site.url"
                >
                    {{ site.domain }}
                </a>
            </h3>
            <h3 class="text-small">
                {{ monitor_log.checked_at | nibnut.date("YYYY-MM-DD HH:mm:ss") }}<span v-if="!!site.ip_address">&nbsp;&bull;&nbsp;{{ site.ip_address }}</span>
            </h3>
        </template>

        <div
            v-for="section in sections"
            :key="section.id"
        >
            <h4>
                <open-icon :glyph="section.glyph" /> {{ section.title }}
                <base-link
                    :href="{ name: 'report.fix-broken-links', params: { slug: edited_record.slug, id: monitor_log.id } }"
                    class="btn btn-link btn-sm ml-4 float-right"
                >
                    {{ $root.translate("Please fix them for me") }}
                </base-link>
            </h4>
            <p class="text-gray">{{ section.description }}</p>
            <dl
                v-for="url in section.pages"
                :key="url"
            >
                <dt>
                    {{ $root.translate("On the page") }}
                    <base-link
                        :href="`${url}?_wpe-links-report=${edited_record.slug}`"
                        target="_blank"
                    >
                        {{ url }}
                    </base-link>
                    ...
                </dt>
                <dd>
                    {{ section.found_text }}:
                    <ul>
                        <li
                            v-for="link in section.items.data[url]"
                            :key="link.xpath || link.to"
                        >
                            <base-link
                                v-if="section.id === 'links'"
                                :href="link.to"
                                target="_blank"
                            >
                                {{ link.to }}
                            </base-link>
                            <span v-else>
                                {{ link.to }}
                            </span>
                        </li>
                    </ul>
                </dd>
            </dl>
        </div>
    </page>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex"

import { is_record_editor_page } from "@/nibnut/mixins"

import {
    BaseLink,
    OpenIcon
} from "@/nibnut/components"

export default {
    name: "ReportBrokenLinks",
    mixins: [is_record_editor_page],
    components: {
        BaseLink,
        OpenIcon
    },
    methods: {
        grab_record_id () {
            this.record_id = this.$route.params.slug
        },
        statused (status) {
            this.page_status = status
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        site () {
            const edited_record = this.edited_record
            if(!edited_record || !edited_record.site_id) return {}
            return this.entity_record("site", edited_record.site_id)
        },
        monitor_log () {
            const monitor_log_id = parseInt(this.$route.params.id)
            if(!monitor_log_id) return {}
            return this.entity_records("monitor_log").find(monitor_log => {
                return (monitor_log.id === monitor_log_id)
            }) || {}
        },
        title () {
            const site = this.site
            if(!site || !site.name) return this.$root.translate("Broken links and missing images")
            return this.$root.translate("Broken links and missing images for {name}", { name: this.site.name })
        },
        reload_query () {
            return {
                monitor_log_id: parseInt(this.$route.params.id),
                relation_ids: this.relation_ids,
                fields: ["fieldset::broken-links", "ns::site;fieldset::broken-links", "ns::monitor_log;fieldset::broken-links"]
            }
        },
        edited_record () {
            if(this.shell_record) return this.shell_record
            if(!this.entity || !this.record_id) return null
            return this.entity_records(this.entity).find(report => report.slug === this.record_id) || {}
        },
        broken_links () {
            const monitor_log = this.monitor_log
            if(!!monitor_log && !!monitor_log.diff_report) {
                return monitor_log.diff_report.links || {}
            }
            return null
        },
        broken_link_pages () {
            const report = this.broken_links
            if(report) return Object.keys(report.data)
            return []
        },
        broken_images () {
            const monitor_log = this.monitor_log
            if(!!monitor_log && !!monitor_log.diff_report) {
                return monitor_log.diff_report.images || {}
            }
            return null
        },
        broken_image_pages () {
            const report = this.broken_images
            if(report) return Object.keys(report.data)
            return []
        },
        sections () {
            const sections = []
            let pages = this.broken_link_pages
            if(pages.length) sections.push({ id: "links", glyph: "link", title: this.$root.translate("Broken Links"), items: this.broken_links, pages, description: this.$root.translate("The following pages on your site contain links that are broken. (point to an inexistant page) It is strongly suggested you fix or remove these links."), found_text: this.$root.translate("...we find broken links that point to") })
            pages = this.broken_image_pages
            if(pages.length) sections.push({ id: "images", glyph: "image", title: this.$root.translate("Broken Images"), items: this.broken_images, pages, description: this.$root.translate("The following pages on your site contain broken or missing images. It is strongly suggested you fix or remove them."), found_text: this.$root.translate("...we find the following missing images") })
            return sections
        }
    },
    data () {
        return {
            entity: "monitor",
            entityName: "Broken Link:::Broken Links",
            relation_ids: ["monitor_log", "site"]
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#report-broken-links {
}
</style>
