<template>
    <div
        :class="{ [size]: true, [color]: true }"
        class="ball-pulse-sync loader"
    >
        <div
            v-for="index in 3"
            :key="index"
        ></div>
    </div>
</template>

<script type="text/javascript">
/*
https://codepen.io/kjbrum/pen/BraCg
https://connoratherton.com/loaders
http://anastawfeek.github.io/ArtWorx-xLoader/
https://github.danielcardoso.net/load-awesome/animations.html
*/
export default {
    name: "Loader",
    props: {
        size: {
            type: String,
            validator: prop => !prop || !!prop.match(/^(xs|sm|md|lg)$/),
            default: "md"
        },
        color: {
            type: String,
            validator: prop => !prop || !!prop.match(/^(gray|light|primary)$/),
            default: "primary"
        }
    }
}
</script>

<style lang="scss">
@use 'sass:math';

@import "../../assets/sass/variables";
@import "~loaders.css/src/animations/ball-pulse-sync";

$ball-size: 15px;
.loader {
    width: auto;
    white-space: nowrap;

    & > div {
        width: $ball-size;
        height: $ball-size;
        margin: $unit-2;
    }

    &.xs {
        & > div {
            width: math.div($ball-size, 4);
            height: math.div($ball-size, 4);
            margin: math.div($unit-1, 2);
        }
    }
    &.sm {
        & > div {
            width: math.div($ball-size, 2);
            height: math.div($ball-size, 2);
            margin: $unit-1;
        }
    }
    &.lg {
        & > div {
            width: $ball-size * 2;
            height: $ball-size * 2;
            margin: $unit-4;
        }
    }

    &.light {
        & > div {
            background-color: $light-color;
        }
    }
    &.gray {
        & > div {
            background-color: $gray-color;
        }
    }
}
@keyframes ball-pulse-sync {
  33% {
    transform: translateY(50%);
  }
  66% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
