<template>
    <div v-if="!!contact">
        <div class="columns">
            <div class="column col-6 col-sm-12">
                <form-input
                    id="first_name"
                    name="first_name"
                    v-model="contact.first_name"
                    :required="false"
                    :saving="saving('first_name')"
                    :error="has_error('first_name')"
                    @input="save"
                >
                    <template v-slot:label>{{ $root.translate("First Name") }}</template>
                </form-input>
            </div>
            <div class="column col-6 col-sm-12">
                <div class="d-flex">
                    <form-input
                        id="last_name"
                        name="last_name"
                        v-model="contact.last_name"
                        :required="false"
                        :saving="saving('last_name')"
                        :error="has_error('last_name')"
                        class="col-12"
                        @input="save"
                    >
                        <template v-slot:label>{{ $root.translate("Last Name") }}</template>
                    </form-input>
                </div>
            </div>
        </div>
        <form-input
            id="position"
            name="position"
            v-model="contact.position"
            :required="false"
            :saving="saving('position')"
            :error="has_error('position')"
            @input="save"
        >
            <template v-slot:label>{{ $root.translate("Position") }}</template>
        </form-input>

        <div class="columns">
            <div class="column col-6 col-sm-12">
                <form-input
                    id="email"
                    name="email"
                    type="email"
                    v-model="contact.email"
                    :required="false"
                    :saving="saving('email')"
                    :error="has_error('email')"
                    @input="save"
                >
                    <template v-slot:label>{{ $root.translate("Email") }}</template>
                </form-input>
            </div>
            <div class="column col-6 col-sm-12">
                <form-input
                    id="phone"
                    name="phone"
                    type="tel"
                    v-model="contact.phone"
                    :required="false"
                    :saving="saving('phone')"
                    :error="has_error('phone')"
                    @input="save"
                >
                    <template v-slot:label>{{ $root.translate("Phone") }}</template>
                </form-input>
            </div>
            <div class="column col-12">
                <form-editor
                    id="notes"
                    name="notes"
                    v-model="contact.notes"
                    size="lg"
                    :required="false"
                    :full-height="false"
                    :saving="saving('notes')"
                    :error="has_error('notes')"
                    @input="save"
                >
                    <template v-slot:label>{{ $root.translate("Notes") }}</template>
                </form-editor>
            </div>
        </div>
    </div>
</template>

<script>
import { handles_saving } from "@/nibnut/mixins"

import { FormInput, FormEditor } from "@/nibnut/components"

export default {
    name: "ContactEditor",
    mixins: [handles_saving],
    components: {
        FormInput,
        FormEditor
    },
    watch: {
        "contact.id": "grab_record_id",
        creationErrors: "creation_errors"
    },
    methods: {
        creation_errors () {
            this.errors = this.creationErrors
        },
        save (value, field) {
            if(this.contact) {
                if(this.contact[field] !== value) this.contact[field] = value
                if(this.contact.id) return this.save_field_for_record_id(this.entity, this.contact.id, this.contact[field], field)
            }
            return Promise.resolve()
        }
    },
    props: {
        contact: {
            type: Object,
            required: true
        },
        creationErrors: {
            type: Object,
            default () {
                return {}
            }
        }
    },
    data () {
        return {
            entity: "contact"
        }
    }
}
</script>
