<template>
    <default-button
        v-bind="button_props"
        v-on="$listeners"
        class="s-circle btn-stats"
    >
        {{ count | nibnut.number("0,0") }}
        <open-icon
            v-if="!!glyph"
            :glyph="glyph"
        />
    </default-button>
</template>

<script>
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "StatButton",
    components: {
        DefaultButton,
        OpenIcon
    },
    computed: {
        button_props () {
            const { count, ...button_props } = this.$props
            return button_props
        },
        is_disabled () {
            return this.disabled || (this.singleUse && (this.status !== null))
        }
    },
    props: {
        count: {
            type: Number,
            default: 0
        },
        label: {
            type: String,
            validator: prop => !!prop
        },
        color: {
            type: String,
            validator: prop => !prop || prop.match(/^(error|warning|primary|secondary|tertiary)$/i),
            default: "error"
        },
        glyph: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.btn.btn-stats {
    position: relative;
    padding: 0 0.3rem;
    font-size: 1rem;
    min-width: 26px;
    height: auto;
    vertical-align: baseline;

    i.las {
        position: absolute;
        bottom: -6px;
        right: -6px;
        background-color: $light-color;
        // border: 1px solid $primary-color;
        color: $primary-color;
        border-radius: 50%;
        font-size: 12px;
    }

    &.btn-error {
        i.las {
            // border-color: $error-color;
            color: $error-color;
        }
    }
    &.btn-warning {
        i.las {
            // border-color: $warning-color;
            color: $warning-color;
        }
    }
}
h1 {
    .btn.btn-stats {
        vertical-align: middle;
    }
}
</style>
