<template>
    <div>
        <data-table
            :id="state_identifier"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :show-head="false"
            @sort="sort_by"
            @search="search"
            @clear-search="clear_search"
            @page="goto_page"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6>{{ row.name }}</h6>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div v-if="field === '_buttons'" class="text-right">
                    <default-button
                        flavor="link"
                        color="error"
                        size="sm"
                        @click.prevent="confirm_row_delete(row)"
                        class="mr-2"
                    >
                        <open-icon glyph="trash" />
                    </default-button>
                    <default-button
                        flavor="link"
                        size="sm"
                        @click.prevent="row_edit(row)"
                    >
                        <open-icon glyph="pen" />
                    </default-button>
                </div>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>

        <modal-dialog
            id="choice-list-item-editor"
            :show.sync="autosuggest_editing"
        >
            <template v-slot:title>
                <span class="h5">{{ $root.translate("Change autosuggest value \"{value}\"", { value: action_row.name }) }}</span>
            </template>

            <p>{{ $root.translate("Change a value, in bulk, to another value. (Fix typos, rename an item, ...)") }}</p>
            <p class="text-small mb-8">{{ $root.translate("If you need to merge two values together, simply change one of them to the other's value. (i.e. to merge 'GoDaddy' and 'Go Daddy' so every record now uses 'GoDaddy', edit 'Go Daddy' and change it to 'GoDaddy')") }}</p>

            <form-input
                id="autosuggest_value"
                name="autosuggest_value"
                v-model="autosuggest_value"
                :required="true"
            >
                <template v-slot:label>{{ $root.translate("Change \"{value}\" to:", { value: action_row.name }) }}</template>
            </form-input>

            <form-input
                v-if="tableName !== 'client_categories'"
                id="autosuggest_url"
                name="autosuggest_url"
                v-model="autosuggest_url"
                :required="false"
            >
                <template v-slot:label>{{ $root.translate("URL") }}</template>
            </form-input>
            <div
                v-if="tableName !== 'client_categories'"
                class="columns"
            >
                <form-input
                    id="autosuggest_default_cost"
                    type="stripe"
                    :min="0"
                    name="autosuggest_default_cost"
                    v-model="autosuggest_default_cost"
                    :required="false"
                    class="column"
                >
                    <template v-slot:label>{{ $root.translate("Default Cost") }}</template>
                    <template v-slot:right_addon><span class="input-group-addon">{{ translate("/month") }}</span></template>
                </form-input>
                <form-toggle-input
                    id="autosuggest_has_notes"
                    name="autosuggest_has_notes"
                    type="checkbox"
                    v-model="autosuggest_has_notes"
                    :horizontal="true"
                    :required="false"
                    class="column"
                >
                    {{ translate("Allows Notes") }}
                </form-toggle-input>
            </div>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        class="mx-2"
                        @click.prevent="row_edit_stop(false)"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        color="primary"
                        class="mx-2"
                        @click.prevent="row_edit_stop(true)"
                    >
                        {{ $root.translate("Save") }}
                    </default-button>
                    <p class="text-small text-error mt-2">{{ $root.translate("NOTE: there is no 'undo'. Double-check your value before saving.") }}</p>
                </div>
            </template>
        </modal-dialog>
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import { is_data_table_source, confirms } from "@/nibnut/mixins"

import {
    ModalDialog,
    FormInput,
    FormToggleInput,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"

export default {
    mixins: [is_data_table_source, confirms],
    components: {
        ModalDialog,
        FormInput,
        FormToggleInput,
        DefaultButton,
        OpenIcon
    },
    mounted () {
        if(this.can_refresh) this.reload()
    },
    methods: {
        refresh () {
            this.set_state_values({
                total: this.rows.length,
                found: this.filtered ? this.filtered_rows.length : this.rows.length
            })
        },
        set_rows (autosuggest_values) {
            this.autosuggest_values = autosuggest_values
            this.refresh()
        },
        reload () {
            this.$store.dispatch("RECORDS_ACTION", {
                entity: "setting",
                action: "autosuggest-list",
                data: { field: `${this.tableName}.term` },
                passthru: true
            }).then(response => {
                this.set_rows(response)
            })
        },
        filter_rows (rows) {
            return rows
        },
        confirm_row_delete (row) {
            this.action_row = row
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete the value \"{value}\"", { value: this.action_row.name }),
                    message: this.$root.translate("Do you really want to delete this value? Sites with sites who have this value as host, dns or registrar will have these fields reset to an empty value."),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-row"
            )
        },
        confirmed () {
            if(this.confirming === "delete-row") {
                this.$store.dispatch("RECORDS_ACTION", {
                    entity: "setting",
                    action: "autosuggest-list",
                    data: { field: `${this.tableName}.term`, value: this.action_row.id },
                    passthru: true,
                    method: "delete"
                }).then(response => {
                    this.set_rows(response)
                    this.action_row = {}
                    this.done_confirming()
                })
            } else this.done_confirming()
        },
        row_edit (row) {
            this.action_row = row
            this.autosuggest_value = this.action_row.id
            this.autosuggest_url = this.action_row.url
            this.autosuggest_default_cost = this.action_row.default_cost
            this.autosuggest_has_notes = !!this.action_row.has_notes
            this.autosuggest_editing = true
        },
        row_edit_stop (save) {
            const close = () => {
                this.autosuggest_editing = false
                this.action_row = {}
                this.autosuggest_value = ""
                this.autosuggest_url = ""
                this.autosuggest_default_cost = 0
                this.autosuggest_has_notes = false
            }
            if(save) {
                this.$store.dispatch("RECORDS_ACTION", {
                    entity: "setting",
                    action: "autosuggest-list",
                    data: { field: `${this.tableName}.term`, value: this.autosuggest_value, url: this.autosuggest_url, default_cost: this.autosuggest_default_cost, has_notes: this.autosuggest_has_notes, old_value: this.action_row.id },
                    passthru: true,
                    method: "post"
                }).then(response => {
                    this.set_rows(response)
                    close()
                })
            } else close()
        }
    },
    computed: {
        state_identifier () {
            return `autosuggest-items-list-${this.tableName}`
        },
        filtered () {
            return !!this.state.search
        },
        rows () {
            return this.autosuggest_values
        },
        can_refresh () {
            return !!this.profile_id
        }
    },
    props: {
        tableName: {
            type: String,
            default: "host_dns_registrar"
        }
    },
    data () {
        return {
            autosuggest_values: [],
            columns: {
                name: { label: "Item", sort: "asc", type: "alpha" },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            action_row: {},
            autosuggest_editing: false,
            autosuggest_value: "",
            autosuggest_url: "",
            autosuggest_default_cost: 0,
            autosuggest_has_notes: false
        }
    }
}
</script>
