<template>
    <div
        v-if="!!site"
        id="site-credits"
    >
        <table class="table summary">
            <tbody>
                <tr>
                    <th>
                        {{ $root.translate("Current Period") }} ({{ current_period | nibnut.date("MMM YYYY") }})
                    </th>
                    <td>
                        {{ $root.translate("{n} used", { n: site.credits_use_count }) }}&nbsp;/&nbsp;{{ $root.translate("{n} available", { n: site.credits_total_count }) }}
                    </td>
                </tr>
                <tr v-if="!!site.credits_carry_over_count">
                    <th>
                        {{ $root.translate("Carry Over") }} ({{ next_period | nibnut.date("MMM YYYY") }})
                    </th>
                    <td>
                        {{ $root.translate("{n} credit:::{n} credits", { n: site.credits_carry_over_count }, site.credits_carry_over_count) }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "SiteCredits",
    methods: {
    },
    computed: {
        standardized_date () {
            return this.date ? this.date : this.$moment()
        },
        current_period () {
            return this.$moment(this.standardized_date).startOf("month")
        },
        next_period () {
            return this.$moment(this.standardized_date).startOf("month").add(1, "month")
        }
    },
    props: {
        site: {
            type: Object,
            required: true
        },
        date: {
            type: String,
            default: null
        }
    },
    data () {
        return {
        }
    }
}
</script>

<style lang="scss">
</style>
