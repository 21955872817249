<template>
    <div class="input-group input-inline has-icon-left master-search">
        <open-icon
            glyph="search"
            size="1x"
            class="form-icon"
        />
        <ul
            v-if="showing"
            class="menu"
        >
            <li
                v-if="loading"
                class="menu-item text-center"
            >
                <loader size="xs" />
            </li>
            <li
                v-else-if="!results.length"
                :data-content="$root.translate('NO RESULTS')"
                class="divider text-center"
            ></li>
            <router-link
                v-for="(result, index) in results"
                :key="result.key"
                :to="{ name: `${result.entity}.edit`, params: { id: result.id } }"
                tag="li"
                class="menu-item"
                @click.native="reset"
            >
                <base-link
                    :href="{ name: `${result.entity}.edit`, params: { id: result.id } }"
                    :class="{ active: (active_choice_index === index) }"
                >
                    <open-icon :glyph="glyph(result)" class="mr-2" />
                    <span>{{ result.name }}</span>
                </base-link>
            </router-link>
        </ul>
        <base-input
            id="master_search"
            ref="field"
            type="text"
            name="master_search"
            :value="query"
            :placeholder="$root.translate('Press / to search')"
            :required="false"
            v-shortkey.focus="['/']"
            @focus="maybe_autoselect"
            @touchstart="touchstart"
            @keyup="keyup"
        />
    </div>
</template>

<script>
import debounce from "lodash/debounce"

import { is_alpha_numerical_input } from "@/nibnut/mixins"

import { BaseInput, OpenIcon, BaseLink } from "@/nibnut/components"
import { Loader } from "@/custom/components"

export default {
    name: "MasterSearchBox",
    mixins: [is_alpha_numerical_input],
    components: {
        BaseInput,
        OpenIcon,
        BaseLink,
        Loader
    },
    mounted () {
        document.addEventListener("click", this.auto_close)
    },
    beforeDestroy () {
        document.removeEventListener("click", this.auto_close)
    },
    methods: {
        maybe_autoselect (event) {
            event.target.select()
        },
        keyup (event) {
            if(this.alphanumeric && this.iOS) this.touching = false

            switch (event.key) {
            case "ArrowDown":
                if(this.showing && !!this.results.length) {
                    this.active_choice_index++
                    if(this.active_choice_index >= this.results.length) this.active_choice_index = 0
                }
                break

            case "ArrowUp":
                if(this.showing && !!this.results.length) {
                    this.active_choice_index--
                    if(this.active_choice_index < 0) this.active_choice_index = this.results.length - 1
                }
                break

            case "Enter":
                if(this.active_choice_index >= 0) {
                    const result = this.results[this.active_choice_index]
                    this.$router.push({ name: `${result.entity}.edit`, params: { id: result.id } })
                    this.reset()
                    this.$refs.field.$el.blur()
                }
                break

            case "Escape":
                this.reset()
                this.$refs.field.$el.blur()
                break

            default:
                this.active_choice_index = -1
                this.query = event.target.value
                this.search()
                break
            }
        },
        search: debounce(function () {
            if(this.showing) {
                this.loading = true
                this.$store.dispatch(
                    "RECORDS_ACTION",
                    {
                        entity: "app",
                        action: "search",
                        data: { search: this.query },
                        method: "get",
                        passthru: true
                    }
                ).then(results => {
                    this.results = results
                }).catch(error => {
                    this.$error(error.message)
                }).then(() => {
                    this.loading = false
                })
            } else if(!!this.results && !!this.results.length) this.results = []
        }, 250),
        glyph (result) {
            console.log(result.key)
            if(result.key.match(/^site/i)) return "globe-americas"
            if(result.key.match(/^license/i)) return "key"
            if(result.key.match(/^client/i)) return "address-card"
            if(result.key.match(/^contact/i)) return "id-badge"
            return "surprise"
        },
        reset () {
            this.query = ""
            this.results = []
        },
        auto_close (event) {
            if(!!event && !!event.target && !this.$el.contains(event.target)) {
                this.reset()
            }
        }
    },
    computed: {
        showing () {
            return !!this.query && (this.query.length >= 3)
        }
    },
    data () {
        return {
            query: "",
            loading: false,
            results: [],
            active_choice_index: -1
        }
    }
}
</script>

<style lang="scss">
.master-search {
    position: relative;

    .form-group:not(:last-child) {
        margin-bottom: 0;
    }
    .menu {
        position: absolute;
        right: 0;
        top: 100%;
        min-width: 100%;
        max-height: 50vh;
        overflow: auto;

        .menu-item {
            white-space: nowrap;
        }
    }
}
</style>
