<template>
    <expandable
        v-if="siteId"
        :expanded-remotely="expand"
    >
        <template v-slot:title>
            {{ $root.translate("Reports") }}
            <default-button
                :title="$root.translate('New Report')"
                flavor="link"
                @click.prevent.stop="create_report"
            >
                <open-icon glyph="plus" />
            </default-button>
        </template>

        <data-table
            id="reports-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :searchable="false"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="false"
            :show-head="false"
            @sort="sort_by"
            @page="goto_page"
            @click="view_report"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6>
                    {{ row.from_date | nibnut.date }} - {{ row.to_date | nibnut.date }}
                    <base-link
                        :id="`report-${row.id}`"
                        :href="`/report/${row.uuid}`"
                        target="report"
                        class="ml-4"
                        @click.native.stop
                    >
                        <open-icon glyph="external-link-alt" :title="$root.translate('View online')" />
                    </base-link>
                </h6>
                <default-button
                    :block="true"
                    @click.prevent="regenerate(row)"
                >
                    <open-icon glyph="sync" :title="$root.translate('Re-generate')" /> {{ $root.translate('Re-generate') }}
                </default-button>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div v-if="field === 'from_date'">
                    {{ row.from_date | nibnut.date }} - {{ row.to_date | nibnut.date }}
                    <base-link
                        :id="`report-${row.id}`"
                        :href="`/report/${row.uuid}`"
                        target="report"
                        class="ml-4"
                        @click.native.stop
                    >
                        <open-icon glyph="external-link-alt" :title="$root.translate('View online')" />
                    </base-link>
                    <default-button
                        size="sm"
                        class="ml-4"
                        @click.prevent.stop="regenerate(row)"
                    >
                        <open-icon glyph="sync" :spin="refreshing(row)" :title="$root.translate('Re-generate')" /> {{ $root.translate('Re-generate') }}
                    </default-button>
                    <default-button
                        size="sm"
                        class="ml-4"
                        @click.prevent.stop="resend(row)"
                    >
                        <open-icon glyph="retweet" :title="$root.translate('Re-send')" /> {{ $root.translate('Re-send') }}
                    </default-button>
                    <default-button
                        size="sm"
                        class="ml-8"
                        @click.prevent.stop="confirm_record_delete(row)"
                    >
                        <open-icon glyph="trash" :title="$root.translate('Delete')" /> {{ $root.translate('Delete') }}
                    </default-button>
                </div>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>

        <modal-dialog
            id="report-creator"
            :show.sync="adding"
            class="overflow-visible"
        >
            <template v-slot:title>
                <span class="h5">{{ translate("New Report Dates") }}</span>
            </template>

            <div class="columns">
                <form-date-input
                    id="from_date"
                    name="from_date"
                    v-model="from_date"
                    position="right"
                    :required="true"
                    :placeholder="$root.translate('From')"
                    class="column col-6"
                >
                    <template v-slot:label>{{ $root.translate("From") }}</template>
                </form-date-input>
                <form-date-input
                    id="to_date"
                    name="to_date"
                    v-model="to_date"
                    position="left"
                    :required="true"
                    :placeholder="$root.translate('To')"
                    class="column col-6"
                >
                    <template v-slot:label>{{ $root.translate("To") }}</template>
                </form-date-input>
            </div>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        class="mr-2"
                        @click.prevent="adding = false"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        color="primary"
                        class="ml-2"
                        @click.prevent="save_new_report"
                    >
                        {{ $root.translate("Create") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </expandable>
</template>

<script type="text/javascript">
import { is_remote_data_table_source, handles_saving, confirms } from "@/nibnut/mixins"

import { ModalDialog, BaseLink, FormDateInput, DefaultButton, OpenIcon } from "@/nibnut/components"
import { Expandable } from "@/custom/components"

let post_load_report = null

export default {
    name: "Logs",
    mixins: [is_remote_data_table_source, handles_saving, confirms],
    components: {
        ModalDialog,
        BaseLink,
        FormDateInput,
        DefaultButton,
        OpenIcon,
        Expandable
    },
    methods: {
        pre_load () {
            this.set_state_value("site_id", this.siteId)
        },
        post_load () {
            if(this.adding) {
                this.expand = true
                this.adding = false
                if(post_load_report) {
                    setTimeout(() => {
                        this.view_report(post_load_report)
                        post_load_report = null
                    }, 300)
                }
            }
        },
        view_report (row) {
            const node = document.getElementById(`report-${row.id}`)
            if(node) node.click()
        },
        regenerate (row) {
            this.refreshing_ids.push(row.id)
            this.$store.dispatch("RECORD_ACTION", {
                entity: this.entity,
                id: row.id,
                action: "refresh",
                method: "put"
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                const index = this.refreshing_ids.indexOf(row.id)
                if(index >= 0) this.refreshing_ids.splice(index, 1)
            })
        },
        resend (row) {
            this.$store.dispatch("RECORD_ACTION", {
                entity: this.entity,
                id: row.id,
                action: "resend",
                method: "put"
            }).then(() => {
                this.$success(this.$root.translate("Report sent!"))
            }).catch(error => {
                this.$error(error.message)
            })
        },
        refreshing (row) {
            return this.refreshing_ids.indexOf(row.id) >= 0
        },
        create_report () {
            this.from_date = this.$moment().startOf("month").subtract(1, "month")
            this.to_date = this.$moment().startOf("month").subtract(1, "day")
            this.adding = true
        },
        save_new_report () {
            this.save_data_for_record_id(this.entity, 0, {
                site_id: this.siteId,
                from_date: this.from_date ? this.from_date.format("YYYY-MM-DD") : null,
                to_date: this.to_date ? this.to_date.format("YYYY-MM-DD") : null
            }).then(report => {
                post_load_report = report
                this.refresh()
            })
        },
        confirm_record_delete (report) {
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete Report"),
                    message: this.$root.translate("Do you really want to delete the rport spawing {from} to {to}?", { from: report.from_date, to: report.to_date }),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete"),
                    report
                },
                "delete-report"
            )
        },
        confirmed () {
            if(this.confirming === "delete-report") {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: this.entity,
                        id: this.confirmation_props.report.id
                    }
                ).then(() => {
                    this.done_confirming()
                    this.refresh()
                }).catch(this.receive_error)
            } else this.done_confirming()
        }
    },
    computed: {
        state_identifier () {
            return "Reports"
        },
        fields () {
            return ["fieldset::list"]
        }
    },
    props: {
        siteId: {
            type: Number,
            validator: prop => prop >= 0,
            required: true
        }
    },
    data () {
        return {
            entity: "site_report",
            columns: {
                from_date: { label: "Period", sort: null, type: "numeric" }
            },
            default_state: {
                per_page: 3,
                page: 1,
                sort_by: "from_date",
                sort_dir: "desc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                site_id: 0
            },

            viewing: false,
            viewed_log: {},

            refreshing_ids: [],

            adding: false,
            from_date: null,
            to_date: null,
            expand: false
        }
    }
}
</script>
