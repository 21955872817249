<template>
    <div
        :class="{ clickable: is_clickable, 'card-complex': !!id_complex_value_fields }"
        class="card"
        @click.prevent.stop="click"
    >
        <div class="card-header">
            <div class="card-title h3">
                <loader v-if="waiting" size="sm" />
                <div v-else-if="!!id_complex_value_fields" class="columns align-items-center">
                    <div
                        v-for="field in id_complex_value_fields"
                        :key="field"
                        class="column"
                    >
                        <span v-if="id_is_number_value(field)">{{ value | nibnut.number("0,0") }}</span>
                        <span v-else>{{ id_value(field) | nibnut.currency }}</span>
                    </div>
                </div>
                <span v-else-if="is_number_value">{{ value | nibnut.number("0,0") }}</span>
                <span v-else>{{ value | nibnut.currency }}</span>
            </div>
            <div class="card-subtitle">
                <div v-if="!!id_complex_value_fields" class="columns align-items-center">
                    <div
                        v-for="field in id_complex_value_fields"
                        :key="field"
                        class="column"
                    >
                        <router-link
                            v-if="!!id_link_to(field)"
                            :id="linkId"
                            :to="id_link_to(field)"
                            @click.native.stop
                        >
                            {{ id_title(field) }}<span v-if="!!id_title_suffix(field)" class="text-small text-gray ml-1">{{ id_title_suffix(field) }}</span>
                        </router-link>
                        <div v-else>
                            {{ id_title(field) }}<span v-if="!!id_title_suffix(field)" class="text-small text-gray ml-1">{{ id_title_suffix(field) }}</span>
                            <div v-if="id_annual_revenue(field) !== null" class="text-small">{{ id_annual_revenue(field) | nibnut.currency }} / year</div>
                        </div>
                    </div>
                </div>
                <router-link
                    v-else-if="!!link_to"
                    :id="linkId"
                    :to="link_to"
                    @click.native.stop
                >
                    {{ title }}<span v-if="!!title_suffix" class="text-small text-gray ml-1">{{ title_suffix }}</span>
                </router-link>
                <span v-else>
                    {{ title }}<span v-if="!!title_suffix" class="text-small text-gray ml-1">{{ title_suffix }}</span>
                </span>
            </div>
            <div v-if="!waiting" class="text-small text-gray">
                <span v-if="id === 'total-clients'">{{ data['lifetime-value'] | nibnut.currency }} {{ translate("LTV") }}</span>
                <span v-else-if="id === 'total-projects'">{{ data['total-projects-value'] | nibnut.currency }} {{ translate("LTV") }}</span>
                <span v-else-if="id === 'total-active-projects-value'">{{ data['total-active-projects'] | nibnut.number("0,0") }} {{ translate("Active Projects") }}</span>
                <span v-else-if="id === 'total-license-cost'">{{ data[id] | nibnut.currency }} {{ translate("Total License Costs") }}</span>
                <span v-else-if="id === 'total-avg-revenue'">{{ (data['total-plan-revenue'] / data['TYPE_MAINTENANCE-plans']) * 12 | nibnut.currency }} / year</span>
                <span v-else-if="id === 'total-income'">{{ id_value(id) * 12 | nibnut.currency }} / year</span>
                <div v-else-if="id.match(/(total|TYPE_MAINTENANCE|TYPE_CONTENT_CREATION|TYPE_ACCESSIBILITY|TYPE_GG_BIZ_PROFILE|TYPE_ANALYTICS|TYPE_SEO)-plans/)">
                    <apexchart
                        v-if="!!chart_options && !!chart_data"
                        :id="id"
                        type="line"
                        :height="20"
                        :options="chart_options"
                        :series="chart_data"
                    />
                </div>
                <span v-else-if="id === 'total-sites'">{{ data['total-managed-domains'] | nibnut.number("0,0") }} domains &bull; {{ data['total-domain-annual-costs'] | nibnut.currency }} / year</span>
                <span v-else-if="id.match(/(total|TYPE_MAINTENANCE|TYPE_CONTENT_CREATION|TYPE_ACCESSIBILITY|TYPE_GG_BIZ_PROFILE|TYPE_ANALYTICS|TYPE_SEO)-plans/) || (id === 'total-sites') || (id === 'total-income') || (id === 'total-expense')" class="d-invisible">{{ id }}</span>
                <span v-else>{{ data[id] * 12 | nibnut.currency }} / year</span>
            </div>
        </div>
        <div class="card-footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
import { Loader } from "@/custom/components"
import VueApexCharts from "vue-apexcharts"

export default {
    name: "StatisticCard",
    components: {
        Loader,
        Apexchart: VueApexCharts
    },
    methods: {
        id_is_number_value (id) {
            return !!id.match(/(total|TYPE_MAINTENANCE|TYPE_CONTENT_CREATION|TYPE_ACCESSIBILITY|TYPE_GG_BIZ_PROFILE|TYPE_ANALYTICS|TYPE_SEO)-plans/) || !!id.match(/total-(clients|sites|projects)/)
        },
        id_value (id) {
            if(id === "total-license-cost") return this.data["total-license-monthly-cost"]
            if(id === "total-avg-revenue") return this.data["total-plan-revenue"] / this.data["total-plans"]
            if(id === "TYPE_MAINTENANCE-avg-revenue") return this.data["TYPE_MAINTENANCE-plan-revenue"] / this.data["TYPE_MAINTENANCE-plans"]
            if(id === "TYPE_CONTENT_CREATION-avg-revenue") return this.data["TYPE_CONTENT_CREATION-plan-revenue"] / this.data["TYPE_CONTENT_CREATION-plans"]
            if(id === "TYPE_ACCESSIBILITY-avg-revenue") return this.data["TYPE_ACCESSIBILITY-plan-revenue"] / this.data["TYPE_ACCESSIBILITY-plans"]
            if(id === "TYPE_GG_BIZ_PROFILE-avg-revenue") return this.data["TYPE_GG_BIZ_PROFILE-plan-revenue"] / this.data["TYPE_GG_BIZ_PROFILE-plans"]
            if(id === "TYPE_ANALYTICS-avg-revenue") return this.data["TYPE_ANALYTICS-plan-revenue"] / this.data["TYPE_ANALYTICS-plans"]
            if(id === "TYPE_SEO-avg-revenue") return this.data["TYPE_SEO-plan-revenue"] / this.data["TYPE_SEO-plans"]
            if(id === "total-income") return (this.data["total-plan-revenue"] + this.data["total-license-revenue"] + this.data["total-active-projects-value"])
            if(id === "total-expense") return (this.data["total-license-monthly-cost"] + this.data["total-hosting-costs"])
            return this.data[id]
        },
        id_link_to (id) {
            if(id === "total-clients") return { name: "client.list" }
            if(id === "total-sites") return { name: "site.list" }
            if(id.match(/total-license-(cost|revenue)/)) return { name: "license.list" }
            if((id === "total-projects") || (id === "total-active-projects-value")) return { name: "project.list" }
            return null
        },
        id_title (id) {
            if(id === "total-clients") return this.translate("Clients")
            if(id === "total-sites") return this.translate("Sites")
            if(id === "total-projects") return this.translate("12-months Projects")
            if(id === "total-license-cost") return this.translate("License Costs")
            if(id === "total-plans") return this.translate("All Plans")
            if(id === "TYPE_MAINTENANCE-plans") return this.translate("Maint.")
            if(id === "TYPE_CONTENT_CREATION-plans") return this.translate("Content")
            if(id === "TYPE_ACCESSIBILITY-plans") return this.translate("Accessibility")
            if(id === "TYPE_GG_BIZ_PROFILE-plans") return this.translate("Business Profile")
            if(id === "TYPE_ANALYTICS-plans") return this.translate("Analytics")
            if(id === "TYPE_SEO-plans") return this.translate("SEO")
            if(id.match(/(total|TYPE_MAINTENANCE|TYPE_CONTENT_CREATION|TYPE_ACCESSIBILITY|TYPE_GG_BIZ_PROFILE|TYPE_ANALYTICS|TYPE_SEO)-plan-revenue/)) return this.translate("Revenue")
            if(id.match(/(total|TYPE_MAINTENANCE|TYPE_CONTENT_CREATION|TYPE_ACCESSIBILITY|TYPE_GG_BIZ_PROFILE|TYPE_ANALYTICS|TYPE_SEO)-avg-revenue/)) return this.translate("Avg.")
            if(id === "total-license-revenue") return this.translate("License Revenue")
            if(id === "total-hosting-costs") return this.translate("Hosting Costs")
            if(id === "total-active-projects-value") return this.translate("Projects Revenue")
            if(id === "total-income") return this.translate("Total Revenue")
            if(id === "total-expense") return this.translate("Total Costs")
            return id
        },
        id_title_suffix (id) {
            if(id.match(/(total|TYPE_MAINTENANCE|TYPE_CONTENT_CREATION|TYPE_ACCESSIBILITY|TYPE_GG_BIZ_PROFILE|TYPE_ANALYTICS|TYPE_SEO)-avg-revenue/)) return "/ site"
            if(id === "total-plans") { // (id.match(/(total|TYPE_MAINTENANCE|TYPE_CONTENT_CREATION|TYPE_ACCESSIBILITY|TYPE_GG_BIZ_PROFILE|TYPE_ANALYTICS|TYPE_SEO)-plans/)) {
                const from = this.nibnut_filter("nibnut.date", [this.from, this.dateFormat])
                const to = this.nibnut_filter("nibnut.date", [this.to, this.dateFormat])
                return `(${from} - ${to})`
            }
            if(id.match(/(total|TYPE_MAINTENANCE|TYPE_CONTENT_CREATION|TYPE_ACCESSIBILITY|TYPE_GG_BIZ_PROFILE|TYPE_ANALYTICS|TYPE_SEO)-(plan|license)-revenue/) || (id === "total-hosting-costs") || (id === "total-active-projects-value") || (id === "total-income")) return "/ month"
            return null
        },
        id_annual_revenue (id) {
            if(id.match(/(total|TYPE_MAINTENANCE|TYPE_CONTENT_CREATION|TYPE_ACCESSIBILITY|TYPE_GG_BIZ_PROFILE|TYPE_ANALYTICS|TYPE_SEO)-(plan|license)-revenue/)) return this.id_value(id) * 12
            return null
        },
        click () {
            if(this.is_clickable) this.$emit("click", this.id, this.data, this.chart_options, this.chart_data)
        }
    },
    computed: {
        id_complex_value_fields () {
            if(this.id === "total-plans") return ["total-plans", "total-plan-revenue", "total-avg-revenue"]
            if(this.id === "TYPE_MAINTENANCE-plans") return ["TYPE_MAINTENANCE-plans", "TYPE_MAINTENANCE-plan-revenue", "TYPE_MAINTENANCE-avg-revenue"]
            if(this.id === "TYPE_CONTENT_CREATION-plans") return ["TYPE_CONTENT_CREATION-plans", "TYPE_CONTENT_CREATION-plan-revenue", "TYPE_CONTENT_CREATION-avg-revenue"]
            if(this.id === "TYPE_ACCESSIBILITY-plans") return ["TYPE_ACCESSIBILITY-plans", "TYPE_ACCESSIBILITY-plan-revenue", "TYPE_ACCESSIBILITY-avg-revenue"]
            if(this.id === "TYPE_GG_BIZ_PROFILE-plans") return ["TYPE_GG_BIZ_PROFILE-plans", "TYPE_GG_BIZ_PROFILE-plan-revenue", "TYPE_GG_BIZ_PROFILE-avg-revenue"]
            if(this.id === "TYPE_ANALYTICS-plans") return ["TYPE_ANALYTICS-plans", "TYPE_ANALYTICS-plan-revenue", "TYPE_ANALYTICS-avg-revenue"]
            if(this.id === "TYPE_SEO-plans") return ["TYPE_SEO-plans", "TYPE_SEO-plan-revenue", "TYPE_SEO-avg-revenue"]
            return null
        },
        is_clickable () {
            return ["total-plans", "TYPE_MAINTENANCE-plans", "TYPE_CONTENT_CREATION-plans", "TYPE_ACCESSIBILITY-plans", "TYPE_GG_BIZ_PROFILE-plans", "TYPE_ANALYTICS-plans", "TYPE_SEO-plans", "total-clients", "total-projects", "total-sites", "total-active-projects-value", "total-license-revenue", "total-license-cost"].indexOf(this.id) >= 0
        },
        is_number_value () {
            return this.id_is_number_value(this.id)
        },
        value () {
            return this.id_value(this.id)
        },
        link_to () {
            return this.id_link_to(this.id)
        },
        title () {
            return this.id_title(this.id)
        },
        title_suffix () {
            return this.id_title_suffix(this.id)
        },
        chart_options () {
            if(!this.id.match(/(total|TYPE_MAINTENANCE|TYPE_CONTENT_CREATION|TYPE_ACCESSIBILITY|TYPE_GG_BIZ_PROFILE|TYPE_ANALYTICS|TYPE_SEO)-plans/)) return null
            const categories = Object.keys(this.data["paid-plans"].total.data).map(date => this.$moment(date).format("MMM YYYY"))
            return {
                chart: {
                    id: this.id,
                    height: 20,
                    sparkline: {
                        enabled: true // !this.id.match(/-full$/)
                    },
                    toolbar: {
                        show: false
                    }
                },
                colors: ["#00bcb4", "#e85600", "#BD9600", "#6A46E8", "#A1464B"],
                stroke: {
                    curve: "smooth",
                    width: 1,
                    lineCap: "round"
                },
                xaxis: {
                    categories
                }
            }
        },
        chart_data () {
            if(!this.id.match(/(total|TYPE_MAINTENANCE|TYPE_CONTENT_CREATION|TYPE_ACCESSIBILITY|TYPE_GG_BIZ_PROFILE|TYPE_ANALYTICS|TYPE_SEO)-plans/)) return null
            const id = this.id.replace(/-plans$/, "")
            return [
                {
                    name: this.data["paid-plans"][id].title,
                    data: Object.values(this.data["paid-plans"][id].data)
                }
            ]
        }
    },
    props: {
        id: {
            type: String,
            required: true
        },
        linkId: {
            type: String,
            required: true
        },
        data: {
            type: Object,
            required: true
        },
        from: {
            required: true
        },
        to: {
            required: true
        },
        dateFormat: {
            type: String,
            default: "MM/YY"
        },
        waiting: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
.card.card-complex {
    .card-header {
        font-size: 0.8em;
        .h3 {
            font-size: clamp(1em, 0.8286em + 0.9524vw, 1.4em);
        }
    }
}
</style>
