<template>
    <div v-if="!!app_context" class="card">
        <div class="card-header">
            <div class="card-title h5">
                {{ $root.translate("Quickbooks") }}
                <span
                    v-if="!!app_context.settings.qbo_accessible"
                    class="ml-4 text-small text-success"
                >
                    <open-icon
                        glyph="check-circle"
                        class="mr-2"
                    />
                    {{ $root.translate("Connected") }}
                </span>
            </div>
        </div>
        <div class="card-body text-center">
            <div v-if="!app_context.settings.qbo_accessible">
                <ipp:connectToIntuit></ipp:connectToIntuit>
            </div>
            <div v-else>
                <slot>
                    <div class="form-group">
                        <default-button
                            :block="true"
                            :waiting="disconnecting"
                            color="error"
                            @click.prevent="disconnect"
                        >
                            {{ $root.translate("Disconnect") }}
                        </default-button>
                    </div>
                </slot>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import { mapState } from "vuex"

import is_external_script_holder from "./IsExternalScriptHolder"

import { DefaultButton, OpenIcon } from "@/nibnut/components"

export default {
    name: "QuickbooksConnector",
    mixins: [is_external_script_holder],
    components: {
        DefaultButton,
        OpenIcon
    },
    created () {
        this.inject_script(
            "intuit-ipp-anywhere",
            "https://appcenter.intuit.com/Content/IA/intuit.ipp.anywhere-1.3.3.js",
            this.bootstrap
        )
    },
    methods: {
        bootstrap () {
            window.intuit.ipp.anywhere.setup({
                grantUrl: `${document.location.protocol}//${document.location.hostname}/qbo`,
                datasources: {
                    quickbooks: true,
                    payments: false
                }
            })
        },
        disconnect () {
            this.disconnecting = true
            this.$store.dispatch("RECORD_ACTION", {
                entity: "setting",
                id: 1,
                action: "qbo-disconnect",
                method: "put"
            }).then(() => {
                this.$router.go()
            }).catch(() => {
                this.disconnecting = false
            })
        }
    },
    computed: {
        ...mapState(["app_context"])
    },
    data () {
        return {
            disconnecting: false
        }
    }
}
</script>
