<template>
    <page
        id="fix-broken-links"
        :title="title"
        :status="page_status"
        class="public-page-content"
        @statused="statused"
    >
        <template v-slot:title>
            <h1>{{ title }}</h1>
        </template>

        <div v-if="!!ticket" class="text-center">
            <h3>
                {{ $root.translate("Thank you! We've created ticket number {number}.", { number: ticket.number }) }}
            </h3>

            <p>
                {{ $root.translate("You should receive a confirmation soon, and we will work on your site as soon as possible.") }}
            </p>
        </div>
    </page>
</template>

<script type="text/javascript">
import { is_page, handles_errors, profile_utilities } from "@/nibnut/mixins"

export default {
    name: "FixBrokenLinks",
    mixins: [is_page, handles_errors, profile_utilities],
    mounted () {
        this.reload()
    },
    watch: {
        $route: "reload"
    },
    methods: {
        reload () {
            this.$store.dispatch(
                "RECORD_ACTION",
                {
                    entity: "monitor",
                    id: this.$route.params.slug,
                    action: "request-fix",
                    data: {
                        monitor_log_id: this.$route.params.id
                    },
                    passthru: true,
                    method: "put"
                }
            ).then(response => {
                this.ticket = response
            }).catch(this.receive_error)
        },
        statused (status) {
            this.page_status = status
        }
    },
    computed: {
        title () {
            if(!this.ticket) return this.$root.translate("Please repair my broken links and missing images")
            return this.$root.translate("Please repair the broken links and missing images on my site {name}", { name: this.ticket._site_name })
        }
    },
    data () {
        return {
            ticket: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#fix-broken-links {
}
</style>
