<template>
    <form-group
        v-bind="form_group_props"
        class="nibnut-address-input"
    >
        <template v-slot:read_only><slot name="read_only">{{ address_one_line(value) }}</slot></template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>

        <div
            :class="{ 'has-error': has_error(manual_override ? 'line1' : 'autocomplete') }"
            class="form-group"
        >
            <label
                :class="{ required }"
                class="form-label"
                :for="`${id}-line1`"
            >
                <small>{{ $root.translate('Street Address') }}</small>
                <sup v-if="required" class="ml-1">*</sup>
                <loader
                    v-if="geocoding || is_saving('line1')"
                    size="xs"
                    color="gray"
                    class="ml-2"
                />
            </label>

            <div
                class="input-group"
            >
                <base-input
                    v-show="!manual_override"
                    ref="autocomplete"
                    name="nibnut-autocomplete"
                    :value="autocomplete_address"
                    :editable="editable"
                    :required="required"
                    :auto-select="true"
                    autocomplete="nibnut-autocomplete"
                    @focus="clear_timeout"
                    @blur="maybe_reset_autocomplete"
                    class="form-input"
                />
                <base-input
                    v-show="!!manual_override"
                    name="line1"
                    :value="value.line1"
                    :editable="editable"
                    :required="required"
                    :auto-select="true"
                    @focus="clear_timeout"
                    @blur="geo_recode($event.target.value, 'line1')"
                    class="form-input"
                />
                <base-button
                    v-if="!!is_filled && !manual_override"
                    class="input-group-btn"
                    @click.prevent="clear_address"
                >
                    <open-icon glyph="times" :title="$root.translate('Clear address')" />
                </base-button>
                <base-button
                    v-if="!!is_filled && !manual_override"
                    class="input-group-btn"
                    @click.prevent="toggle_manual_override"
                >
                    <open-icon glyph="pencil-alt" :title="$root.translate('Manually edit address')" />
                </base-button>
                <base-button
                    v-else-if="!!manual_override"
                    class="input-group-btn"
                    @click.prevent="toggle_manual_override"
                >
                    <open-icon glyph="crosshairs" :title="$root.translate('Autosuggest address')" />
                </base-button>
            </div>

            <div
                v-if="has_error('line1') && !has_error('autocomplete')"
                :id="`${id}-line1-description`"
                aria-live="assertive"
                class="form-input-hint"
            >
                {{ has_error('line1') }}
            </div>
            <div
                v-else
                v-for="(error, field) in autocomplete_errors"
                :key="field"
                :id="`${id}-${field}-description`"
                aria-live="assertive"
                class="form-input-hint"
            >
                {{ error }}
            </div>
        </div>
        <a
            v-if="!!is_filled && !has_line2 && !value.line2"
            href
            class="text-small"
            @click.prevent="has_line2 = true"
        >
            <open-icon glyph="plus-circle" :title="$root.translate('Add address line 2')" class="mr-1" />{{ $root.translate('Add address line 2') }}
        </a>
        <form-input
            v-else-if="!!is_filled && (has_line2 || !!value.line2)"
            :id="`${id}-line2`"
            name="line2"
            v-model="value.line2"
            :editable="editable"
            :required="false"
            :error="has_error('line2')"
            class="mt-2"
            @input="$emit('input', value, name)"
        >
            <template slot="label">
                <small>{{ $root.translate("Address Line 2") }}</small>
            </template>
        </form-input>

        <div v-if="!!manual_override" class="columns">
            <div class="column col-12">
                <form-input
                    :id="`${id}-city`"
                    name="city"
                    v-model="value.city"
                    :editable="editable"
                    :required="false"
                    :error="has_error('city')"
                    @input="geo_recode"
                >
                    <template slot="label">
                        <small>{{ $root.translate("City") }}</small>
                    </template>
                </form-input>
            </div>
            <div class="column col-6 col-sm-12 mt-2">
                <region-selector
                    :id="`${id}-state`"
                    name="state"
                    v-model="value.state"
                    :country="value.country"
                    @input="geo_recode"
                />
            </div>
            <div class="column col-6 col-sm-12 mt-2">
                <form-input
                    :id="`${id}-zip`"
                    name="zip"
                    v-model="value.zip"
                    :editable="editable"
                    :required="false"
                    :error="has_error('zip')"
                    @input="geo_recode"
                >
                    <template slot="label">
                        <small v-if="!!value.country && !!value.country.match(/^(ca)$/i)">{{ $root.translate("Postal Code") }}</small>
                        <small v-else-if="!!value.country && !!value.country.match(/^(us)$/i)">{{ $root.translate("Zip Code") }}</small>
                        <small v-else>{{ $root.translate("Zip / Postal Code") }}</small>
                    </template>
                </form-input>
            </div>
        </div>
        <form-select
            :id="`${id}-country`"
            name="country"
            :value="value.country"
            :data-source="countries"
            :show-all="false"
            :ad-hoc="false"
            :required="required"
            :disabled="disabled"
            :saving="is_saving('country')"
            :error="has_error('country')"
            @input="change_country"
        >
            <template v-slot:label>
                <small>{{ $root.translate("Country") }}</small>
            </template>
        </form-select>
    </form-group>
</template>

<script>
import countries from "../../nibnut/components/Inputs/AddressInput/countries"

import is_nibnut_component from "../../nibnut/mixins/IsNibnutComponent"
import string_utilities from "../../nibnut/mixins/StringUtilities"

import FormGroup from "../../nibnut/components/Inputs/FormGroup"
import FormSelect from "../../nibnut/components/Inputs/FormSelect"
import FormInput from "../../nibnut/components/Inputs/FormInput"
import BaseInput from "../../nibnut/components/Inputs/BaseInput"
import BaseButton from "../../nibnut/components/Buttons/BaseButton"
import OpenIcon from "../../nibnut/components/OpenIcon"
import RegionSelector from "../../nibnut/components/Inputs/AddressInput/RegionSelector"
import Loader from "@/custom/components/Loader"

const place_component = (place, component_id) => {
    if(place.address_components) {
        const component = place.address_components.find((component) => {
            return component.types[0] === component_id
        })
        if(component) return component
    }
    return { short_name: "", long_name: "" }
}

export default {
    name: "AddressInput",
    mixins: [is_nibnut_component, string_utilities],
    components: {
        FormGroup,
        FormSelect,
        FormInput,
        BaseInput,
        BaseButton,
        OpenIcon,
        Loader,
        RegionSelector
    },
    mounted () {
        this.setup()
    },
    beforeDestroy () {
        if(window.google) {
            if(this.autocomplete_listener) {
                window.google.maps.event.removeListener(this.autocomplete_listener)
                this.autocomplete_listener = null
            }
            if(this.autocomplete) {
                window.google.maps.event.clearInstanceListeners(this.autocomplete)
                this.autocomplete = null
            }
        }
    },
    watch: {
        value: "setup",
        "value.country": "reset_options"
    },
    methods: {
        setup () {
            if(!window.google) console.error("AddressInput component expects Google API to be installed")
            else {
                if(this.value) {
                    let tries = 0
                    const setup = () => {
                        if(this.$refs.autocomplete.$el && !this.autocomplete) {
                            const leave_it_be_chrome = this.$refs.autocomplete.$el.getAttribute("autocomplete")
                            this.autocomplete = new window.google.maps.places.Autocomplete(
                                this.$refs.autocomplete.$el,
                                {
                                    types: ["geocode"],
                                    componentRestrictions: { country: [this.value.country || this.defaultCountry] }
                                }
                            )
                            setTimeout(() => {
                                this.$refs.autocomplete.$el.setAttribute("autocomplete", leave_it_be_chrome)
                            }, 200)
                            this.autocomplete_listener = this.autocomplete.addListener("place_changed", this.autocomplete_place_changed)
                        } else if(++tries <= 10) setTimeout(setup, 100)
                    }
                    setup()
                }
            }
        },
        reset_options () {
            if(this.autocomplete) {
                this.autocomplete.setComponentRestrictions({ country: [this.value.country || this.defaultCountry] })
            }
        },
        clear_timeout () {
            if(this.autocomplete_timer) {
                clearTimeout(this.autocomplete_timer)
                this.autocomplete_timer = null
            }
        },
        toggle_manual_override () {
            this.manual_override = !this.manual_override
        },
        maybe_reset_autocomplete (event) {
            // wait a small itsy bit -- if autocomplete not triggered, then reset value
            if(!this.autocomplete_timer) {
                this.autocomplete_timer = setTimeout(() => {
                    if(event.target.value !== this.autocomplete_address) event.target.value = this.autocomplete_address
                }, 250)
            }
        },
        place_to_address (place) {
            if(place) {
                if(!this.manual_override) {
                    this.value.line1 = place_component(place, "street_number").short_name + " " + place_component(place, "route").long_name
                    this.value.city = place_component(place, "locality").long_name || place_component(place, "sublocality_level_1").long_name
                    if((this.value.country === "US") || (this.value.country === "CA")) this.value.state = place_component(place, "administrative_area_level_1").short_name
                    else this.value.state = place_component(place, "administrative_area_level_1").long_name
                    this.value.country = place_component(place, "country").short_name || this.defaultCountry
                    this.value.zip = place_component(place, "postal_code").long_name
                }
                this.value.latitude = place.geometry.location.lat() || 0
                this.value.longitude = place.geometry.location.lng() || 0
            } else if(!this.manual_override) {
                this.value.line1 = ""
                this.value.city = ""
                this.value.state = ""
                this.value.country = this.defaultCountry
                this.value.zip = ""
                this.value.latitude = 0
                this.value.longitude = 0
            }
            this.$emit("input", this.value, this.name)
        },
        clear_address () {
            this.clear_timeout()
            this.place_to_address()
        },
        change_country (country) {
            if(country !== this.value.country) {
                this.value.city = ""
                this.value.state = ""
                this.value.zip = ""
                if(this.manual_override) this.geo_recode(country, "country")
                else {
                    this.value.country = country
                    this.$emit("input", this.value, this.name)
                }
            }
        },
        autocomplete_place_changed () {
            this.clear_timeout()
            this.place_to_address(this.autocomplete.getPlace())
        },
        geo_recode (value, name) {
            if(!this.manual_override) throw new Error("AddressInput geo_recode should not be called in manual override!")
            const proceed = () => {
                this.$emit("input", this.value, this.name)
                this.geocoding = false
            }

            if(name) this.value[name] = value
            if(typeof this.value.latitude !== "undefined") this.value.latitude = 0
            if(typeof this.value.longitude !== "undefined") this.value.longitude = 0

            if(!this.geocode) proceed()
            else {
                // The goal here is to try to figure out lat/long when user manually changes address fields
                // NOTE that the lat/long will be set correctly when the user picks a location form GG's places dropdown - and will NOT trigger this function
                // Basically, this is mainly for country changes, and in manual_override mode; as such, this should NOT change address values other than the lat/long!
                if(!this.geocoding) {
                    this.geocoding = true

                    const full_address = [
                        this.value.line1,
                        this.value.city,
                        this.value.state,
                        this.value.country,
                        this.value.zip
                    ].join(" ")

                    if(!this.geocoder && !!window.google) this.geocoder = new window.google.maps.Geocoder()
                    if(!this.geocoder) proceed()
                    else {
                        this.geocoder.geocode({ address: full_address }, (results, status) => {
                            if(status === "OK") {
                                const place = results[0]
                                this.value.latitude = place.geometry.location.lat() || 0
                                this.value.longitude = place.geometry.location.lng() || 0
                                proceed()
                            } else if(status === "ZERO_RESULTS") {
                                proceed()
                            }
                        })
                    }
                }
            }
        },
        is_saving (field) {
            return this.saving_field === field
        },
        has_error (error_id) {
            if(error_id && this.errors) {
                if(error_id === "autocomplete") return !!Object.keys(this.autocomplete_errors).length
                return this.errors[error_id]
            }
            return false
        }
    },
    computed: {
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        },
        is_filled () {
            return !!this.value && (this.value.line1 || this.value.city)
        },
        countries () {
            return countries
        },
        autocomplete_address () {
            if(!this.is_filled) return ""
            return this.address_one_line(this.value)
        },
        autocomplete_errors () {
            const errors = {}
            if(this.errors.line1) errors.line1 = this.errors.line1
            if(this.errors.city) errors.city = this.errors.city
            if(this.errors.state) errors.state = this.errors.state
            if(this.errors.zip) errors.zip = this.errors.zip
            return errors
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            type: Object,
            default () {
                return {}
            }
        },
        defaultCountry: {
            type: String,
            default: "CA"
        },
        required: {
            type: Boolean,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Object,
            default () {
                return {}
            }
        },
        geocode: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            saving_field: "",
            has_line2: false,
            autocomplete: null,
            autocomplete_listener: null,
            autocomplete_timer: null,
            geocoder: null,
            geocoding: false,
            manual_override: false
        }
    }
}
</script>
