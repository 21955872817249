<template>
    <default-button
        v-if="!!topic && !!topic.id"
        :color="!!topic.upcoming_alert_count ? 'primary' : 'regular'"
        class="btn-alert"
        v-on="$listeners"
    >
        <open-icon
            glyph="bell"
        />
        {{ topic.upcoming_alert_count | nibnut.number("0,0") }}
    </default-button>
</template>

<script>
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "AlertButton",
    components: {
        DefaultButton,
        OpenIcon
    },
    props: {
        topic: {
            type: Object,
            validator: prop => (prop === null) || (typeof prop === "object")
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.btn.btn-alert {
    position: fixed;
    top: 70px;
    right: $layout-spacing-sm;
    z-index: $zindex-5;
}
</style>
