<template>
    <page
        id="credits-list"
        :title="$root.translate('Uninvoiced Credit:::Uninvoiced Credits', {}, 2)"
        :status="page_status"
        :waiting="waiting || loading"
        @statused="statused"
    >
        <data-table
            id="credits-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :export-url="!selected_row_ids ? export_url : ''"
            :can-add="false"
            :clickable="false"
            :searchable="!selected_row_ids"
            :selection="selected_row_ids"
            @select="select_row"
            @clear-selection="clear_selection"
            @filter-by-selection="filter_by_selection"
            @sort="sort_by"
            @search="search"
            @page="goto_page"
        >
            <template
                v-slot:controls
            >
                <form-select
                    v-if="!selected_row_ids"
                    id="site_ids"
                    name="site_ids"
                    :value="state.site_ids"
                    data-source="credit"
                    :empty-label="$root.translate('All Customers')"
                    :placeholder="$root.translate('Site domain, Client name or email, ...')"
                    :show-all="false"
                    :required="false"
                    @input="filter_by('site_ids', $event)"
                />
                <form-select
                    v-if="!selected_row_ids"
                    id="month"
                    name="month"
                    :value="month"
                    :data-source="months"
                    :show-all="true"
                    :required="true"
                    @input="filter_by_date"
                />
                <form-input
                    v-if="!selected_row_ids"
                    id="year"
                    name="year"
                    type="number"
                    :value="year"
                    :min="2018"
                    :max="max_year"
                    :required="true"
                    @input="filter_by_date"
                />
            </template>
            <template
                v-slot:right-controls
            >
                <div
                    :class="{ 'col-12': !!selected_row_ids }"
                    class="columns"
                >
                    <div v-if="selected_row_ids" class="column flex-grow"></div>
                    <div v-if="selected_row_ids" class="column col-auto">
                        <label
                            class="form-label text-nowrap"
                        >
                            {{ translate("Invoice Date") }}:
                        </label>
                    </div>
                    <div v-if="selected_row_ids" class="column col-auto">
                        <form-date-input
                            id="invoiced_on"
                            name="invoiced_on"
                            v-model="invoiced_on"
                            position="bottom"
                            :required="true"
                            :horizontal="true"
                        />
                    </div>
                    <div class="column col-auto">
                        <default-button
                            v-if="!selected_row_ids"
                            flavour="link"
                            @click.prevent="invoiced_selection_start"
                        >
                            {{ translate("Mark as Invoiced...") }}
                        </default-button>
                        <default-button
                            v-else
                            :disabled="invoicing_selection"
                            @click.prevent="invoiced_selection_stop(false)"
                        >
                            {{ translate("Cancel") }}
                        </default-button>
                    </div>
                    <div v-if="selected_row_ids" class="column col-auto">
                        <default-button
                            color="success"
                            :waiting="invoicing_selection"
                            :disabled="!selected_row_ids.length || invoicing_selection"
                            @click.prevent="invoiced_selection_stop(true)"
                        >
                            {{ translate("Mark {n} credit(s) as invoiced", { n: selected_row_ids.length }) }}
                        </default-button>
                    </div>
                </div>
            </template>
            <template
                v-slot:summary="{ row }"
            >
                <h6>
                    {{ row.used_on | nibnut.date }} - {{ row.subject }}
                </h6>
                <div>{{ site_for_row(row).name }}</div>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div v-if="field === 'used_on'">
                    {{ row.used_on | nibnut.date }}
                    <div
                        v-if="!!row.is_billable && !!row.invoiced_on"
                        class="text-gray text-small"
                    >
                        {{ translate("Invoiced on") }}:&nbsp;{{ row.invoiced_on | nibnut.date }}
                    </div>
                </div>
                <div v-else-if="field === 'site_id'">
                    <div>
                        <router-link
                            :to="{ name: 'site.edit', params: { id: row.site_id } }"
                            @click.native.stop
                        >
                            {{ site_for_row(row).name }}
                        </router-link>
                    </div>
                    <div class="text-small">
                        <router-link
                            :to="{ name: 'client.edit', params: { id: row.computed_client_id } }"
                            @click.native.stop
                        >
                            {{ client_for_row(row).computed_name }}
                        </router-link>
                    </div>
                </div>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>
    </page>
</template>

<script type="text/javascript">
import { is_page, is_remote_data_table_source } from "@/nibnut/mixins"

import {
    FormSelect,
    FormInput,
    FormDateInput,
    DefaultButton
} from "@/nibnut/components"

export default {
    name: "CreditList",
    mixins: [is_page, is_remote_data_table_source],
    components: {
        FormSelect,
        FormInput,
        FormDateInput,
        DefaultButton
    },
    methods: {
        pre_load () {
            if(!this.state.filter) this.set_state_value("filter", this.$moment().startOf("month").format("YYYY-MM-DD"))
        },
        filter_rows (rows) {
            return rows
        },
        site_for_row (row) {
            return this.entity_records("site").find(site => site.id === row.site_id) || {}
        },
        client_for_row (row) {
            return this.entity_records("client").find(client => client.id === row.computed_client_id) || {}
        },
        filter_by_date (value, field) {
            const date = this.$moment(this.state.filter)
            if(field === "year") value = parseInt(value) || this.$moment().year()
            date[field](value).startOf("month")
            this.filter("used_on", date.format("YYYY-MM-DD"))
        },
        invoiced_selection_start () {
            this.invoiced_on = this.$moment()
            this.selected_row_ids = []
        },
        invoiced_selection_stop (save) {
            if(save && !!this.selected_row_ids.length && !!this.invoiced_on) {
                this.invoicing_selection = true
                this.$store.dispatch(
                    "RECORDS_ACTION",
                    {
                        entity: this.entity,
                        action: "invoice",
                        data: { ids: this.selected_row_ids, invoiced_on: this.invoiced_on.format("YYYY-MM-DD"), fields: this.fields, relation_ids: this.relation_ids },
                        method: "put"
                    }
                ).then(results => {
                    this.selected_row_ids = null
                    this.refresh()
                }).catch(error => {
                    this.$error(error.message)
                }).then(() => {
                    this.invoicing_selection = false
                })
            } else this.selected_row_ids = null
        }
    },
    computed: {
        fields () {
            return ["fieldset::list", "ns::site;fieldset::basic", "ns::client;fieldset::basic"]
        },
        month () {
            return this.$moment(this.state.filter || null).month()
        },
        max_year () {
            return this.$moment().year()
        },
        year () {
            return this.$moment(this.state.filter || null).year()
        },
        months () {
            return this.$moment.months().map((name, id) => {
                return { id, name }
            })
        }
    },
    data () {
        return {
            entity: "credit",
            relation_ids: ["site", "client"],
            columns: {
                used_on: { label: "Used", sort: "asc", type: "numeric" },
                subject: { label: "Ticket", sort: false },
                site_id: { label: "Customer", sort: false }
            },
            default_state: {
                per_page: 25,
                page: 1,
                sort_by: "used_on",
                sort_dir: "asc",
                filter_on: "used_on",
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                site_ids: null
            },
            invoiced_on: null,
            invoicing_selection: false
        }
    }
}
</script>

<style lang="scss">
#tickets-list {
    td {
        vertical-align: top;
    }
}
</style>
