const is_external_script_holder = {
    methods: {
        inject_script (id, url, onload) {
            let script = document.getElementById(id)
            if(!script) {
                script = document.createElement("script")
                script.type = "text/javascript"
                script.src = url
                script.id = id
                script.async = true
                script.onload = onload
                document.head.appendChild(script)
            }
        }
    }
}

export default is_external_script_holder
