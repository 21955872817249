import ProjectsList from "@/custom/components/ProjectsList"
import NewProject from "@/custom/dialogs/NewProject"

const lists_projects = {
    components: {
        ProjectsList,
        NewProject
    },
    watch: {
        edit_project: "reset_editor"
    },
    methods: {
        edit_project_by_id (project_id) {
            this.edited_project_id = project_id
            this.edit_project = true
        },
        reset_editor () {
            if(!this.edit_project) this.edited_project_id = null
        },
        created_project (project) {
            this.newest_project_id = project.id
            this.edit_project = false
        }
    },
    data () {
        return {
            edited_project_id: null,
            edit_project: false,
            newest_project_id: 0
        }
    }
}

export default lists_projects
