<template>
    <page
        id="alerts-list-page"
        :title="translate('Alerts')"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <alerts-list
            topic-type="*"
            :topic-id="1"
        />
    </page>
</template>

<script type="text/javascript">
import { is_page } from "@/nibnut/mixins"

import { AlertsList } from "@/custom/components"

export default {
    mixins: [is_page],
    components: {
        AlertsList
    }
}
</script>
