<template>
    <img
        :src="src"
        class="freescout-button-image"
        @mouseover="hover=true"
        @mouseout="hover=false"
    />
</template>

<script type="text/javascript">
import freescout_active from "@/assets/img/freescout.png"
import freescout_inactive from "@/assets/img/freescout_inactive.png"

export default {
    computed: {
        src () {
            if(!this.active && !this.hover) return freescout_inactive
            return freescout_active
        }
    },
    props: {
        active: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            hover: false
        }
    }
}
</script>

<style lang="scss">
.freescout-button-image {
    max-width: 100%;
    max-height: 100%;
}
</style>
