<template>
    <modal-dialog
        id="new-site"
        :show.sync="shown"
    >
        <template v-slot:title><span class="h5">{{ $root.translate("New Website") }}</span></template>
        <base-form
            :has-required-fields="true"
            @submit.prevent="create"
        >
            <form-input
                id="name"
                name="name"
                v-model="site.name"
                :required="true"
                :error="has_error('name')"
            >
                <template v-slot:label>{{ $root.translate("Name") }}</template>
            </form-input>

            <form-input
                id="domain"
                name="domain"
                v-model="site.domain"
                :required="true"
                :error="has_error('domain')"
                class="mt-2"
            >
                <template v-slot:label>{{ $root.translate("Domain") }}</template>
            </form-input>

            <form-select
                id="client_id"
                name="client_id"
                v-model="site.client_id"
                data-source="client"
                :required="true"
                :disabled="!!clientId"
                :error="has_error('client_id')"
            >
                <template v-slot:label>{{ $root.translate("Client") }}</template>
            </form-select>

            <form-toggle-input
                id="check_website"
                name="check_website"
                type="checkbox"
                v-model="site.check_website"
                :required="false"
                class="mt-8"
            >
                {{ $root.translate('Monitor Website Status') }}
            </form-toggle-input>
            <form-toggle-input
                id="check_domain"
                name="check_domain"
                type="checkbox"
                v-model="site.check_domain"
                :required="false"
            >
                {{ $root.translate('Monitor Domain') }}
            </form-toggle-input>
            <form-toggle-input
                id="check_ssl"
                name="check_ssl"
                type="checkbox"
                v-model="site.check_ssl"
                :required="false"
            >
                {{ $root.translate('Monitor SSL Certificate') }}
            </form-toggle-input>
            <form-toggle-input
                id="check_links"
                name="check_links"
                type="checkbox"
                v-model="site.check_links"
                :required="false"
            >
                {{ $root.translate('Monitor Broken Links & Images') }}
            </form-toggle-input>
        </base-form>

        <template v-slot:footer>
            <div class="text-center">
                <default-button
                    :disabled="creating"
                    class="mr-2"
                    @click.prevent="shown = false"
                >
                    {{ $root.translate("Cancel") }}
                </default-button>
                <default-button
                    type="submit"
                    color="primary"
                    :waiting="creating"
                    :disabled="creating"
                    class="ml-2"
                    @click.prevent="create"
                >
                    {{ $root.translate("Create") }}
                </default-button>
            </div>
        </template>
    </modal-dialog>
</template>

<script>
import crud_utilities from "@/nibnut/mixins/CrudUtilities"
import handles_errors from "@/nibnut/mixins/HandlesErrors"

import {
    DefaultButton,
    ModalDialog,
    BaseForm,
    FormInput,
    FormSelect,
    FormToggleInput
} from "@/nibnut/components"

export default {
    name: "NewSite",
    mixins: [crud_utilities, handles_errors],
    components: {
        DefaultButton,
        ModalDialog,
        BaseForm,
        FormInput,
        FormSelect,
        FormToggleInput
    },
    mounted () {
        this.reset()
    },
    watch: {
        show: "reset"
    },
    methods: {
        reset () {
            this.record_shell("site").then(record => {
                this.site = {
                    ...record,
                    check_website: true,
                    check_domain: true,
                    check_ssl: true,
                    check_links: true,
                    client_id: this.clientId
                }
            })
        },
        create () {
            this.creating = true
            return this.$store.dispatch("CREATE_RECORD", {
                entity: "site",
                data: this.site
            }).then(record => {
                this.shown = false
                this.$router.push({ name: "site.edit", params: { id: record.id } })
            }).catch(this.receive_error).then(() => {
                this.creating = false
            })
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        clientId: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            site: {},
            creating: false
        }
    }
}
</script>
