<template>
    <div v-if="!!edited_record">
        <div class="columns">
            <div class="column col-6 col-sm-12">
                <h6>{{ translate("Client Types") }}</h6>
                <choice-lists
                    table-name="client_categories"
                />
            </div>
            <div class="column col-6 col-sm-12">
                <h6>{{ translate("Hosts & Registrars") }}</h6>
                <choice-lists />
            </div>
        </div>
    </div>
</template>

<script>
import is_settings_panel from "./IsSettingsPanel"

import ChoiceLists from "./ChoiceLists"

export default {
    name: "Hosts",
    mixins: [is_settings_panel],
    components: {
        ChoiceLists
    }
}
</script>
