<template>
    <div>
        <data-table
            id="projects-list"
            entityName="12-months project:::12-months projects"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="true"
            :export-url="(!siteId && !$mq.match(/^(md|lg|tl|xl)$/i)) ? export_url : null"
            class="projects-list"
            @sort="sort_by"
            @search="search"
            @clear-search="clear_search"
            @page="goto_page"
            @add="edit_project_by_id(0)"
            @click="edit_project_by_id($event.id)"
        >
            <template
                v-slot:controls
            >
                <!--
                <div>
                    <form-select
                        id="filter"
                        name="filter"
                        :value="state.filter"
                        :data-source="project_types"
                        :empty-value="0"
                        :empty-label="translate('All Types')"
                        :show-all="true"
                        :required="false"
                        @input="filter('project_type_id', $event)"
                    />
                </div>
                //-->
                <div>
                    <form-select
                        id="status"
                        name="status"
                        :value="state.computed_in_progress"
                        :data-source="statuses"
                        :empty-value="null"
                        :empty-label="translate('Any Status')"
                        :show-all="true"
                        :required="false"
                        @input="filter_by('computed_in_progress', $event)"
                    />
                </div>
            </template>
            <template
                v-slot:summary="{ row }"
            >
                <h6 v-if="!siteId">
                    <router-link
                        v-if="field === 'owner_id'"
                        :to="{ name: 'site.edit', params: { id: row.owner_id } }"
                    >
                        {{ row.owner_name }}
                    </router-link>
                </h6>
                <div class="mb-2">
                    {{ row.start_on | nibnut.date }} &gt; {{ row.end_on | nibnut.date }}
                    <div class="text-gray text-small">
                        {{ translate("1 month:::{n} months", { n: duration_for_row(row) }, duration_for_row(row)) }}
                    </div>
                </div>
                <div class="mb-2">
                    <span v-if="row.computed_in_progress">{{ translate("In progress...") }}</span>
                    <span v-else class="text-gray">{{ row.status }}</span>
                    <div class="text-gray text-small">
                        <span v-if="row.user_name">{{ row.user_name }}</span>
                        <span v-else class="text-gray">{{ translate("Unassigned") }}</span>
                    </div>
                </div>
                <div>
                    {{ row.computed_total | nibnut.currency }}
                    <div class="text-gray text-small">
                        {{ row.price_first_period | nibnut.currency }} + {{ row.price_recurring_period | nibnut.currency }}&nbsp;/&nbsp;{{ translate("month") }}
                    </div>
                </div>
                <default-button
                    flavor="link"
                    color="error"
                    class="mt-4"
                    @click.prevent.stop="confirm_row_delete(row)"
                >
                    <open-icon glyph="trash" />
                    {{ translate("Delete") }}
                </default-button>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <router-link
                    v-if="field === 'owner_id'"
                    :to="{ name: 'site.edit', params: { id: row.owner_id } }"
                >
                    {{ row.owner_name }}
                </router-link>
                <div v-else-if="field.match(/_on$/)">
                    {{ row[field] | nibnut.date }}
                    <div v-if="!!siteId && (field === 'start_on')" class="text-gray text-small">
                        {{ translate("1 month:::{n} months", { n: duration_for_row(row) }, duration_for_row(row)) }}
                    </div>
                </div>
                <span v-else-if="field === 'duration'">{{ translate("1 month:::{n} months", { n: duration_for_row(row) }, duration_for_row(row)) }}</span>
                <span v-else-if="field === 'user_name'">
                    <span v-if="row.user_name">{{ row.user_name }}</span>
                    <span v-else class="text-gray">{{ translate("Unassigned") }}</span>
                </span>
                <div v-else-if="field === 'computed_in_progress'" class="no-wrap">
                    <span v-if="row.computed_in_progress">{{ translate("In progress...") }}</span>
                    <span v-else class="text-gray">{{ row.status }}</span>
                    <div v-if="!!siteId" class="text-gray text-small">
                        <span v-if="row.user_name">{{ row.user_name }}</span>
                        <span v-else class="text-gray">{{ translate("Unassigned") }}</span>
                    </div>
                </div>
                <div v-else-if="field === 'computed_total'" class="no-wrap">
                    {{ row.computed_total | nibnut.currency }}
                    <div class="text-gray text-small">
                        {{ row.price_first_period | nibnut.currency }} + {{ row.price_recurring_period | nibnut.currency }}&nbsp;/&nbsp;{{ translate("month") }}
                    </div>
                </div>
                <div v-else-if="field === '_buttons'" class="text-right">
                    <default-button
                        flavor="link"
                        @click.prevent.stop="confirm_row_delete(row)"
                    >
                        <open-icon glyph="trash" />
                    </default-button>
                </div>
                <span v-else>{{ row[field] }}</span>
            </template>
            <template v-slot:tfoot="{ summarized }">
                <tfoot v-if="summarized && !!statistics && (state.total > 1)" class="totals text-small text-bold">
                    <tr>
                        <td>
                            {{ translate("1 month:::{n} months", { n: statistics.duration }, statistics.duration) }}
                            <div>{{ statistics.grand_total | nibnut.currency }}</div>
                            <div class="text-gray text-small">
                                {{ statistics.total_first_period | nibnut.currency }} + {{ statistics.total_recurring_period | nibnut.currency }}&nbsp;/&nbsp;{{ translate("month") }}
                            </div>
                        </td>
                    </tr>
                </tfoot>
                <tfoot v-else-if="!!statistics && (state.total > 1)" class="totals text-small text-bold">
                    <tr>
                        <td :colspan="siteId ? 3 : 6">&nbsp;</td>
                        <td>
                            {{ statistics.grand_total | nibnut.currency }}
                            <div class="text-gray text-small">
                                {{ statistics.total_first_period | nibnut.currency }} + {{ statistics.total_recurring_period | nibnut.currency }}&nbsp;/&nbsp;{{ translate("month") }}
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </template>
        </data-table>
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            :waiting="deleting_record"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex"

import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"
import confirms from "@/nibnut/mixins/Confirms"

import FormSelect from "@/nibnut/components/Inputs/FormSelect"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "ProjectsList",
    mixins: [is_remote_data_table_source, confirms],
    components: {
        FormSelect,
        DefaultButton,
        OpenIcon
    },
    watch: {
        createdProjectId: "maybe_refresh"
    },
    methods: {
        pre_load () {
            if(!this.project_types.length) {
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "project_type"
                    }
                )
            }
            this.set_state_value("owner_id", this.siteId)
        },
        post_load (response) {
            this.statistics = response.meta
        },
        edit_project_by_id (project_id) {
            this.$emit("edit", project_id)
        },
        maybe_refresh () {
            if(this.createdProjectId > 0) this.refresh()
        },
        duration_for_row (row) {
            if(row && row.start_on && row.end_on) return Math.round(this.$moment(row.end_on).diff(this.$moment(row.start_on), "months", true))
            return 1
        },
        project_type_for_row (row) {
            return this.entity_record("project_type", row.project_type_id) || {}
        },
        confirm_row_delete (row) {
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete 12-months Project"),
                    message: this.$root.translate("Do you really want to delete the project '{name}'? There is no undo...", { name: row.owner_name }),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete"),
                    _record_id: row.id
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") {
                this.deleting_record = true
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: this.entity,
                        id: this.confirmation_props._record_id
                    }
                ).then(() => {
                    this.refresh()
                    this.done_confirming()
                }).catch(this.receive_error).then(() => {
                    this.deleting_record = false
                })
            } else this.done_confirming()
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        state_identifier () {
            return `projects-list-${this.siteId}`
        },
        fields () {
            return ["fieldset::list"]
        },
        visible_columns () {
            if(this.siteId) {
                const { owner_id, duration, user_name, ...columns } = this.columns
                return columns
            }
            return this.columns
        },
        project_types () {
            return this.entity_records("project_type")
        },
        statuses () {
            return [
                { id: 1, name: this.translate("In Progress") },
                { id: 0, name: this.translate("Completed") }
            ]
        }
    },
    props: {
        siteId: {
            type: Number,
            validator: prop => prop >= 0,
            required: true
        },
        createdProjectId: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            entity: "project",
            columns: {
                owner_id: { label: "Name", sort: false },
                start_on: { label: "Start Date", sort: "desc", type: "numeric" },
                end_on: { label: "End Date", sort: null, type: "numeric" },
                duration: { label: "Duration", sort: null, type: "numeric" },
                computed_in_progress: { label: "Status", sort: false },
                user_name: { label: "Assigned", sort: false },
                computed_total: { label: "Pricing", sort: null, type: "numeric" },
                _buttons: { label: " ", sort: false, cell_class: { "col-1": true } }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "start_on",
                sort_dir: "desc",
                filter_on: "owner_id",
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                owner_id: 0
            },
            statistics: null,
            deleting_record: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.projects-list {
    table.table {
        td {
            vertical-align: top;
        }
        tfoot.totals {
            & > tr:first-child > td {
                border-top: $border-width-lg solid $border-color;
            }
            & > tr:last-child > td {
                border-bottom-width: $border-width-lg;
            }
        }
    }
}
</style>
