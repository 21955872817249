<template>
    <default-button
        :title="title"
        v-bind="button_props"
        class="btn-remote-connection"
        @click.prevent.stop="toggle($event)"
    >
        <slot></slot>
        <open-icon
            v-if="canDisconnect && !!remoteId && (!url || alt_mode)"
            glyph="ban"
            size="2x"
            class="remote-disconnect text-error"
        />
    </default-button>
</template>

<script>
import { DefaultButton, OpenIcon } from "@/nibnut/components"

export default {
    name: "RemoteConnectionButton",
    components: {
        DefaultButton,
        OpenIcon
    },
    mounted () {
        window.addEventListener("keydown", this.key_press)
        window.addEventListener("keyup", this.key_up)
    },
    beforeDestroy () {
        window.removeEventListener("keydown", this.key_press)
        window.removeEventListener("keyup", this.key_up)
    },
    methods: {
        key_press (event) {
            this.alt_mode = event.metaKey
        },
        key_up (event) {
            this.alt_mode = event.metaKey
        },
        toggle (event) {
            if(!!this.remoteId && !!this.url && (!this.canDisconnect || !event.metaKey)) window.open(this.url)
            else this.$emit("click", this.remoteId)
        }
    },
    computed: {
        button_props () {
            const { remoteId, remoteServiceName, url, ...button_props } = this.$props
            return button_props
        },
        title () {
            if(this.remoteId && !!this.url) return this.translate("Click to view in {name}. Command-click to disconnect.", { name: this.remoteServiceName })
            if(this.remoteId) return this.translate("Click to disconnect from {name}.", { name: this.remoteServiceName })
            return this.translate("Connect to {name}...", { name: this.remoteServiceName })
        }
    },
    props: {
        ...DefaultButton.props,
        remoteId: {
            default: null
        },
        remoteServiceName: {
            type: String,
            required: true
        },
        url: {
            type: String,
            default: ""
        },
        canDisconnect: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            alt_mode: false
        }
    }
}
</script>

<style lang="scss">
.btn-remote-connection {
    position: relative;

    i.remote-disconnect {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
