import Vue from "vue"
import VueRouter from "vue-router"
import routes from "./routes"

Vue.use(VueRouter)

if("scrollRestoration" in history) history.scrollRestoration = "manual"

// Setup vue-router
const router = new VueRouter({
    mode: "history",
    base: "/",
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        // if(to.hash) return { el: to.hash }
        if(!!to && !!to.meta && !!to.meta.scroll_position) {
            to.meta.scroll_position = savedPosition
            return savedPosition
        }
        return { x: 0, y: 0 }
    }
})

export default router
