<template>
    <div v-if="!!edited_record" class="columns">
        <div class="column col-8 col-lg-6 col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="card-title h5">{{ translate("Site Settings") }}</div>
                </div>
                <div class="card-body">
                    <h6>{{ translate("Defaults") }}</h6>
                    <div class="columns">
                        <div class="column col-4 col-sm-12">
                            <form-input
                                id="default_expected_load_time"
                                name="default_expected_load_time"
                                type="number"
                                :value="edited_record.default_expected_load_time"
                                :min="1"
                                :required="false"
                                :saving="saving('default_expected_load_time')"
                                :error="has_error('default_expected_load_time')"
                                @input="confirm_load_time_change"
                            >
                                <template v-slot:label>{{ translate("Max. Load Time") }}</template>
                                <template v-slot:right_addon><span class="input-group-addon">{{ translate("ms") }}</span></template>
                            </form-input>
                        </div>
                        <div class="column col-4 col-sm-12">
                            <form-input
                                id="default_notification_trigger"
                                name="default_notification_trigger"
                                type="number"
                                :value="edited_record.default_notification_trigger"
                                :min="1"
                                :required="false"
                                :saving="saving('default_notification_trigger')"
                                :error="has_error('default_notification_trigger')"
                                @input="save"
                            >
                                <template v-slot:label>{{ translate("Confirm Errors") }}</template>
                                <template v-slot:right_addon><span class="input-group-addon">{{ translate("times") }}</span></template>
                            </form-input>
                        </div>
                        <div class="column col-4 col-sm-12">
                            <form-input
                                id="default_license_next_bill_delay"
                                name="default_license_next_bill_delay"
                                type="number"
                                :value="edited_record.default_license_next_bill_delay"
                                :min="1"
                                :required="false"
                                :saving="saving('default_license_next_bill_delay')"
                                :error="has_error('default_license_next_bill_delay')"
                                @input="save"
                            >
                                <template v-slot:label>{{ translate("Lic. Billing Delay") }}</template>
                                <template v-slot:right_addon><span class="input-group-addon">{{ translate("month(s)") }}</span></template>
                            </form-input>
                        </div>
                    </div>

                    <form-input
                        id="mainwp_site_url"
                        name="mainwp_site_url"
                        v-model="edited_record.mainwp_site_url"
                        :required="false"
                        :saving="saving('mainwp_site_url')"
                        :error="has_error('mainwp_site_url')"
                        @input="save"
                    >
                        <template v-slot:label>{{ translate("MainWP Site URL") }}</template>
                    </form-input>

                    <form-input
                        id="dashboard_site_url"
                        name="dashboard_site_url"
                        v-model="edited_record.dashboard_site_url"
                        :required="false"
                        :saving="saving('dashboard_site_url')"
                        :error="has_error('dashboard_site_url')"
                        @input="save"
                    >
                        <template v-slot:label>{{ translate("Dashboard Site URL") }}</template>
                    </form-input>

                    <form-input
                        id="care_plans_url"
                        name="care_plans_url"
                        v-model="edited_record.care_plans_url"
                        :required="false"
                        :saving="saving('care_plans_url')"
                        :error="has_error('care_plans_url')"
                        @input="save"
                    >
                        <template v-slot:label>{{ translate("Care Plans Page URL") }}</template>
                    </form-input>

                    <!--
                    <form-input
                        id="crm_site_url"
                        name="crm_site_url"
                        v-model="edited_record.crm_site_url"
                        :required="false"
                        :saving="saving('crm_site_url')"
                        :error="has_error('crm_site_url')"
                        @input="save"
                    >
                        <template v-slot:label>{{ translate("CRM Site URL") }}</template>
                    </form-input>
                    //-->
                </div>
            </div>
        </div>
        <div class="column col-4 col-lg-6 col-md-12">
            <project-types />
            <div class="card mt-4">
                <div class="card-header">
                    <div class="card-title h5">{{ translate("User Management") }}</div>
                </div>
                <div class="card-body">
                    <invitation-expiries
                        id="default_invitation_delay"
                        name="default_invitation_delay"
                        v-model="edited_record.default_invitation_delay"
                        :required="true"
                        :saving="saving('default_invitation_delay')"
                        :error="has_error('default_invitation_delay')"
                        @input="save"
                    />
                </div>
            </div>
            <div class="card mt-4">
                <div class="card-body">
                    <default-button
                        color="primary"
                        :block="true"
                        :disabled="saving_custom_css"
                        :waiting="saving_custom_css"
                        @click.prevent="edit_custom_css"
                    >
                        {{ translate("Edit Custom CSS...") }}
                    </default-button>
                </div>
            </div>
            <div class="card mt-4">
                <div class="card-body">
                    <default-button
                        color="secondary"
                        :block="true"
                        :title="translate('Re-check all sites with reported missing broken email...')"
                        @click.prevent="recheck_missing_emails"
                    >
                        {{ translate("Re-check Missing Emails") }}
                    </default-button>
                </div>
            </div>
        </div>
        <div v-if="is_developer" class="column col-8 col-lg-6 col-md-12 mt-4">
            <div class="card">
                <div class="card-header">
                    <div class="card-title h5">{{ translate("Developer Zone") }}</div>
                </div>
                <div class="card-body">
                    <form-toggle-input
                        id="bug_reporter_active"
                        name="bug_reporter_active"
                        type="checkbox"
                        v-model="edited_record.bug_reporter_active"
                        :true-value="1"
                        :false-value="0"
                        :required="false"
                        :saving="saving('bug_reporter_active')"
                        :error="has_error('bug_reporter_active')"
                        @input="save"
                    >
                        {{ translate('Bug Reporter') }}
                    </form-toggle-input>
                </div>
            </div>
        </div>
        <modal-dialog
            id="load-time-confirmation"
            :show.sync="confirming_load_time_change"
        >
            <template v-slot:title>
                <span class="h5">{{ translate("Propagate Load Time Change to {number} ms", { number: load_time_change_to }) }}</span>

                <p>{{ translate("Do you want to change the expected load times of existing sites?") }}</p>
            </template>

            <template v-slot:footer>
                <default-button
                    color="primary"
                    :block="true"
                    class="mb-2"
                    @click.prevent="save_load_time_change(true)"
                >
                    {{ translate("Propagate to all sites") }}
                </default-button>
                <default-button
                    color="primary"
                    :block="true"
                    class="mb-2"
                    @click.prevent="save_load_time_change(false)"
                >
                    {{ translate("Propagate to sites set with load times of {number} ms", { number: load_time_change_from }) }}
                </default-button>
                <default-button
                    color="primary"
                    :block="true"
                    class="mb-2"
                    @click.prevent="save_load_time_change(null)"
                >
                    {{ translate("Don't propagate") }}
                </default-button>
                <default-button
                    :block="true"
                    @click.prevent="cancel_load_time_change"
                >
                    {{ translate("Cancel") }}
                </default-button>
            </template>
        </modal-dialog>
        <modal-dialog
            id="custom-css-editor"
            :show.sync="editing_custom_css"
        >
            <template v-slot:title>
                <span class="h5">{{ translate("Custom CSS") }}</span>
            </template>

            <prism-editor
                v-model="custom_css"
                :highlight="syntax_highlighter"
            />

            <template v-slot:footer>
                <default-button
                    class="mr-2"
                    @click.prevent="editing_custom_css = false"
                >
                    {{ translate("Cancel") }}
                </default-button>
                <default-button
                    color="primary"
                    class="ml-2"
                    @click.prevent="save_custom_css"
                >
                    {{ translate("Save & Apply") }}
                </default-button>
            </template>
        </modal-dialog>
    </div>
</template>

<script>
import { highlight, languages } from "prismjs/components/prism-core"
import "prismjs/components/prism-css"
import "prismjs/themes/prism-tomorrow.css"

import { PrismEditor } from "vue-prism-editor"
import "vue-prism-editor/dist/prismeditor.min.css" // import the styles somewhere

import { ModalDialog, InvitationExpiries, FormInput, FormToggleInput, DefaultButton } from "../../nibnut/components"
import ProjectTypes from "./ProjectTypes"

import is_settings_panel from "./IsSettingsPanel"

export default {
    name: "AppSettings",
    mixins: [is_settings_panel],
    components: {
        ModalDialog,
        InvitationExpiries,
        FormInput,
        FormToggleInput,
        DefaultButton,
        PrismEditor,
        ProjectTypes
    },
    methods: {
        confirm_load_time_change (new_time) {
            this.load_time_change_from = this.edited_record.default_expected_load_time
            this.load_time_change_to = parseInt(new_time) || 1
            this.confirming_load_time_change = true
        },
        cancel_load_time_change () {
            this.$nextTick(() => {
                this.edited_record.default_expected_load_time = this.load_time_change_from
            })
            this.confirming_load_time_change = false
        },
        save_load_time_change (propagation_mode) {
            const data = {
                default_expected_load_time: this.load_time_change_to
            }
            if(propagation_mode === true) data.propagate_expected_load_time = 0
            else if(propagation_mode === false) data.propagate_expected_load_time = this.load_time_change_from
            this.save_data_for_record_id(this.entity, this.edited_record.id, data)
            this.confirming_load_time_change = false
        },
        edit_custom_css () {
            this.custom_css = this.edited_record.custom_css
            this.editing_custom_css = true
        },
        save_custom_css () {
            this.saving_custom_css = true
            this.save(this.custom_css, "custom_css").then(() => {
                const node = document.getElementById("custom-app-css")
                if(node) {
                    const base_href = node.getAttribute("href").split("?")[0]
                    node.setAttribute("href", `${base_href}?_x=${(new Date()).getTime()}`)
                }
                this.custom_css = ""
                this.saving_custom_css = false
            })
            this.editing_custom_css = false
        },
        recheck_missing_emails () {
            this.$store.dispatch(
                "RECORDS_ACTION",
                {
                    entity: "app",
                    action: "recheck-all-missing-emails",
                    method: "post",
                    passthru: true
                }
            ).then(() => {
                this.$success(this.translate("Done: you will be notified when all emails have been re-checked."))
            }).catch(error => {
                this.$error(error.message)
            })
        },
        syntax_highlighter (css) {
            return highlight(css, languages.css)
        }
    },
    data () {
        return {
            confirming_load_time_change: false,
            load_time_change_to: 0,
            load_time_change_from: 0,
            custom_css: "",
            editing_custom_css: false,
            saving_custom_css: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.prism-editor-wrapper {
    height: 25vh;
    border: $border-width solid $border-color;
}
</style>
