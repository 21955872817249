<template>
    <page
        id="licenses-list"
        :title="$root.translate('License:::Licenses', {}, 2)"
        :status="page_status"
        :waiting="waiting || loading"
        @statused="statused"
    >
        <template v-slot:title>
            <h1>
                {{ $root.translate('License:::Licenses', {}, 2) }}
                <default-button
                    :title="$root.translate('New License')"
                    flavor="link"
                    @click.prevent="record_create(entity)"
                >
                    <open-icon glyph="plus" />
                </default-button>
            </h1>
        </template>
        <data-table
            id="licenses-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="is_at_least_administrator"
            :clickable="is_at_least_administrator"
            :export-url="export_url"
            @sort="sort_by"
            @search="search"
            @clear-search="clear_search"
            @page="goto_page"
            @add="is_at_least_administrator ? record_create(entity) : null"
            @click="is_at_least_administrator ? record_edit(entity, $event.id) : null"
        >
            <template
                v-slot:controls
            >
                <div>
                    <form-select
                        id="repeat"
                        name="repeat"
                        :value="state.filter"
                        :data-source="repeats"
                        :required="false"
                        :empty-value="0"
                        :empty-label="$root.translate('All Renewals')"
                        @input="filter('repeat', $event)"
                    />
                </div>
                <div>
                    <form-select
                        id="type"
                        name="type"
                        :value="state.license_type"
                        :data-source="license_types"
                        :required="false"
                        :empty-label="$root.translate('All Types')"
                        @input="filter_by('license_type', $event)"
                    />
                </div>
            </template>
            <template
                v-slot:summary="{ row }"
            >
                <h6>
                    <base-link
                        v-if="!!row.provider_url"
                        :href="row.provider_url"
                        :title="row.name"
                        target="_blank"
                        @click.stop
                    >
                        {{ row.name }}
                    </base-link>
                    <span v-else>{{ row.name }}</span>
                </h6>
                <div
                    v-if="!!row.license_key"
                    class="text-gray text-small"
                >
                    {{ row.license_key }} ({{ type_for_row(row) }})
                    <default-button
                        flavor="link"
                        size="xs"
                        v-clipboard:copy="row.license_key"
                        v-clipboard:success="row_copied_to_clipboard"
                        class="ml-2"
                        @click.stop
                    >
                        <open-icon v-if="row_copied(row)" glyph="check-circle" />
                        <open-icon v-else glyph="clipboard" />
                    </default-button>
                </div>
                <div class="mt-2">
                    {{ repeat_for_row(row) }}
                    <div
                        v-if="!!row.repeat && !!row.repeat_on_as_string"
                        class="text-gray text-small"
                    >
                        <span v-if="row.repeat === constants('repeats', 'REPEAT_LIFETIME').id">{{ $root.translate("Acquired On") }}</span>
                        <span v-else>{{ $root.translate("Renews Next On") }}</span>
                        {{ row.repeat_on_as_string | nibnut.date }}
                    </div>
                    <div
                        class="text-gray text-small"
                    >
                        {{ $root.translate('Cost') }}: {{ row.purchase_price | nibnut.currency }}
                    </div>
                </div>
                <div class="mt-2">
                    {{ (row.computed_monthly_revenue * 12) | nibnut.currency }} / {{ $root.translate("year") }}
                    <div
                        class="text-gray text-small"
                    >
                        {{ row.computed_monthly_revenue | nibnut.currency }} / {{ $root.translate("month") }}
                    </div>
                </div>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div v-if="field === 'name'">
                    <base-link
                        v-if="!!row.provider_url"
                        :href="row.provider_url"
                        :title="row.name"
                        target="_blank"
                        @click.stop
                    >
                        {{ row.name }}
                    </base-link>
                    <span v-else>{{ row.name }}</span>
                    <div
                        v-if="!!row.license_key"
                        class="text-gray text-small"
                    >
                        {{ row.license_key }}
                        <default-button
                            flavor="link"
                            size="xs"
                            v-clipboard:copy="row.license_key"
                            v-clipboard:success="row_copied_to_clipboard"
                            class="ml-2"
                            @click.stop
                        >
                            <open-icon v-if="row_copied(row)" glyph="check-circle" />
                            <open-icon v-else glyph="clipboard" />
                        </default-button>
                    </div>
                </div>
                <div v-else-if="field === 'type'">
                    {{ type_for_row(row) }}
                </div>
                <div v-else-if="field === 'repeat'">
                    {{ repeat_for_row(row) }}
                    <div
                        v-if="!!row.repeat && !!row.repeat_on_as_string"
                        class="text-gray text-small"
                    >
                        <span v-if="row.repeat === constants('repeats', 'REPEAT_LIFETIME').id">{{ $root.translate("Acquired On") }}</span>
                        <span v-else>{{ $root.translate("Renews Next On") }}</span>
                        {{ row.repeat_on_as_string | nibnut.date }}
                    </div>
                </div>
                <span v-else-if="field === 'purchase_price'">{{ row.purchase_price | nibnut.currency }}</span>
                <div v-else-if="field === 'computed_monthly_revenue'">
                    {{ (row.computed_monthly_revenue * 12) | nibnut.currency }} / {{ $root.translate("year") }}
                    <div
                        class="text-gray text-small"
                    >
                        {{ row.computed_monthly_revenue | nibnut.currency }} / {{ $root.translate("month") }}
                    </div>
                </div>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>
    </page>
</template>

<script type="text/javascript">
import { is_page, is_remote_data_table_source, misc_utilities, crud_utilities } from "@/nibnut/mixins"
import { handles_copy_paste } from "@/custom/mixins"

import {
    FormSelect,
    BaseLink,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"

export default {
    name: "LicenseList",
    mixins: [is_page, is_remote_data_table_source, misc_utilities, crud_utilities, handles_copy_paste],
    components: {
        FormSelect,
        BaseLink,
        DefaultButton,
        OpenIcon
    },
    methods: {
        type_for_row (row) {
            if(row && row.type) {
                const type = Object.values(this.constants("license_types")).find(license_type => license_type.id === row.type)
                if(type) return type.name
            }
            return ""
        },
        repeat_for_row (row) {
            if(row && row.repeat) {
                const repeat = Object.values(this.constants("repeats")).find(repeat => repeat.id === row.repeat)
                if(repeat) return repeat.name
            }
            return ""
        },
        row_copied_to_clipboard (copy_data) {
            this.copied_to_clipboard(copy_data.text)
        },
        row_copied (row) {
            return this.copied === row.license_key
        },
        clear_search () {
            this.clearing_search = true
            this.set_state_values({
                page: 1,
                filter_on: null,
                filter: null,
                archived: false,
                search: ""
            })
            this.refresh()
            setTimeout(() => {
                this.clearing_search = false
            }, 750)
        }
    },
    computed: {
        fields () {
            return [
                ...Object.keys(this.columns),
                "license_key",
                "provider_url",
                "repeat_on_as_string"
            ]
        },
        license_types () {
            return Object.values(this.constants("license_types"))
        },
        repeats () {
            return Object.values(this.constants("repeats"))
        }
    },
    data () {
        return {
            entity: "license",
            // relation_ids: ["monitor", "plan"],
            columns: {
                name: { label: "Name", sort: "asc", type: "alpha" },
                type: { label: "Type", sort: false },
                repeat: { label: "Renewal", sort: false },
                purchase_price: { label: "Cost", sort: null },
                computed_monthly_revenue: { label: "Revenue", sort: null, type: "numeric" }
            },
            default_state: {
                per_page: 25,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                license_type: ""
            }
        }
    }
}
</script>
