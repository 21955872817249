let copied_reset = null

const handles_copy_paste = {
    methods: {
        copied_to_clipboard (identifier = true) {
            if(copied_reset) {
                clearTimeout(copied_reset)
                copied_reset = null
            }
            this.copied = identifier
            copied_reset = setTimeout(() => {
                this.copied = false
            }, 3000)
        },
        copy_to_clipboard (text, identifier = true) {
            this.$copyText(text).then(
                () => {
                    this.copied_to_clipboard(identifier)
                },
                () => {}
            )
        }
    },
    data () {
        return {
            copied: false
        }
    }
}

export default handles_copy_paste
