import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import { crud_utilities, misc_utilities } from "@/nibnut/mixins"

const is_sites_list_data_source = {
    mixins: [crud_utilities, misc_utilities],
    methods: {
        site_monitors (site) {
            return this.entity_records("monitor").filter(monitor => monitor.site_id === site.id)
        },
        site_web_monitor (site) {
            return this.site_monitors(site).find(monitor => monitor.type === this.constants("monitor_types", "TYPE_WEBSITE").id) || {}
        },
        site_domain_monitor (site) {
            return this.site_monitors(site).find(monitor => monitor.type === this.constants("monitor_types", "TYPE_DOMAIN").id) || {}
        },
        site_ssl_monitor (site) {
            return this.site_monitors(site).find(monitor => monitor.type === this.constants("monitor_types", "TYPE_SSL").id) || {}
        },
        site_link_monitor (site) {
            return this.site_monitors(site).find(monitor => monitor.type === this.constants("monitor_types", "TYPE_LINKS").id) || {}
        },
        site_email_monitor (site) {
            return this.site_monitors(site).find(monitor => monitor.type === this.constants("monitor_types", "TYPE_SITE_EMAIL").id) || {}
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        plans () {
            return orderBy(this.entity_records("plan"), "name", "asc")
        },
        plan_repeats () {
            return [
                { id: "monthly", name: this.$root.translate("Monthly") },
                { id: "yearly", name: this.$root.translate("Yearly") }
            ]
        }
    }
}

export default is_sites_list_data_source
