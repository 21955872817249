<template>
    <page
        id="projects-list-page"
        :title="$root.translate('12-months Project:::12-months Projects', {}, 2)"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <template v-slot:title>
            <h1>
                {{ $root.translate('12-months Project:::12-months Projects', {}, 2) }}
                <default-button
                    :title="$root.translate('New Project')"
                    flavor="link"
                    @click.prevent="edit_project_by_id(0)"
                >
                    <open-icon glyph="plus" />
                </default-button>
            </h1>
        </template>
        <projects-list
            :site-id="0"
            :created-project-id="newest_project_id"
            @edit="edit_project_by_id"
        />
        <new-project
            :show.sync="edit_project"
            :project-id="edited_project_id"
            :site-id="0"
            @created="created_project"
        />
    </page>
</template>

<script type="text/javascript">
import { is_page } from "@/nibnut/mixins"
import { lists_projects } from "@/custom/mixins"

import {
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"

export default {
    mixins: [is_page, lists_projects],
    components: {
        DefaultButton,
        OpenIcon
    },
    methods: {
    },
    computed: {
    },
    data () {
        return {
        }
    }
}
</script>
