const handles_stored_state = {
    methods: {
        set_state_values (new_values) {
            this.$store.dispatch("SET_ROUTE_STATE", {
                route: this.state_identifier,
                route_state: {
                    ...(this.stored_state || this.default_state),
                    ...new_values
                }
            })
        },
        reset_state (force) {
            if(force || !this.stored_state) {
                this.$store.dispatch("SET_ROUTE_STATE", {
                    route: this.state_identifier,
                    route_state: {
                        ...this.default_state
                    }
                })
            }
        },
        set_state_value (key, value) {
            this.set_state_values({ [key]: value })
        }
    },
    computed: {
        state_identifier () {
            return this.$route.name
        },
        stored_state () {
            return this.$store.getters.route_state_by_identifier(this.state_identifier)
        },
        state () {
            return this.stored_state || { ...this.default_state }
        }
    },
    data () {
        return {
            default_state: {
                per_page: 10,
                page: 1,
                total: 0,
                found: 0
            }
        }
    }
}

export default handles_stored_state
