import { mapGetters } from "vuex"

import is_record_editor_page from "@/nibnut/mixins/IsRecordEditorPage"
import ui_utilities from "@/nibnut/mixins/UiUtilities"

const lists_projects = {
    mixins: [is_record_editor_page, ui_utilities],
    watch: {
        waiting: "maybe_whitelabel"
    },
    methods: {
        grab_record_id () {
            this.record_id = this.$route.params.uuid
        },
        statused (status) {
            this.page_status = status
        },
        maybe_whitelabel () {
            if(!this.waiting && !!this.edited_record && !this.edited_record.white_label_report) {
                const node = document.querySelector(".nibnut-app > .header")
                if(node) node.classList.add("wpexpert")
            }
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        edited_record () {
            if(this.shell_record) return this.shell_record
            if(!this.entity || !this.record_id) return null
            return this.entity_records(this.entity).find(report => report.uuid === this.record_id) || {}
        }
    },
    data () {
        return {
            alerts_shown: false
        }
    }
}

export default lists_projects
