<template>
    <modal-dialog
        id="new-project"
        :show.sync="shown"
    >
        <template v-slot:title>
            <span class="h5" v-if="!record_id && site">
                {{ translate("New Project for {name}", { name: site.name }) }}
            </span>
            <span class="h5" v-else-if="!record_id">
                {{ translate("New Project") }}
            </span>
            <span class="h5" v-else-if="project_type && edited_record">
                {{ translate("{project_type} for {name}", { project_type: project_type.name, name: site.name }) }}
            </span>
            <span class="h5" v-else-if="edited_record && site">
                {{ translate("Project for {name}", { name: site.name }) }}
            </span>
            <span class="h5" v-else>
                {{ translate("Project") }}
            </span>
        </template>
        <base-form
            v-if="edited_record"
            :has-required-fields="true"
            @submit.prevent="record_save"
        >
            <form-select
                v-if="!siteId"
                id="owner_id"
                name="owner_id"
                v-model="edited_record.owner_id"
                data-source="site"
                :required="true"
                :show-all="false"
                :saving="saving('owner_id')"
                :error="has_error('owner_id')"
                @input="save"
            >
                <template v-slot:label>
                    {{ translate("Site") }}
                </template>
            </form-select>

            <form-select
                id="user_id"
                name="user_id"
                v-model="edited_record.user_id"
                data-source="user"
                :empty-value="0"
                :empty-label="translate('Unassigned')"
                :required="false"
                :show-all="true"
                :saving="saving('user_id')"
                :error="has_error('user_id')"
                @input="save"
            >
                <template v-slot:label>
                    {{ translate("Assignment") }}
                </template>
            </form-select>

            <div class="columns">
                <form-input
                    id="price_first_period"
                    name="price_first_period"
                    v-model="edited_record.price_first_period"
                    glyph="dollar-sign"
                    glyph-position="left"
                    :required="false"
                    :saving="saving('price_first_period')"
                    :error="has_error('price_first_period')"
                    class="column"
                    @input="save"
                >
                    <template v-slot:label>
                        {{ translate("Project Startup") }}
                    </template>
                </form-input>
                <form-input
                    id="price_recurring_period"
                    name="price_recurring_period"
                    v-model="edited_record.price_recurring_period"
                    glyph="dollar-sign"
                    glyph-position="left"
                    :required="false"
                    :saving="saving('price_recurring_period')"
                    :error="has_error('price_recurring_period')"
                    class="column"
                    @input="save"
                >
                    <template v-slot:label>
                        {{ translate("Project Recurring") }}
                    </template>
                </form-input>
            </div>

            <div class="columns">
                <form-date-input
                    id="start_on"
                    name="start_on"
                    :value="edited_record.start_on"
                    position="right"
                    :required="false"
                    :saving="saving('start_on')"
                    :error="has_error('start_on')"
                    class="column"
                    @input="save_as_date"
                >
                    <template v-slot:label>
                        {{ translate("Start Date") }}
                    </template>
                    <template v-if="edited_record.end_on" v-slot:hint>
                        {{ translate("End Date") }}: {{ edited_record.end_on | nibnut.date }}
                    </template>
                </form-date-input>
                <form-input
                    id="duration"
                    name="duration"
                    type="number"
                    v-model="edited_record.duration"
                    :required="false"
                    :min="1"
                    :saving="saving('duration')"
                    :error="has_error('duration')"
                    class="column"
                    @input="save"
                >
                    <template v-slot:label>
                        {{ translate("Duration") }}
                    </template>
                    <template v-slot:right_addon>
                        <span class="input-group-addon">{{ translate("month:::months", {}, edited_record.duration) }}</span>
                    </template>
                </form-input>
            </div>

            <form-editor
                id="notes"
                name="notes"
                v-model="edited_record.notes"
                size="lg"
                :required="false"
                :full-height="false"
                :saving="saving('notes')"
                :error="has_error('notes')"
                @input="save"
            >
                <template v-slot:label>
                    {{ translate("Notes") }}
                </template>
            </form-editor>
        </base-form>

        <template v-slot:footer>
            <div v-if="!record_id" class="text-center">
                <default-button
                    :disabled="creating_record"
                    class="mr-2"
                    @click.prevent="shown = false"
                >
                    {{ translate("Cancel") }}
                </default-button>
                <default-button
                    type="submit"
                    color="primary"
                    :waiting="creating_record"
                    :disabled="creating_record"
                    class="ml-2"
                    @click.prevent="record_save"
                >
                    {{ translate("Create") }}
                </default-button>
            </div>
        </template>
    </modal-dialog>
</template>

<script>
import { mapGetters } from "vuex"

import is_record_editor from "@/nibnut/mixins/IsRecordEditor"

import FormSelect from "@/nibnut/components/Inputs/FormSelect"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import FormDateInput from "@/nibnut/components/Inputs/FormDateInput"
import FormEditor from "@/nibnut/components/Inputs/FormEditor"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import BaseForm from "@/nibnut/components/BaseForm"

export default {
    name: "NewProject",
    mixins: [is_record_editor],
    components: {
        FormSelect,
        FormInput,
        FormDateInput,
        FormEditor,
        DefaultButton,
        ModalDialog,
        BaseForm
    },
    watch: {
        show: "reset",
        projectId: "reset"
    },
    methods: {
        grab_record_id () {
            this.record_id = this.projectId
        },
        post_load () {
            if(!this.record_id && this.shell_record) {
                this.shell_record.owner_type = "App\\Site"
                this.shell_record.owner_id = this.siteId
            }
        },
        reset () {
            this.reload(true)
        },
        record_save () {
            const edited_record = this.edited_record
            const is_new = !edited_record.id
            if(is_new) this.creating_record = true
            return this.save_data_for_record_id(this.entity, edited_record.id || 0, edited_record).then(record => {
                this.creating_record = false
                this.$emit("created", record)
            })
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        can_reload () {
            return !!this.profile_id && (this.projectId !== null)
        },
        cleanup_before_reload () {
            return false
        },
        site () {
            return this.entity_record("site", this.siteId)
        },
        project_type () {
            return null // *************
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        projectId: {
            validator: prop => (prop === null) || (prop >= 0),
            default: null
        },
        siteId: {
            type: Number,
            validator: prop => prop >= 0,
            required: true
        }
    },
    data () {
        return {
            entity: "project",
            entityName: "project:::projects"
        }
    }
}
</script>
