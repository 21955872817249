<template>
    <div id="site-license-list" class="card">
        <div class="card-header">
            <div class="card-title h5">
                <span v-if="!!licenseId">{{ $root.translate("Sites") }}</span>
                <span v-else>{{ $root.translate("Licenses") }}</span>
                <default-button
                    :title="!!licenseId ? $root.translate('Add Site') : $root.translate('Add License')"
                    flavor="link"
                    @click.prevent="adding = true"
                >
                    <open-icon glyph="plus" />
                </default-button>
            </div>
        </div>
        <div class="card-body">
            <data-table
                id="license-list"
                entityName="license:::licenses"
                :columns="visible_columns"
                :rows="filtered_rows"
                :search="state.search"
                :total="state.total"
                :found="state.found"
                :current-page="state.page"
                :total-pages="nb_pages"
                :can-add="true"
                @sort="sort_by"
                @search="search"
                @clear-search="clear_search"
                @page="goto_page"
                @add="adding = true"
                @click="view_object"
            >
                <template
                    v-slot:summary="{ row }"
                >
                    <h6 v-if="!!licenseId">{{ row.site_domain }}</h6>
                    <h6 v-else>{{ row.license_name }}</h6>
                    <div class="mt-2">
                        <div v-if="row.sale_price === null">
                            <label
                                class="form-label"
                            >
                                {{ (row.proxied_monthly_sale_price * 12) | nibnut.currency }} / {{ $root.translate("year") }}
                            </label>
                            <default-button
                                flavor="link"
                                size="xs"
                                @click.prevent.stop="set_row_custom_price(row)"
                            >
                                {{ $root.translate("Set Custom price") }}
                            </default-button>
                        </div>
                        <div v-else>
                            <form-input
                                :id="`${row.id}-sale_price`"
                                name="sale_price"
                                :value="row.sale_price"
                                glyph="dollar-sign"
                                glyph-position="left"
                                :required="false"
                                @click.stop
                                @input="save_row(row, ...arguments)"
                            >
                                <template v-slot:right_addon>
                                    <span class="input-group-addon">/</span>
                                    <select
                                        v-model="row.sale_price_repeat"
                                        class="form-select"
                                        @input="save_row(row, $event.target.value, 'sale_price_repeat')"
                                        @click.stop
                                    >
                                        <option
                                            v-for="repeat in price_repeats"
                                            :key="repeat.id"
                                            :value="repeat.id"
                                        >
                                            {{ repeat.period }}
                                        </option>
                                    </select>
                                </template>
                            </form-input>
                            <default-button
                                flavor="link"
                                size="xs"
                                @click.prevent.stop="save_row(row, null, 'sale_price')"
                            >
                                {{ $root.translate("Use Default Price") }}
                            </default-button>
                        </div>
                    </div>
                    <form-date-input
                        v-if="!!row.proxied_monthly_sale_price"
                        :id="`${row.id}-charge_on`"
                        name="charge_on"
                        v-model="row.charge_on"
                        position="bottom"
                        :required="false"
                        :min="$moment().startOf('day')"
                        class="mt-2"
                        @click.stop
                        @input="save_row_date(row, ...arguments)"
                    />
                    <default-button
                        color="error"
                        :block="true"
                        class="mt-4"
                        @click.prevent.stop="confirm_row_delete(row)"
                    >
                        <open-icon glyph="trash" class="mr-2" />
                        {{ $root.translate("Delete") }}
                    </default-button>
                </template>
                <template
                    v-slot:tbody="{ row, field }"
                >
                    <div v-if="field === 'name'">
                        <span v-if="!!licenseId">{{ row.site_domain }}</span>
                        <span v-else>{{ row.license_name }}</span>
                    </div>
                    <div v-else-if="field === 'sale_price'">
                        <div v-if="row.sale_price === null">
                            <label
                                class="form-label"
                            >
                                {{ (row.proxied_monthly_sale_price * 12) | nibnut.currency }} / {{ $root.translate("year") }}
                            </label>
                            <default-button
                                flavor="link"
                                size="xs"
                                @click.prevent.stop="set_row_custom_price(row)"
                            >
                                {{ $root.translate("Set Custom price") }}
                            </default-button>
                        </div>
                        <div v-else>
                            <form-input
                                :id="`${row.id}-sale_price`"
                                name="sale_price"
                                :value="row.sale_price"
                                glyph="dollar-sign"
                                glyph-position="left"
                                :required="false"
                                @click.stop
                                @input="save_row(row, ...arguments)"
                            >
                                <template v-slot:right_addon>
                                    <span class="input-group-addon">/</span>
                                    <select
                                        v-model="row.sale_price_repeat"
                                        class="form-select"
                                        @input="save_row(row, $event.target.value, 'sale_price_repeat')"
                                        @click.stop
                                    >
                                        <option
                                            v-for="repeat in price_repeats"
                                            :key="repeat.id"
                                            :value="repeat.id"
                                        >
                                            {{ repeat.period }}
                                        </option>
                                    </select>
                                </template>
                            </form-input>
                            <default-button
                                flavor="link"
                                size="xs"
                                @click.prevent.stop="save_row(row, null, 'sale_price')"
                            >
                                {{ $root.translate("Use Default Price") }}
                            </default-button>
                        </div>
                    </div>
                    <div v-else-if="field === 'charge_on_as_string'">
                        <form-date-input
                            v-if="!!row.proxied_monthly_sale_price"
                            :id="`${row.id}-charge_on`"
                            name="charge_on"
                            v-model="row.charge_on"
                            position="bottom"
                            :required="false"
                            :min="$moment().startOf('day')"
                            @click.native.stop
                            @input="save_row_date(row, ...arguments)"
                        />
                    </div>
                    <div v-else-if="field === '_buttons'" class="text-right">
                        <default-button
                            flavor="link"
                            @click.prevent.stop="confirm_row_delete(row)"
                        >
                            <open-icon glyph="trash" />
                        </default-button>
                    </div>
                    <span v-else>{{ row[field] }}</span>
                </template>
            </data-table>

            <record-picker
                :show.sync="adding"
                :entity="autosuggest_entity"
                :context-id="licenseId || siteId"
                @save="link_license"
            >
                <template v-slot:title>
                    <span v-if="!!licenseId" class="h5">{{ $root.translate("Add a site to a license") }}</span>
                    <span v-else class="h5">{{ $root.translate("Add a license to a site") }}</span>
                </template>
                <template v-slot:label>
                    <span v-if="!!licenseId">{{ $root.translate("Site") }}</span>
                    <span v-else>{{ $root.translate("License") }}</span>
                </template>
                <template v-slot:hint>
                    <span v-if="!!licenseId">{{ $root.translate("Type the domain of an existing site") }}</span>
                    <span v-else>{{ $root.translate("Type the name of an existing license") }}</span>
                </template>
                <template v-slot:save>
                    {{ $root.translate("Add") }}
                </template>
            </record-picker>

            <confirmation
                v-if="!!confirming"
                v-bind="confirmation_props"
                :waiting="deleting_record"
                @cancel="done_confirming"
                @confirmed="confirmed"
            />
        </div>
    </div>
</template>

<script>
import { is_remote_data_table_source, handles_saving, confirms } from "@/nibnut/mixins"

import { FormInput, FormDateInput, DefaultButton, OpenIcon } from "@/nibnut/components"
import RecordPicker from "../dialogs/RecordPicker"

export default {
    name: "SiteLicenceList",
    mixins: [is_remote_data_table_source, handles_saving, confirms],
    components: {
        FormInput,
        FormDateInput,
        DefaultButton,
        OpenIcon,
        RecordPicker
    },
    watch: {
        licenseId: "refresh",
        siteId: "refresh"
    },
    methods: {
        pre_load () {
            if(this.licenseId) {
                this.set_state_values({
                    page: 1,
                    license_id: this.licenseId,
                    site_id: 0
                })
            } else if(this.siteId) {
                this.set_state_values({
                    page: 1,
                    license_id: 0,
                    site_id: this.siteId
                })
            }
        },
        link_license (record_id) {
            this.adding = false

            const data = {
                license_id: this.licenseId,
                site_id: this.siteId,
                fields: this.fields
            }
            if(this.licenseId) data.site_id = record_id
            else data.license_id = record_id

            this.$store.dispatch("CREATE_RECORD", {
                entity: this.entity,
                data
            }).then(() => {
                this.refresh()
            }).catch(this.receive_error)
        },
        view_object (row) {
            const entity = this.autosuggest_entity
            this.$router.push({ name: `${entity}.edit`, params: { id: row[`${entity}_id`] } })
        },
        save_data_for_record_id (entity, record_id, data) {
            if(!record_id) return

            const fields = Object.keys(data)
            fields.forEach(field => {
                if(this.saving_field_ids.indexOf(field) < 0) this.saving_field_ids.push(field)
                this.clear_error(field)
            })

            return this.$store.dispatch("RECORD_SAVE", {
                entity: entity,
                id: record_id,
                data: {
                    ...data,
                    fields: this.fields
                }
            }).catch(this.receive_error).then(() => {
                fields.forEach(field => {
                    const index = this.saving_field_ids.indexOf(field)
                    if(index >= 0) this.saving_field_ids.splice(index, 1)
                })
            })
        },
        save_row (row, value, field) {
            this.save_field_for_record_id(this.entity, row.id, value, field)
        },
        save_row_date (row, date, field, format = "YYYY-MM-DD") {
            const value = this.$moment.isMoment(date) ? date.format(format) : null
            this.save_row(row, value, field)
        },
        set_row_custom_price (row) {
            this.$store.dispatch("RECORD_ACTION", {
                entity: this.entity,
                id: row.id,
                action: "custom_price",
                method: "post"
            })
        },
        confirm_row_delete (row) {
            let title = null
            let message = null
            if(this.licenseId) {
                title = this.$root.translate("Delete License for {name}", { name: row.site_domain })
                message = this.$root.translate("Do you really want to delete the license for this site? This will simply break the link between the license and the site. The license and the site will be left untouched. Still, there is no undo...")
            } else {
                title = this.$root.translate("Delete {name} for this site", { name: row.license_name })
                message = this.$root.translate("Do you really want to delete this license for this site? This will simply break the link between the license and the site. The license and the site will be left untouched. Still, there is no undo...")
            }
            this.deleting_record_id = row.id

            this.confirm(
                {
                    type: "error",
                    title,
                    message,
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") {
                this.deleting_record = true
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: this.entity,
                        id: this.deleting_record_id
                    }
                ).then(() => {
                    this.refresh()
                    this.done_confirming()
                }).catch(this.receive_error).then(() => {
                    this.deleting_record = false
                })
            } else this.done_confirming()
        }
    },
    computed: {
        can_refresh () {
            return this.profile_id && (!!this.licenseId || !!this.siteId)
        },
        fields () {
            const fields = [
                "fieldset::sublist"
            ]

            return fields
        },
        autosuggest_entity () {
            if(this.licenseId) return "site"
            return "license"
        },
        price_repeats () {
            const for_life_id = this.constants("repeats", "REPEAT_LIFETIME").id
            return Object.values(this.constants("repeats")).filter(repeat => repeat.id !== for_life_id)
        }
    },
    props: {
        licenseId: {
            type: Number,
            default: 0
        },
        siteId: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            entity: "site_license",

            columns: {
                name: { label: "Name", sort: false, cell_class: { "col-6": true } },
                sale_price: { label: "Price", sort: false, cell_class: { "col-3": true } },
                charge_on_as_string: { label: "Next Bill", sort: false, cell_class: { "col-2": true } },
                _buttons: { label: " ", sort: false, cell_class: { "col-1": true } }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: null,
                sort_dir: null,
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                site_id: 0,
                license_id: 0
            },

            adding: false,
            deleting_record_id: 0,
            deleting_record: false
        }
    }
}
</script>

<style lang="scss">
#site-license-list {
    & > h6 {
        margin-bottom: 0;
    }
    table.table {
        td {
            vertical-align: top;
        }
    }
}
</style>
