<template>
    <div v-if="!!edited_record" class="columns">
        <div class="column col-4 col-lg-6 col-md-12 mb-2">
            <div class="card">
                <div class="card-header">
                    <div class="card-title h5">{{ $root.translate("Administrative Notifications") }}</div>
                </div>
                <div class="card-body">
                    <form-input
                        id="administration_notification_email"
                        name="administration_notification_email"
                        v-model="edited_record.administration_notification_email"
                        :required="false"
                        :saving="saving('administration_notification_email')"
                        :error="has_error('administration_notification_email')"
                        @input="save"
                    >
                        <template v-slot:label>{{ $root.translate("Send to") }}</template>
                    </form-input>

                    <div class="columns">
                        <div class="column col-6 col-md-12">
                            <form-input
                                id="maintenance_plan_renewal_warning_delay"
                                name="maintenance_plan_renewal_warning_delay"
                                type="number"
                                v-model="edited_record.maintenance_plan_renewal_warning_delay"
                                :min="1"
                                :required="false"
                                :saving="saving('maintenance_plan_renewal_warning_delay')"
                                :error="has_error('maintenance_plan_renewal_warning_delay')"
                                @input="save"
                            >
                                <template v-slot:label>{{ $root.translate("Maint. Plan Due for Billing") }}</template>
                                <template v-slot:right_addon><span class="input-group-addon">{{ $root.translate("days before") }}</span></template>
                            </form-input>
                        </div>
                        <div class="column col-6 col-md-12">
                            <form-input
                                id="license_billing_due_warning_delay"
                                name="license_billing_due_warning_delay"
                                type="number"
                                v-model="edited_record.license_billing_due_warning_delay"
                                :min="1"
                                :required="false"
                                :saving="saving('license_billing_due_warning_delay')"
                                :error="has_error('license_billing_due_warning_delay')"
                                @input="save"
                            >
                                <template v-slot:label>{{ $root.translate("License Due for Billing") }}</template>
                                <template v-slot:right_addon><span class="input-group-addon">{{ $root.translate("days before") }}</span></template>
                            </form-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="column col-4 col-lg-6 col-md-12 mb-2">
            <div class="card">
                <div class="card-header">
                    <div class="card-title h5">{{ $root.translate("Virtual Assistant Notifications") }}</div>
                </div>
                <div class="card-body">
                    <form-input
                        id="va_notification_email"
                        name="va_notification_email"
                        v-model="edited_record.va_notification_email"
                        :required="false"
                        :saving="saving('va_notification_email')"
                        :error="has_error('va_notification_email')"
                        @input="save"
                    >
                        <template v-slot:label>{{ $root.translate("Send to") }}</template>
                    </form-input>

                    <form-select
                        id="client_contact_alert_delay"
                        name="client_contact_alert_delay"
                        v-model="edited_record.client_contact_alert_delay"
                        :data-source="client_contact_delays"
                        :required="false"
                        :saving="saving('client_contact_alert_delay')"
                        :error="has_error('client_contact_alert_delay')"
                        @input="save"
                    >
                        <template v-slot:label>{{ $root.translate("Client Contact Reminder") }}</template>
                    </form-select>
                </div>
            </div>
        </div>

        <div class="column col-4 col-lg-6 col-md-12 mb-2">
            <div class="card">
                <div class="card-header">
                    <div class="card-title h5">{{ $root.translate("Technical Notifications") }}</div>
                </div>
                <div class="card-body">
                    <form-input
                        id="notification_email"
                        name="notification_email"
                        v-model="edited_record.notification_email"
                        :required="false"
                        :saving="saving('notification_email')"
                        :error="has_error('notification_email')"
                        @input="save"
                    >
                        <template v-slot:label>{{ $root.translate("Send to") }}</template>
                    </form-input>
                    <h6 class="mt-4">
                        {{ $root.translate("Domain Expiration") }}
                    </h6>
                    <div class="columns">
                        <div class="column col-6 col-sm-12">
                            <form-input
                                id="domain_warning_delay"
                                name="domain_warning_delay"
                                type="number"
                                v-model="edited_record.domain_warning_delay"
                                :min="1"
                                :required="false"
                                :saving="saving('domain_warning_delay')"
                                :error="has_error('domain_warning_delay')"
                                @input="save"
                            >
                                <template v-slot:right_addon><span class="input-group-addon">{{ $root.translate("days before") }}</span></template>
                            </form-input>
                        </div>
                        <div class="column col-6 col-sm-12">
                            <form-input
                                id="domain_warning_urgent_delay"
                                name="domain_warning_urgent_delay"
                                type="number"
                                v-model="edited_record.domain_warning_urgent_delay"
                                :min="1"
                                :max="edited_record.domain_warning_delay - 1"
                                :required="false"
                                :saving="saving('domain_warning_urgent_delay')"
                                :error="has_error('domain_warning_urgent_delay')"
                                @input="save"
                            >
                                <template v-slot:right_addon><span class="input-group-addon">{{ $root.translate("days before") }}</span></template>
                            </form-input>
                        </div>
                    </div>

                    <h6 class="mt-4">
                        {{ $root.translate("SSL Certificate Expiration") }}
                    </h6>
                    <div class="columns">
                        <div class="column col-6 col-sm-12">
                            <form-input
                                id="ssl_certificate_warning_delay"
                                name="ssl_certificate_warning_delay"
                                type="number"
                                v-model="edited_record.ssl_certificate_warning_delay"
                                :min="1"
                                :required="false"
                                :saving="saving('ssl_certificate_warning_delay')"
                                :error="has_error('ssl_certificate_warning_delay')"
                                @input="save"
                            >
                                <template v-slot:right_addon><span class="input-group-addon">{{ $root.translate("days before") }}</span></template>
                            </form-input>
                        </div>
                        <div class="column col-6 col-sm-12">
                            <form-input
                                id="ssl_certificate_warning_urgent_delay"
                                name="ssl_certificate_warning_urgent_delay"
                                type="number"
                                v-model="edited_record.ssl_certificate_warning_urgent_delay"
                                :min="1"
                                :max="edited_record.ssl_certificate_warning_delay - 1"
                                :required="false"
                                :saving="saving('ssl_certificate_warning_urgent_delay')"
                                :error="has_error('ssl_certificate_warning_urgent_delay')"
                                @input="save"
                            >
                                <template v-slot:right_addon><span class="input-group-addon">{{ $root.translate("days before") }}</span></template>
                            </form-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import is_settings_panel from "./IsSettingsPanel"

import { FormInput, FormSelect } from "../../nibnut/components"

export default {
    name: "Notifications",
    mixins: [is_settings_panel],
    components: {
        FormInput,
        FormSelect
    },
    computed: {
        client_contact_delays () {
            return [
                { id: 3, name: this.$root.translate("Every 3 months") },
                { id: 6, name: this.$root.translate("Every 6 months") },
                { id: 9, name: this.$root.translate("Every 9 months") },
                { id: 12, name: this.$root.translate("Every 12 months") }
            ]
        }
    }
}
</script>
