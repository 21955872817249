<template>
    <section
        v-if="!!message"
        :class="{ [`message-type-${message.type}`]: true }"
        class="ticket-message p-6"
    >
        <div v-if="message.type === 'lineitem'" class="columns">
            <div class="column text-small">{{ message.action.text }}</div>
            <div class="column col-auto text-small text-gray text-right">
                <div>{{ message.createdAt | nibnut.smart_date("MMM D, YYYY") }}</div>
            </div>
        </div>
        <div v-else class="columns">
            <div class="column col-auto">
                <figure class="avatar avatar-xl">
                    <open-icon v-if="!message.customer || !message.customer.photoUrl" glyph="user" size="2x" />
                    <img v-else :src="message.customer.photoUrl" :alt="string_concat(' ', message.customer.firstName, message.customer.lastName)">
                </figure>
            </div>
            <div class="column">
                <h5 v-if="!!message.customer">{{ string_concat(' ', message.customer.firstName, message.customer.lastName) }}</h5>
                <div
                    v-for="(addresses, type) in to_cc_bcc"
                    :key="type"
                    class="text-xsmall"
                >
                    <strong>{{ type | nibnut.ucwords }}</strong>:&nbsp;{{ addresses }}
                </div>
            </div>
            <div class="column col-auto text-small text-gray text-right">
                <div>{{ message.createdAt | nibnut.smart_date("MMM D, YYYY") }}</div>
                <div>{{ message.assignedTo ? string_concat(" ", message.assignedTo.firstName, message.assignedTo.lastName) : translate("Anyone") }}<span v-if="!!message.status && !message.status.match(/nochange/i)">, {{ message.status | nibnut.ucwords }}</span></div>
            </div>
        </div>
        <div v-if="message.type !== 'lineitem'" v-html="message.body" class="mt-8"></div>
        <div v-if="!!message._embedded && !!message._embedded.attachments && !!message._embedded.attachments.length">
            <open-icon glyph="paperclip" class="text-gray" />
            <span
                v-for="attachment in message._embedded.attachments"
                :key="attachment.id"
                class="chip"
            >
                <base-link :href="attachment.fileUrl" target="message-image-viewer">
                    {{ attachment.fileName }} ({{ attachment.size | nibnut.filesize }})
                </base-link>
            </span>
        </div>
    </section>
</template>

<script>
import { string_utilities } from "@/nibnut/mixins"

import {
    BaseLink,
    OpenIcon
} from "@/nibnut/components"

export default {
    name: "Message",
    mixins: [string_utilities],
    components: {
        BaseLink,
        OpenIcon
    },
    computed: {
        to_cc_bcc () {
            const to_cc_bcc = {}
            if(this.message) {
                ["to", "cc", "bcc"].forEach(type => {
                    if(!!this.message[type] && !!this.message[type].length) to_cc_bcc[type] = this.message[type].join(", ")
                })
            }
            return to_cc_bcc
        }
    },
    props: {
        message: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.ticket-message {
    border-left: 4px solid transparent;
    border-bottom: 1px solid $border-color;
    &.message-type-lineitem {
        background-color: $gray-color-light;
    }
    &.message-type-message {
        background-color: lighten($brand-color-secondary, 61%);
        border-left-color: $brand-color-secondary;
    }
    &.message-type-note {
        background-color: lighten($brand-color, 48%);
        border-left-color: $brand-color;
    }
}
</style>
