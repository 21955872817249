<template>
    <form-group
        v-bind="form_group_props"
    >
        <template v-slot:read_only><slot name="read_only"></slot></template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>
        <default-tag-input
            ref="field"
            :id="id"
            :name="name"
            :value="value"
            :id-field="idField"
            :label-field="labelField"
            :data-source="dataSource"
            :data-source-additional-data="dataSourceAdditionalData"
            :show-all="showAll"
            :ad-hoc="adHoc"
            :required="required"
            :disabled="disabled"
            @input="pick"
            @create="create"
        />
    </form-group>
</template>

<script>
import is_nibnut_component from "../../mixins/IsNibnutComponent"

import FormGroup from "./FormGroup"
import DefaultTagInput from "./DefaultTagInput"

export default {
    name: "FormTagInput",
    mixins: [is_nibnut_component],
    components: {
        FormGroup,
        DefaultTagInput
    },
    methods: {
        pick (value, field, option) {
            if(this.editable) this.$emit("input", value, field, option)
        },
        create (label, field) {
            if(this.editable) this.$emit("create", label, field)
        }
    },
    computed: {
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        }
    },
    props: {
        ...DefaultTagInput.props,
        idField: {
            type: String,
            default: "id"
        },
        labelField: {
            type: String,
            default: "name"
        },
        emptyValue: {
            default: 0
        },
        emptyLabel: {
            type: String,
            default: ""
        },
        dataSource: { // either an array of options (locally sourced) OR an entity name (remote source ; api endpoint /<entity>/autosuggest/<name>)
            validator: prop => !!prop && (Array.isArray(prop) || (typeof prop === "string")),
            required: true,
            default () {
                return []
            }
        },
        adHoc: {
            type: Boolean,
            default: false
        },
        showAll: {
            type: Boolean,
            default: true
        },
        minOptionQueryLength: {
            type: Number,
            default: 3
        },
        editable: {
            type: Boolean,
            default: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    }
}
</script>
