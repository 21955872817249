import Vue from "vue"
import VueRouter from "vue-router"
import VueCookie from "vue-cookie"
import VueMq from "vue-mq"
import * as Sentry from "@sentry/browser"
import { Vue as VueIntegration } from "@sentry/integrations"
import VueClipboard from "vue-clipboard2"
import Vue2TouchEvents from "vue2-touch-events"
// import vuexI18n from "vuex-i18n"
// import translationsFr from "./lang/fr.json"
import moment from "moment"
import numeral from "numeral"

import "./app-sw"
import router from "./router"
import store from "./store"

import App from "./App.vue"

/*
 * TOP OF MIND:
 * https://alligator.io/vuejs/vue-async-components-load-error/
 * https://vuejsdevelopers.com/2018/08/27/vue-js-form-handling-vuelidate/
 */

Vue.config.ignoredElements = ["ipp:connectToIntuit"]

Vue.use(VueRouter)
Vue.use(VueCookie)
Vue.use(VueMq, {
    breakpoints: {
        xs: 481,
        sm: 551,
        md: 841,
        lg: 961,
        tl: 1025, // tablet landscape
        xl: 1281,
        fred: 1330,
        xxl: 1441
    }
})
Vue.use(VueClipboard)
Vue.use(Vue2TouchEvents)
Vue.use(require("vue-shortkey"), { prevent: ["input", "textarea", ".ProseMirror"] })

// Vue.use(vuexI18n.plugin, store)
const renderFn = () => {
    const identifiers = ["{", "}"]
    // construct a regular expression ot find variable substitutions, i.e. {test}
    const matcher = new RegExp("" + identifiers[0] + "{1}(\\w{1}|\\w.+?)" + identifiers[1] + "{1}", "g")

    // define the replacement function
    const replace = (translation, replacements) => {
        // check if the object has a replace property
        if(!translation.replace) return translation
        return translation.replace(matcher, (placeholder) => {
            // remove the identifiers (can be set on the module level)
            const key = placeholder.replace(identifiers[0], "").replace(identifiers[1], "")
            if(replacements[key] !== undefined) return replacements[key]
            return placeholder
        })
    }
    const isArray = (object) => {
        return !!object && (Array === object.constructor)
    }

    // the render function will replace variable substitutions and prepare the
    // translations for rendering
    return (translation, replacements = {}, pluralization = null) => {
        // get the type of the property
        const objType = typeof translation
        const pluralizationType = typeof pluralization

        const resolvePlaceholders = () => {
            if(isArray(translation)) {
                // replace the placeholder elements in all sub-items
                return translation.map((item) => {
                    return replace(item, replacements, false)
                })
            } else if(objType === "string") {
                return replace(translation, replacements, true)
            }
        }

        // return translation item directly
        if(pluralization === null) return resolvePlaceholders()

        // check if pluralization value is countable
        if(pluralizationType !== "number") return resolvePlaceholders()

        // --- handle pluralizations ---
        // replace all placeholders
        const resolvedTranslation = resolvePlaceholders()
        // initialize pluralizations
        let pluralizations = null
        // if translations are already an array and have more than one entry,
        // we will not perform a split operation on :::
        if(isArray(resolvedTranslation) && (resolvedTranslation.length > 0)) pluralizations = resolvedTranslation
        else {
            // split translation strings by ::: to find create the pluralization array
            pluralizations = resolvedTranslation.split(":::")
        }
        // determine the pluralization version to use by locale
        const index = (pluralization !== 1) ? 1 : 0
        // check if the specified index is present in the pluralization
        if(typeof pluralizations[index] === "undefined") {
            // return the first element of the pluralization by default
            return pluralizations[0].trim()
        }
        // return the requested item from the pluralizations
        return pluralizations[index].trim()
    }
}
const render = renderFn()
Vue.prototype.translate = function () {
    // taken from vuexI18n
    const args = arguments
    const count = args.length

    // initialize options
    let key = ""
    let options = {}
    let pluralization = null

    // check if a default value was specified and fill options accordingly
    if(count >= 2 && typeof args[1] === "string") {
        key = args[0]
        if(count > 2) options = args[2]
        if(count > 3) pluralization = args[3]
    } else {
        key = args[0]
        // default value was not specified and is therefore the same as the key
        if(count > 1) options = args[1]
        if(count > 2) pluralization = args[2]
    }

    return render(key, options, pluralization)
}

/*
moment.updateLocale("en", {
    longDateFormat : {
        LT: "h:mm A",
        LTS: "h:mm:ss A",
        L: "MM/DD/YYYY",
        LL: "MMMM Do", // year added only if needed
        LLL: "MMMM Do, YYYY",
        LLLL: "dddd, MMMM Do, YYYY"
    }
})
moment.updateLocale("fr", {
    longDateFormat : {
        LT: "H:mm",
        LTS: "H:mm:ss",
        L: "DD/MM/YYYY",
        LL: "Do MMMM", // year added only if needed
        LLL: "Do MMMM YYYY",
        LLLL: "dddd le Do MMMM YYYY"
    }
})
*/
moment.locale("en")
Vue.prototype.$moment = moment

/*
numeral.register("locale", "fr", {
    delimiters: {
        thousands: " ",
        decimal: ","
    },
    abbreviations: {
        thousand: "k",
        million: "m",
        billion: "b",
        trillion: "t"
    },
    ordinal : function (number) {
        return number === 1 ? "er" : "ème"
    },
    currency: {
        symbol: "$"
    }
})
*/
numeral.locale("en")
Vue.prototype.$numeral = numeral

const notifies = {
    methods: {
        $notify (message, type = "primary", dismiss_after = 7000, message_id = null) {
            this.$store.dispatch("SYSTEM_MESSAGE", {
                type,
                message,
                dismiss_after,
                message_id
            })
        },
        $error (message, dismiss_after = 7000, message_id = null) {
            this.$notify(message, "error", dismiss_after, message_id)
        },
        $warn (message, dismiss_after = 7000, message_id = null) {
            this.$notify(message, "warning", dismiss_after, message_id)
        },
        $success (message, dismiss_after = 7000, message_id = null) {
            this.$notify(message, "success", dismiss_after, message_id)
        }
    }
}
Vue.mixin(notifies)

/*
Vue.i18n.add("fr", translationsFr)
Vue.i18n.set("en") // **** cookie value OR default?
*/

// === FILTERS: v-bind="'I\'m good' | nibnut.slugify" OR {{ 'I\'m good' | nibnut.slugify }} OR this.nibnut_filter("nibnut.slugify", ["I'm good"])
Vue.mixin({
    methods: {
        nibnut_filter (filter_name, filter_arguments) {
            return this.$options.filters[filter_name].apply(this, filter_arguments)
        }
    }
})
Vue.filter("nibnut.slugify", (value, replacement = "-") => {
    if(!value) return value
    return value.toLowerCase().replace(/[^a-zA-Z\-0-9]/g, replacement)
})
Vue.filter("nibnut.smart_date", (date, format = "YYYY-MM-DD", short = false) => {
    date = moment(date)
    if(date.diff(moment(), "days") <= 7) return date.fromNow(short)
    return date.format(format)
})
Vue.filter("nibnut.date_from_now", (date, short = false) => {
    return moment(date).fromNow(short)
})
Vue.filter("nibnut.date", (date, format = "YYYY-MM-DD") => { // YYYY-MM-DD HH:mm:ss
    if(!date) return ""
    return moment(date).format(format)
})
Vue.filter("nibnut.duration", (duration) => {
    return moment.duration(duration).humanize()
})
Vue.filter("nibnut.number", (number, format = "0,0.0", default_value = null) => {
    if(!number && default_value) return default_value
    return numeral(number).format(format)
})
const KILOBYTE = 1024
const MEGABYTE = 1024 * KILOBYTE
const GIGABYTE = 1024 * MEGABYTE
Vue.filter("nibnut.filesize", (bytes, default_value = null) => {
    if(!bytes && default_value) return default_value
    let units = "bytes"
    if(bytes >= GIGABYTE) {
        bytes /= GIGABYTE
        units = "GB"
    } else if(bytes >= MEGABYTE) {
        bytes /= MEGABYTE
        units = "MB"
    } else if(bytes >= KILOBYTE) {
        bytes /= KILOBYTE
        units = "KB"
    }
    return `${numeral(Math.round(bytes)).format("0,0")} ${units}`
})
Vue.filter("nibnut.currency", (number, format = "0,0.00", default_value = null) => {
    if(!number && default_value) return default_value
    const amount = numeral(number).format(format)
    return `$ ${amount}`
})
Vue.filter("nibnut.number-field", (number, editable, format, default_value) => {
    if(editable) return number
    return this.nibnut_filter("nibnut.number", [number, format, default_value])
})
Vue.filter("nibnut.phone", (phone) => {
    if(!phone) return ""
    const matches = phone.match(/^(\d{3})(\d{3})(\d{4})$/)
    if(matches) return `(${matches[1]}) ${matches[2]}-${matches[3]}`
    return ""
})
Vue.filter("nibnut.mailto", (email) => {
    if(!email) return ""
    return "mailto:" + email
})
Vue.filter("nibnut.telto", (phone) => {
    if(!phone) return ""
    return "tel:" + phone.replace(/[^0-9+\-,]+/g, "")
})
Vue.filter("nibnut.ucwords", (text) => {
    if(!text) return text
    return text.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter) => {
        return letter.toUpperCase()
    })
})
Vue.filter("nibnut.smart_truncate", (text, max_length, ellipsis = "...") => {
    const length = text.length
    if(length <= max_length) return text
    return text.substring(0, max_length - ellipsis.length) + ellipsis
})

// === DIRECTIVES
Vue.directive("nibnut-on-scroll", {
    inserted: (element, binding) => {
        const listener = (event) => {
            if(binding && !!binding.value && binding.value(event, element)) window.removeEventListener("scroll", listener)
        }
        window.addEventListener("scroll", listener)
    }
})

if(process.env.NODE_ENV === "development") {
    Vue.config.debug = true
    Vue.config.devtools = true
}
if(process.env.VUE_APP_SENTRY_DSN && (process.env.VUE_APP_SENTRY_DSN !== "null")) {
    Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
            new VueIntegration({ Vue, attachProps: true, logErrors: (process.env.NODE_ENV !== "production") })
        ]
    })
}

window.nibnut_app = new Vue({
    el: "#app",
    store,
    router,
    render: h => h(App)
})
