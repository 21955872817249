<template>
<div>
    <data-table
        id="logs-list"
        :columns="visible_columns"
        :rows="filtered_rows"
        :search="state.search"
        :searchable="false"
        :total="state.total"
        :found="state.found"
        :current-page="state.page"
        :total-pages="nb_pages"
        @sort="sort_by"
        @search="search"
        @clear-search="clear_search"
        @page="goto_page"
        @click="show_log"
    >
        <template
            v-slot:controls
        >
            <div>
                <form-select
                    id="type"
                    name="type"
                    :value="state.filter"
                    :data-source="types_filter"
                    :required="false"
                    :empty-value="0"
                    :empty-label="$root.translate('Any Type')"
                    @input="filter('type', $event)"
                />
            </div>
            <div>
                <form-select
                    id="status"
                    name="status"
                    :value="state.status"
                    :data-source="status_filters"
                    :required="false"
                    :empty-value="null"
                    :empty-label="$root.translate('Any Status')"
                    @input="filter_by('status', $event)"
                />
            </div>
        </template>
        <template
            v-slot:summary="{ row }"
        >
            <h6>
                <open-icon v-if="row.type === 'check_ssl'" glyph="lock" :title="$root.translate('SSL Certificate')" />
                <open-icon v-else glyph="link" :title="$root.translate('Website')" />
                {{ log_type_name(row) }}
            </h6>
            <div class="text-small">
                <span class="text-gray">{{ row.checked_at | nibnut.date }} @ {{ row.checked_at | nibnut.date("HH:mm:ss") }}</span>
                &nbsp;&bull;&nbsp;
                <span v-if="!row.computed_error_count && !row.computed_warning_count" class="text-success">
                    <open-icon glyph="check-circle" /> {{ $root.translate("All good!") }}
                </span>
                <span
                    v-else-if="!!row.computed_error_count"
                    :data-tooltip="small_screen ? false : errors_tooltips"
                    :class="{ tooltip: !small_screen }"
                    class="text-error"
                >
                    <open-icon glyph="times-circle" /> {{ $root.translate("{number} error:::{number} errors", { number: row.computed_error_count }, row.computed_error_count) }}
                </span>
                <span
                    v-else-if="!!row.computed_warning_count"
                    :data-tooltip="small_screen ? false : errors_tooltips"
                    :class="{ tooltip: !small_screen }"
                    class="text-warning"
                >
                    <open-icon glyph="exclamation-circle" /> {{ $root.translate("{number} warning:::{number} warnings", { number: row.computed_warning_count }, row.computed_warning_count) }}
                </span>
            </div>
                <default-button
                    :title="$root.translate('View Report')"
                    size="sm"
                    class="mt-4"
                    @click.prevent.stop
                >
                    {{ $root.translate('View Report') }}
                </default-button>
        </template>
        <template
            v-slot:tbody="{ row, field }"
        >
            <div v-if="field === 'type'">
                <open-icon v-if="row.type === 'check_ssl'" glyph="lock" :title="$root.translate('SSL Certificate')" />
                <open-icon v-else glyph="link" :title="$root.translate('Website')" />
                {{ log_type_name(row) }}
            </div>
            <div v-else-if="field === 'checked_at'">
                {{ row.checked_at | nibnut.date }} @ {{ row.checked_at | nibnut.date("HH:mm:ss") }}
            </div>
            <div
                v-else-if="field === 'status'"
            >
                <span v-if="!row.computed_error_count && !row.computed_warning_count" class="text-success">
                    <open-icon glyph="check-circle" /> {{ $root.translate("All good!") }}
                </span>
                <span
                    v-else-if="!!row.computed_error_count"
                    class="text-error"
                >
                    <open-icon glyph="times-circle" /> {{ $root.translate("{number} error:::{number} errors", { number: row.computed_error_count }, row.computed_error_count) }}
                </span>
                <span
                    v-else-if="!!row.computed_warning_count"
                    class="text-warning"
                >
                    <open-icon glyph="exclamation-circle" /> {{ $root.translate("{number} warning:::{number} warnings", { number: row.computed_warning_count }, row.computed_warning_count) }}
                </span>
            </div>
            <span v-else>{{ row[field] }}</span>
        </template>
    </data-table>

    <modal-dialog
        id="log-viewer"
        :show.sync="viewing"
    >
        <template v-slot:title>
            <span class="h5">{{ log_type_name(viewed_log) }} {{ viewed_log.checked_at | nibnut.date }} @ {{ viewed_log.checked_at | nibnut.date("HH:mm:ss") }}</span>
        </template>

        <table v-if="!!viewed_log && !!viewed_log.report" class="table">
            <tbody>
                <tr
                    v-for="test in viewed_log.report"
                    :key="test.id"
                >
                    <td>
                        {{ test_name(test.id) }}
                    </td>
                    <td
                        :class="{ [test_error_class(test)]: true }"
                    >
                        <span v-if="!!test.message">{{ test.message }}</span>
                        <span v-else>{{ $root.translate("All good!") }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </modal-dialog>
</div>
</template>

<script type="text/javascript">
import { is_remote_data_table_source, misc_utilities } from "@/nibnut/mixins"

import { ModalDialog, FormSelect, DefaultButton, OpenIcon } from "@/nibnut/components"

export default {
    name: "Logs",
    mixins: [is_remote_data_table_source, misc_utilities],
    components: {
        ModalDialog,
        FormSelect,
        DefaultButton,
        OpenIcon
    },
    methods: {
        refresh () {
            if(!this.entity) console.error(this.$root.translate("IsRemoteDataTableSource has no entity defined."))

            if(this.siteId && (this.is_public_page || this.profile_id)) {
                const query = {
                    ...this.state,
                    site_id: this.siteId,
                    // scope: 3,
                    fields: this.fields,
                    relation_ids: this.relation_ids
                }

                this.loading = true
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: this.entity,
                        query
                    }
                ).then(response => {
                    this.current_records_ids = response.record_ids

                    this.set_state_values({
                        total: response.total || 0,
                        found: response.found || 0
                    })

                    this.post_load(response)
                }).catch(error => {
                    this.$error(error.message)
                }).then(() => {
                    this.loading = false
                })
            }
        },
        log_type_name (log) {
            const types = this.constants("monitor_types")
            const type = Object.values(types).find(type => type.id === log.type)
            if(type) return type.name
            return log.type
        },
        show_log (log) {
            this.viewed_log = log
            this.viewing = true
        },
        test_name (test_id) {
            const tests = this.constants("tests")
            const test = Object.values(tests).find(test => test.id === test_id)
            if(test) return test.name
            return test_id
        },
        test_error_class (test) {
            return `text-${test.type}`
        }
    },
    computed: {
        state_identifier () {
            return "Logs"
        },
        fields () {
            return [
                ...Object.keys(this.columns),
                "report",
                "computed_error_count",
                "computed_warning_count"
            ]
        },
        types_filter () {
            return Object.values(this.constants("monitor_types"))
        },
        status_filters () {
            return [
                { id: "computed_error_count", name: "Errors" },
                { id: "computed_warning_count", name: "Warnings" }
            ]
        }
    },
    props: {
        siteId: {
            type: Number,
            validator: prop => prop >= 0,
            required: true
        }
    },
    data () {
        return {
            entity: "monitor_log",
            columns: {
                type: { label: "Report", sort: null, type: "alpha" },
                checked_at: { label: "Date", sort: "desc", type: "numeric" },
                status: { label: "Status", sort: false, type: "alpha" }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "checked_at",
                sort_dir: "desc",
                filter_on: "type",
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                status: null
            },

            viewing: false,
            viewed_log: {}
        }
    }
}
</script>
