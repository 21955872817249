import AlertButton from "@/custom/components/AlertButton"
import AlertsDialog from "@/custom/dialogs/AlertsDialog"

/*
<alerts-dialog
    :show.sync="alerts_shown"
    :topic-type="alert_topic_type"
    :topic-id="alert_topic_id"
/>
*/

const lists_projects = {
    components: {
        AlertButton,
        AlertsDialog
    },
    computed: {
        alert_topic_type () {
            return null
        },
        alert_topic_id () {
            return 0
        }
    },
    data () {
        return {
            alerts_shown: false
        }
    }
}

export default lists_projects
