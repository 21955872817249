<template>
    <img
        :src="src"
        class="contacts-plus-button-image"
    />
</template>

<script>
import button_inactive from "@/assets/img/contacts_plus_inactive.png"
import button_active from "@/assets/img/contacts_plus.png"

export default {
    name: "ContactsPlusLogo",
    computed: {
        src () {
            if(this.active) return button_active
            return button_inactive
        }
    },
    props: {
        active: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
.contacts-plus-button-image {
    max-width: 100%;
    max-height: 100%;
}
</style>
