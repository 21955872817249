<template>
    <div id="site-projects-list" class="card">
        <div class="card-header">
            <div class="card-title h5">
                {{ $root.translate("12-months Projects") }}
                <default-button
                    :title="$root.translate('New 12-months Project')"
                    flavor="link"
                    @click.prevent="edit_project_by_id(0)"
                >
                    <open-icon glyph="plus" />
                </default-button>
            </div>
        </div>
        <div class="card-body">
            <projects-list
                :site-id="siteId"
                :created-project-id="newest_project_id"
                @edit="edit_project_by_id"
            />
            <new-project
                :show.sync="edit_project"
                :project-id="edited_project_id"
                :site-id="siteId"
                @created="created_project"
            />
        </div>
    </div>
</template>

<script type="text/javascript">
import { lists_projects } from "@/custom/mixins"

import {
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"

export default {
    name: "Projects",
    mixins: [lists_projects],
    components: {
        DefaultButton,
        OpenIcon
    },
    methods: {
    },
    props: {
        siteId: {
            type: Number,
            validator: prop => prop >= 0,
            required: true
        }
    }
}
</script>
