<template>
    <modal-dialog
        id="record-picker"
        :show.sync="shown"
    >
        <template v-slot:title>
            <slot name="title"></slot>
        </template>

        <base-form
            :has-required-fields="true"
            @submit.prevent="save"
        >
            <form-select
                id="record_id"
                name="record_id"
                v-model="record_id"
                :data-source="entity"
                :context-id="contextId"
                :required="true"
                :show-all="false"
                :error="has_error('record_id')"
            >
                <template v-slot:label><slot name="label"></slot></template>
                <template v-slot:hint><slot name="hint"></slot></template>
            </form-select>
        </base-form>

        <template v-slot:footer>
            <div class="text-center">
                <default-button
                    class="mr-2"
                    @click.prevent="shown = false"
                >
                    {{ $root.translate("Cancel") }}
                </default-button>
                <default-button
                    type="submit"
                    color="primary"
                    class="ml-2"
                    @click.prevent="save"
                >
                    <slot name="save">{{ $root.translate("Save") }}</slot>
                </default-button>
            </div>
        </template>
    </modal-dialog>
</template>

<script>
import handles_errors from "@/nibnut/mixins/HandlesErrors"

import {
    ModalDialog,
    BaseForm,
    FormSelect,
    DefaultButton
} from "@/nibnut/components"

export default {
    name: "RecordPicker",
    mixins: [handles_errors],
    components: {
        ModalDialog,
        BaseForm,
        FormSelect,
        DefaultButton
    },
    mounted () {
        this.reset()
    },
    watch: {
        show: "reset"
    },
    methods: {
        reset () {
            this.record_id = 0
        },
        save () {
            this.clear_all_errors()
            if(!this.record_id) this.add_error("record_id", this.$root.translate("Please pick a value"))
            else this.$emit("save", this.record_id)
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        entity: {
            type: String,
            required: true
        },
        contextId: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            record_id: 0
        }
    }
}
</script>
