<template>
    <default-button
        v-if="copy"
        v-clipboard:copy="email"
        v-clipboard:success="copied_to_clipboard"
        v-on="$listeners"
    >
        <slot :copied="copied">{{ email }}</slot>
    </default-button>
    <base-link
        v-else
        :href="email | nibnut.mailto"
        v-on="$listeners"
    >
        <slot>{{ email }}</slot>
    </base-link>
</template>

<script>
import handles_copy_paste from "@/custom/mixins/HandlesCopyPaste"
import DefaultButton from "../Buttons/DefaultButton"
import BaseLink from "./BaseLink"

export default {
    name: "MailtoLink",
    mixins: [handles_copy_paste],
    components: {
        DefaultButton,
        BaseLink
    },
    props: {
        email: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        copy: {
            type: Boolean,
            default: false
        }
    }
}
</script>
