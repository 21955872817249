<template>
<div>
    <data-table
        id="site-plan-list"
        entity-name="plan:::plans"
        :columns="visible_columns"
        :rows="filtered_rows"
        :search="state.search"
        :searchable="false"
        :total="state.total + !!shell_record"
        :found="state.found + !!shell_record"
        :current-page="state.page"
        :total-pages="nb_pages"
        :show-totals="false"
        :clickable="false"
        :can-add="!!site && !!site.id"
        class="site-plan-list"
        @sort="sort_by"
        @page="goto_page"
        @add="new_plan"
    >
        <template
            v-slot:summary="{ row }"
        >
            <form-select
                :id="`plan_id-${row.id}`"
                name="plan_id"
                v-model="row.plan_id"
                data-source="site_plan"
                :required="true"
                :show-all="true"
                @input="save_row(row, ...arguments)"
            >
                <template v-slot:label>{{ translate("Plan") }}</template>
            </form-select>
            <form-select
                v-if="!row.value && !!plan_by_id(row.plan_id).monthly_price && !!plan_by_id(row.plan_id).yearly_price"
                :id="`repeat-${row.id}`"
                name="repeat"
                v-model="row.repeat"
                :data-source="repeats_for_plan_id(row.plan_id)"
                :required="true"
                :show-all="true"
                @input="save_row(row, ...arguments)"
            >
                <template v-slot:label>{{ translate("Price") }}</template>
                <template v-slot:hint>
                    <span
                        v-if="!!plan_by_id(row.plan_id).monthly_price || !!plan_by_id(row.plan_id).yearly_price"
                    >
                        <default-button
                            flavor="link"
                            size="xs"
                            @click.prevent="toggle_pricing_for_row(row)"
                        >
                            <span v-if="row.value === null">{{ translate("Set a custom price") }}</span>
                            <span v-else>{{ translate("Use the plan's default price") }}</span>
                        </default-button>
                    </span>
                </template>
            </form-select>
            <form-input
                v-else
                :id="`value-${row.id}`"
                name="value"
                type="float"
                :value="row.value"
                :min="0"
                :required="false"
                :editable="row.value !== null"
                glyph="dollar-sign"
                glyph-position="left"
                @input="save_row(row, ...arguments)"
            >
                <template v-slot:label>{{ translate("Price") }}</template>
                <template v-slot:hint>
                    <span
                        v-if="!!plan_by_id(row.plan_id).monthly_price || !!plan_by_id(row.plan_id).yearly_price"
                    >
                        <default-button
                            flavor="link"
                            size="xs"
                            @click.prevent="toggle_pricing_for_row(row)"
                        >
                            <span v-if="row.value === null">{{ translate("Set a custom price") }}</span>
                            <span v-else>{{ translate("Use the plan's default price") }}</span>
                        </default-button>
                    </span>
                </template>
                <template v-slot:empty-value>
                    <span v-if="!row.displayed_plan_value">{{ translate("Free") }}</span>
                    <span v-else>{{ row.displayed_plan_value | nibnut.currency }} / {{ repeat_as_unit(row.repeat) }}</span>
                    <div
                        v-if="!!plan_by_id(row.plan_id).monthly_price || !!plan_by_id(row.plan_id).yearly_price"
                    >
                        <default-button
                            flavor="link"
                            size="xs"
                            @click.prevent="toggle_pricing_for_row(row)"
                        >
                            <span v-if="row.value === null">{{ translate("Set a custom price") }}</span>
                            <span v-else>{{ translate("Use the plan's default price") }}</span>
                        </default-button>
                    </div>
                </template>
                <template v-slot:right_addon>
                    <span class="input-group-addon">/</span>
                    <select
                        :value="row.repeat"
                        class="form-select"
                        @change="save_row(row, $event.target.value, 'repeat')"
                    >
                        <option
                            v-for="repeat in repeat_units"
                            :key="repeat.id"
                            :value="repeat.id"
                        >
                            {{ repeat.name }}
                        </option>
                    </select>
                </template>
            </form-input>
            <form-date-input
                :id="`ends_on-${row.id}`"
                name="ends_on"
                v-model="row.ends_on"
                position="bottom"
                :required="false"
                :min="$moment().startOf('day').add(1, 'days')"
                @input="save_row_date(row, ...arguments)"
            >
                <template v-slot:label>{{ translate("Next Invoice") }}</template>
            </form-date-input>
            <form-date-input
                :id="`life_starts_on-${row.id}`"
                name="life_starts_on"
                v-model="row.life_starts_on"
                position="bottom"
                :required="false"
                :max="$moment().startOf('day')"
                @input="save_row_date(row, ...arguments)"
            >
                <template v-slot:label>{{ translate("Since") }}</template>
            </form-date-input>
            <default-button
                v-if="!!row.id"
                flavor="link"
                color="error"
                :block="true"
                @click.prevent.stop="confirm_row_deletion(row)"
            >
                <open-icon glyph="trash" />
            </default-button>
            <div v-else class="columns">
                <div class="column">
                    <default-button
                        color="error"
                        :block="true"
                        class="mt-4"
                        @click.prevent.stop="shell_record = null"
                    >
                        <open-icon glyph="times" />
                    </default-button>
                </div>
                <div class="column">
                    <default-button
                        color="success"
                        class="mt-4"
                        @click.prevent.stop="create_plan"
                    >
                        <open-icon glyph="check" />
                    </default-button>
                </div>
            </div>
        </template>
        <template
            v-slot:tbody="{ row, field }"
        >
            <form-select
                v-if="field === 'plan_id'"
                :id="`plan_id-${row.id}`"
                name="plan_id"
                v-model="row.plan_id"
                data-source="site_plan"
                :required="true"
                :show-all="true"
                @input="save_row(row, ...arguments)"
            />
            <div v-else-if="field === 'value'">
                <form-select
                    v-if="!row.value && !!plan_by_id(row.plan_id).monthly_price && !!plan_by_id(row.plan_id).yearly_price"
                    :id="`repeat-${row.id}`"
                    name="repeat"
                    v-model="row.repeat"
                    :data-source="repeats_for_plan_id(row.plan_id)"
                    :required="true"
                    :show-all="true"
                    @input="save_row(row, ...arguments)"
                >
                    <template v-slot:hint>
                        <span
                            v-if="!!plan_by_id(row.plan_id).monthly_price || !!plan_by_id(row.plan_id).yearly_price"
                        >
                            <default-button
                                flavor="link"
                                size="xs"
                                @click.prevent="toggle_pricing_for_row(row)"
                            >
                                <span v-if="row.value === null">{{ translate("Set a custom price") }}</span>
                                <span v-else>{{ translate("Use the plan's default price") }}</span>
                            </default-button>
                        </span>
                    </template>
                </form-select>
                <form-input
                    v-else
                    :id="`value-${row.id}`"
                    name="value"
                    type="float"
                    :value="row.value"
                    :min="0"
                    :required="false"
                    :editable="row.value !== null"
                    glyph="dollar-sign"
                    glyph-position="left"
                    @input="save_row(row, ...arguments)"
                >
                    <template v-slot:hint>
                        <span
                            v-if="!!plan_by_id(row.plan_id).monthly_price || !!plan_by_id(row.plan_id).yearly_price"
                        >
                            <default-button
                                flavor="link"
                                size="xs"
                                @click.prevent="toggle_pricing_for_row(row)"
                            >
                                <span v-if="row.value === null">{{ translate("Set a custom price") }}</span>
                                <span v-else>{{ translate("Use the plan's default price") }}</span>
                            </default-button>
                        </span>
                    </template>
                    <template v-slot:empty-value>
                        <span v-if="!row.displayed_plan_value">{{ translate("Free") }}</span>
                        <span v-else>{{ row.displayed_plan_value | nibnut.currency }} / {{ repeat_as_unit(row.repeat) }}</span>
                        <div
                            v-if="!!plan_by_id(row.plan_id).monthly_price || !!plan_by_id(row.plan_id).yearly_price"
                        >
                            <default-button
                                flavor="link"
                                size="xs"
                                @click.prevent="toggle_pricing_for_row(row)"
                            >
                                <span v-if="row.value === null">{{ translate("Set a custom price") }}</span>
                                <span v-else>{{ translate("Use the plan's default price") }}</span>
                            </default-button>
                        </div>
                    </template>
                    <template v-slot:right_addon>
                        <span class="input-group-addon">/</span>
                        <select
                            :value="row.repeat"
                            class="form-select"
                            @change="save_row(row, $event.target.value, 'repeat')"
                        >
                            <option
                                v-for="repeat in repeat_units"
                                :key="repeat.id"
                                :value="repeat.id"
                            >
                                {{ repeat.name }}
                            </option>
                        </select>
                    </template>
                </form-input>
            </div>
            <form-date-input
                v-else-if="field === 'ends_on'"
                :id="`ends_on-${row.id}`"
                name="ends_on"
                v-model="row.ends_on"
                position="bottom"
                :required="false"
                :min="$moment().startOf('day').add(1, 'days')"
                @input="save_row_date(row, ...arguments)"
            />
            <form-date-input
                v-else-if="field === 'life_starts_on'"
                :id="`life_starts_on-${row.id}`"
                name="life_starts_on"
                v-model="row.life_starts_on"
                position="bottom"
                :required="false"
                :max="$moment().startOf('day')"
                @input="save_row_date(row, ...arguments)"
            />
            <div
                v-else-if="field === '_buttons'"
                class="text-right"
            >
                <default-button
                    v-if="!!row.id"
                    flavor="link"
                    color="error"
                    @click.prevent.stop="confirm_row_deletion(row)"
                >
                    <open-icon glyph="trash" />
                </default-button>
                <default-button
                    v-else
                    color="error"
                    @click.prevent.stop="shell_record = null"
                >
                    <open-icon glyph="times" />
                </default-button>
                <default-button
                    v-if="!row.id"
                    color="success"
                    class="ml-2"
                    @click.prevent.stop="create_plan"
                >
                    <open-icon glyph="check" />
                </default-button>
            </div>
            <span v-else>{{ row[field] }}</span>
        </template>
    </data-table>
    <confirmation
        v-if="confirming"
        v-bind="confirmation_props"
        @cancel="done_confirming"
        @confirmed="confirmed"
    />
</div>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex"

import { is_data_table_source, handles_saving, confirms } from "@/nibnut/mixins"

import {
    FormInput,
    FormDateInput,
    FormSelect,
    DefaultButton,
    OpenIcon
} from "../../nibnut/components"

export default {
    name: "SitePlans",
    mixins: [is_data_table_source, handles_saving, confirms],
    mounted () {
        this.reset()
    },
    watch: {
        "site.id": "reset"
    },
    components: {
        FormInput,
        FormDateInput,
        FormSelect,
        DefaultButton,
        OpenIcon
    },
    methods: {
        repeat_as_unit (repeat) {
            if(repeat === "yearly") return this.translate("year")
            return this.translate("month")
        },
        plan_by_id (plan_id) {
            return this.entity_record("plan", plan_id) || {}
        },
        repeats_for_plan_id (plan_id) {
            if(!plan_id) return []
            const plan = this.plan_by_id(plan_id)
            if(!plan || (!plan.monthly_price && !plan.yearly_price)) return []

            return [
                { id: "monthly", name: `${this.nibnut_filter("nibnut.currency", [plan.monthly_price])} / ${this.repeat_as_unit("monthly")}` },
                { id: "yearly", name: `${this.nibnut_filter("nibnut.currency", [plan.yearly_price])} / ${this.repeat_as_unit("yearly")}` }
            ]
        },
        new_plan () {
            if(!this.site.id) return
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: this.entity }
            ).then(record => {
                record.site_id = this.site.id
                this.shell_record = record
            }).catch(error => {
                this.$error(error.message)
            })
        },
        save_row (row, value, field) {
            if(row.id) this.save_field_for_record_id(this.entity, row.id, value, field)
            else {
                row[field] = value
                if(field === "plan_id") {
                    const plan = this.plan_by_id(row.plan_id)
                    if(plan.monthly_price) {
                        row.displayed_plan_value = plan.monthly_price
                        row.repeat = "monthly"
                    } else {
                        row.displayed_plan_value = plan.yearly_price
                        row.repeat = "yearly"
                    }
                }
            }
        },
        save_row_date (row, date, field, format = "YYYY-MM-DD") {
            const value = this.$moment.isMoment(date) ? date.format(format) : null
            this.save_row(row, value, field)
        },
        toggle_pricing_for_row (row) {
            if(row.value === null) {
                if(row.repeat === "yearly") row.value = this.plan_by_id(row.plan_id).yearly_price
                else row.value = this.plan_by_id(row.plan_id).monthly_price
            } else row.value = null
            this.save_row(row, row.value, "value")
        },
        create_plan () {
            if(this.shell_record) {
                this.$store.dispatch("CREATE_RECORD", {
                    entity: this.entity,
                    data: this.shell_record
                }).then(() => {
                    this.shell_record = null
                    this.refresh()
                }).catch(this.receive_error)
            }
        },
        confirm_row_deletion (row) {
            const plan = this.plan_by_id(row.plan_id)
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete {name}", { name: plan.name }),
                    message: this.$root.translate("Do you really want to delete this plan? There is no undo..."),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete"),
                    deletion_data: {
                        entity: this.entity,
                        id: row.id
                    }
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    this.confirmation_props.deletion_data
                ).then(() => {
                    this.done_confirming()
                    this.refresh()
                }).catch(this.receive_error)
            } else this.done_confirming()
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        state_identifier () {
            return "site-plans"
        },
        rows () {
            if(!this.site.id) return []
            return this.entity_records("site_plan").filter(site_plan => site_plan.site_id === this.site.id)
        },
        filtered_rows () {
            let rows = this.rows.slice()

            if(!this.state.archived) rows = rows.filter(record => !record.deleted_at)

            rows = this.sort_rows(rows)
            rows = this.filter_rows(rows)
            rows = this.search_rows(rows)

            rows = this.page_rows(rows)

            if(this.shell_record) rows.push(this.shell_record)

            return rows
        },
        repeat_units () {
            return [
                { id: "monthly", name: this.repeat_as_unit("monthly") },
                { id: "yearly", name: this.repeat_as_unit("yearly") }
            ]
        }
    },
    props: {
        site: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            entity: "site_plan",
            columns: {
                plan_id: { label: "Plan", sort: false },
                value: { label: "Price", sort: null, type: "numeric" },
                ends_on: { label: "Next Invoice", sort: null, type: "numeric" },
                life_starts_on: { label: "Since", sort: "desc", type: "numeric" },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "life_starts_on",
                sort_dir: "desc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                status: null
            },
            shell_record: null
        }
    }
}
</script>

<style lang="scss">
.site-plan-list {
    table.table {
        td {
            vertical-align: top;
        }
    }
}
</style>
