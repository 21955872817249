<template>
    <nav
        v-if="is_at_least_licensing"
        :class="{ active: navigating }"
        class="nibnut-sidenav"
        v-touch:swipe="swiped"
    >
        <ul class="nav">
            <router-link
                :to="{ name: 'home' }"
                tag="li"
                active-class="active"
                :exact="true"
                class="nav-item"
                @click.native="$emit('navigate', false)"
            >
                <base-link
                    :href="{ name: 'home' }"
                    :exact="true"
                    @click.native="$emit('navigate', false)"
                >
                    <open-icon glyph="home" size="1x" />
                    <span>{{ $root.translate("Home") }}</span>
                </base-link>
            </router-link>
            <router-link
                v-if="is_developer"
                :to="{ name: 'ticket.list', params: { folder: ticket_folders.length ? ticket_folders[0].id : '0' } }"
                tag="li"
                active-class="active"
                class="nav-item"
                @click.native="$emit('navigate', false)"
            >
                <base-link
                    :href="{ name: 'ticket.list', params: { folder: ticket_folders.length ? ticket_folders[0].id : '0' } }"
                    @click.native="$emit('navigate', false)"
                >
                    <open-icon
                        glyph="inbox"
                        size="1x"
                        :badge="(!navigating && !!active_tickets_count) ? nibnut_filter('nibnut.number', [active_tickets_count, '0,0']) : ''"
                    />
                    <span
                        :class="{ 'text-bold': !!active_tickets_count }"
                    >
                        {{ $root.translate("Tickets") }}<span v-if="active_tickets_count"> ({{ active_tickets_count | nibnut.number("0,0") }})</span>
                    </span>
                </base-link>
                <ul
                    v-if="navigating && !!ticket_folders.length"
                    class="nav"
                >
                    <router-link
                        v-for="folder in ticket_folders"
                        :key="folder.id"
                        :to="{ name: 'ticket.list', params: { folder: folder.id } }"
                        tag="li"
                        active-class="active"
                        class="nav-item"
                        @click.native="$emit('navigate', false)"
                    >
                        <base-link
                            :href="{ name: 'ticket.list', params: { folder: folder.id } }"
                            @click.native="$emit('navigate', false)"
                        >
                            <span
                                :class="{ 'text-bold': !!active_tickets_count_for_folders(folder) }"
                            >
                                {{ folder.name }}<span v-if="active_tickets_count_for_folders(folder)"> ({{ active_tickets_count_for_folders(folder) | nibnut.number("0,0") }})</span>
                            </span>
                        </base-link>
                    </router-link>
                </ul>
            </router-link>
            <router-link
                v-if="is_at_least_administrator"
                :to="{ name: 'client.list' }"
                tag="li"
                active-class="active"
                class="nav-item"
                @click.native="$emit('navigate', false)"
            >
                <base-link
                    :href="{ name: 'client.list' }"
                    @click.native="$emit('navigate', false)"
                >
                    <open-icon glyph="address-card" size="1x" />
                    <span>{{ $root.translate("Clients") }}</span>
                </base-link>
            </router-link>
            <router-link
                v-if="is_at_least_administrator"
                :to="{ name: 'site.list' }"
                tag="li"
                active-class="active"
                class="nav-item"
                @click.native="$emit('navigate', false)"
            >
                <base-link
                    :href="{ name: 'site.list' }"
                    @click.native="$emit('navigate', false)"
                >
                    <open-icon glyph="globe-americas" size="1x" />
                    <span>{{ $root.translate("Sites") }}</span>
                </base-link>
            </router-link>
            <router-link
                v-if="is_at_least_administrator"
                :to="{ name: 'project.list' }"
                tag="li"
                active-class="active"
                class="nav-item"
                @click.native="$emit('navigate', false)"
            >
                <base-link
                    :href="{ name: 'project.list' }"
                    @click.native="$emit('navigate', false)"
                >
                    <open-icon glyph="search-dollar" size="1x" />
                    <span>{{ $root.translate("Projects") }}</span>
                </base-link>
            </router-link>
            <router-link
                v-if="is_at_least_administrator"
                :to="{ name: 'alert.list' }"
                tag="li"
                active-class="active"
                class="nav-item"
                @click.native="$emit('navigate', false)"
            >
                <base-link
                    :href="{ name: 'alert.list' }"
                    @click.native="$emit('navigate', false)"
                >
                    <open-icon glyph="bell" size="1x" />
                    <span>{{ $root.translate("Alerts") }}</span>
                </base-link>
            </router-link>
            <router-link
                v-if="is_at_least_administrator"
                :to="{ name: 'license.list' }"
                tag="li"
                active-class="active"
                class="nav-item"
                @click.native="$emit('navigate', false)"
            >
                <base-link
                    :href="{ name: 'license.list' }"
                    @click.native="$emit('navigate', false)"
                >
                    <open-icon glyph="key" size="1x" />
                    <span>{{ $root.translate("Licenses") }}</span>
                </base-link>
            </router-link>
            <router-link
                v-if="is_at_least_administrator"
                :to="{ name: 'credit.list' }"
                tag="li"
                active-class="active"
                class="nav-item"
                @click.native="$emit('navigate', false)"
            >
                <base-link
                    :href="{ name: 'credit.list' }"
                    @click.native="$emit('navigate', false)"
                >
                    <open-icon glyph="file-invoice-dollar" size="1x" />
                    <span>{{ $root.translate("Credits") }}</span>
                </base-link>
            </router-link>
            <router-link
                v-if="is_at_least_administrator"
                :to="{ name: 'user.list' }"
                tag="li"
                active-class="active"
                class="nav-item"
                @click.native="$emit('navigate', false)"
            >
                <base-link
                    :href="{ name: 'user.list' }"
                    @click.native="$emit('navigate', false)"
                >
                    <open-icon glyph="users" size="1x" />
                    <span>{{ $root.translate("Users") }}</span>
                </base-link>
            </router-link>
            <router-link
                v-if="is_at_least_administrator"
                :to="{ name: 'settings' }"
                tag="li"
                active-class="active"
                class="nav-item"
                @click.native="$emit('navigate', false)"
            >
                <base-link
                    :href="{ name: 'settings' }"
                    @click.native="$emit('navigate', false)"
                >
                    <open-icon glyph="cog" size="1x" />
                    <span>{{ $root.translate("Settings") }}</span>
                </base-link>
            </router-link>
            <li
                v-if="installable"
                class="nav-item"
            >
                <base-link
                    href="#"
                    @click.prevent="$emit('install')"
                >
                    <open-icon glyph="arrow-circle-down" size="1x" />
                    <span>{{ $root.translate("Install") }}</span>
                </base-link>
            </li>
        </ul>
        <div class="nibnut-sidenav-footer">
            <div v-if="!navigating || !!$mq.match(/^(md|lg|tl|xl|fred)$/i)">
                &copy;&nbsp;{{ current_year }}<br />
                v.{{ app_version }}
            </div>
            <div v-else>
                &copy;&nbsp;{{ current_year }}&nbsp;{{ setting("customer_name") }}<br />
                {{  $root.translate("App sprouted from a") }}&nbsp;<a href="https://nibnut.com"><base-image :src="nibnut_logo" alt="nibnut" /></a>&nbsp;&bull;&nbsp;v.{{ app_version }}
            </div>
        </div>
    </nav>
</template>

<script>
import { misc_utilities, string_utilities } from "@/nibnut/mixins"
import { handles_freescout_folders } from "@/custom/mixins"

import { BaseImage, BaseLink, OpenIcon } from "@/nibnut/components"

import nibnut_logo from "@/assets/img/nibnut.png"

export default {
    name: "AppSidenav",
    mixins: [misc_utilities, string_utilities, handles_freescout_folders],
    components: {
        BaseImage,
        BaseLink,
        OpenIcon
    },
    methods: {
        swiped (direction) {
            if((direction === "left") || (direction === "right")) this.$emit("navigate", (direction === "right"))
        }
    },
    computed: {
        nibnut_logo () {
            return nibnut_logo
        }
    },
    props: {
        navigating: {
            type: Boolean,
            required: true
        },
        installable: {
            type: Boolean,
            required: false
        }
    }
}
</script>
