import { mapState } from "vuex"

const ui_utilities = {
    methods: {
        scroll_to (target) {
            if(!target) return
            const scroll_margin = parseInt(getComputedStyle(target)["scroll-margin-top"]) || 0
            const header = document.querySelector(".nibnut-app > .header")
            window.scrollTo({ top: target.getBoundingClientRect().y + window.scrollY - scroll_margin - header.offsetHeight, left: 0, behavior: "smooth" })
        }
    },
    computed: {
        ...mapState(["app_context"]),
        small_screen () {
            return !!this.$mq.match(/^(xs|sm|md|lg)$/i)
        },
        is_public_page () {
            return !this.$route.meta || (!this.$route.meta.login_required && !this.$route.meta.admin_route && !this.$route.meta.licensing_route)
        },
        is_navigation_forced () {
            return !this.small_screen && (!!this.$route.meta && !!this.$route.meta.nav_expanded)
        }
    }
}

export default ui_utilities
